import axios from "axios";
import React, {useEffect, useState} from "react";
import {SearchBar} from "../SearchBar/SearchBar";
import {Button, PrimaryHeading, StandardText, Table} from "../common";
import {useHistory} from "react-router-dom";
import {useIntl} from 'react-intl';
import {accountType, cellAccStatus} from "../Helpers/Helpers";
import {readableDateTime} from "../Helpers/DateTimeHelpers";
import styles from "./Accounts.module.scss";
import ErrorMessage from "../ErrorMessage";
import Loading from "../Loading/Loading";

const getAccounts = (searchTerm, pageNumber, setAccounts, setAccountMeta, setLoading, searched) => {
    setLoading(true);
    if (searched) {
        axios.post("/api/payreq-accounts/delivery-accounts", {search: searchTerm, pageNumber: pageNumber})
            .then(({data}) => {
                setAccounts(data.accounts);
                setAccountMeta(data.meta);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        setLoading(false);
    }
};

const getMainUser = (setMainUser, setLoadingMainUser) => {
    setLoadingMainUser(true);
    axios.get('/api/user', {})
        .then(({data}) => {
            setMainUser(data.exists);
        })
        .finally(() => {
            setLoadingMainUser(false);
        })
}

const accColumnHeaders = [
    {
        Header: <StandardText text="accTable.id"/>,
        accessor: 'customerActorId',
    },
    {
        Header: <StandardText text="accTable.tagName"/>,
        accessor: 'tagName',
    },
    {
        Header: <StandardText text="accTable.accountType"/>,
        accessor: 'systemId',
        Cell: accountType,
    },
    {
        Header: <StandardText text="accTable.status"/>,
        accessor: 'status',
        Cell: cellAccStatus,
    },
    {
        Header: <StandardText text="accTable.timezone"/>,
        accessor: 'timezone',
    },
    {
        Header: <StandardText text="accTable.creationDate"/>,
        accessor: 'creationDate',
        Cell: (row) => readableDateTime(row['creationDate']),
    },
];

const Accounts = ({accounts, accountMeta, pageNumber, setPageNumber, searched, history, loading}) => {

    const invalid = !accounts || accounts.length === 0;

    if (invalid) return (searched) ?
        <ErrorMessage><StandardText text="accounts.mailers.emptySearch"/></ErrorMessage> :
        <b><StandardText text="accounts.mailers.getStarted"/></b>;
    return (
        <Table columnHeaders={accColumnHeaders} tableData={accounts} tableMeta={accountMeta} pageNumber={pageNumber}
               setPageNumber={setPageNumber}
               handleRowClick={(data) => history.push('/accounts/' + data[0])}
               targetColumns={['customerActorId']} loading={loading}/>
    );
};

const PayreqAccounts = () => {
    const [accounts, setAccounts] = useState([]);
    const [accountMeta, setAccountMeta] = useState([]);
    const [loadingAccounts, setLoadingAccounts] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [searched, setSearched] = useState(false);
    const [mainUser, setMainUser] = useState(false);
    const [loadingMainUser, setLoadingMainUser] = useState(true);

    useEffect(() => getAccounts(searchTerm, pageNumber, setAccounts, setAccountMeta, setLoadingAccounts, searched),
        [searchTerm, searched, pageNumber]);

    useEffect(() => getMainUser(setMainUser, setLoadingMainUser), []);

    const history = useHistory();
    const intl = useIntl();

    if (loadingMainUser) return <Loading/>;


    return(
        <React.Fragment>
            <PrimaryHeading text={"accounts.mailers.header"}/>
            {mainUser && <Button className={styles.addButton} label={"accounts.add.button"} icon={"add"} variant={"primary"} onClick={() => history.push('/accounts/add')}/>}
            <div className={styles.userBox}>
                <SearchBar setPageNumber={setPageNumber}
                           searchTerm={searchTerm}
                           setSearchTerm={setSearchTerm}
                           setSearched={setSearched}
                           intl={intl}/>
                <Accounts accounts={accounts} accountMeta={accountMeta} pageNumber={pageNumber}
                       setPageNumber={setPageNumber} searched={searched} history={history} loading={loadingAccounts}/>
            </div>
        </React.Fragment>
    )
};

export default PayreqAccounts;