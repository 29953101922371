import styles from "./CustomerSummary.module.scss";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import {Button, IconText} from "../../common";
import {valueParse} from "../../../utils/display-helper-utils";
import Loading from "../../Loading/Loading";

const getCustomerInfo = (id, setCustomerInfo, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/customer-summary-details", {params: {accountId: id}})
        .then(({data}) => {
            setCustomerInfo(data)
        })
        .finally(() => {
        setLoading(false);
    });
};

const CustomerSummary = () => {

    const [customerInfo, setCustomerInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const id = parseInt(useParams().id);
    const history = useHistory();

    useEffect(() => getCustomerInfo(id, setCustomerInfo, setLoading),
        [id])

    if (loading) return <Loading/>;

    if (customerInfo) {
        return (
        <div className={styles.allElemens}>
            <div className={styles.headerDiv}>
                <div className={styles.rowDiv}>
                    <div className={styles.indDiv}>
                        <IconText className={styles.fieldLabel} text={"accounts.customer.id"}/>
                        <IconText className={styles.fieldText}>{customerInfo.id}</IconText>
                    </div>
                    <div className={styles.indDiv}>
                        <IconText className={styles.fieldLabel} text={"accounts.customer.name"}/>
                        <IconText className={styles.fieldText}>{customerInfo.name}</IconText>
                    </div>
                </div>
                <div className={styles.rowDiv}>
                    <div className={styles.indDiv}>
                        <IconText className={styles.fieldLabel} text={"accounts.customer.country"}/>
                        <IconText className={styles.fieldText}>{valueParse(customerInfo.country)}</IconText>
                    </div>
                    <div className={styles.indDiv}>
                        <IconText className={styles.fieldLabel} text={"accounts.customer.state"}/>
                        <IconText className={styles.fieldText}>{valueParse(customerInfo.statname)}</IconText>
                    </div>
                </div>
            </div>
            <div className={styles.buttonDiv}>
                <Button variant={"link"} label={"accounts.customer.edit"} icon={"edit"} onClick={() => history.push(id + '/edit-customer')}/>
            </div>
        </div>
        ) } else {
        return null;
    }

}

export default CustomerSummary;


