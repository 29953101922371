import React from "react";
import RegularText from "../_RegularText";
import {Button, Modal} from "../index";
import {Form, Formik} from "formik";
import styles from "../_Modal/_Modal.module.scss";
import altStyles from "./EditConfirmationModal.module.scss";
import {useHistory} from "react-router-dom";

const EditConfirmationModal = ({newVals, existingVals, show, setShow, id, editFunc, intlKeys, additionalFields = null, header}) => {
    const history = useHistory();

    const handleClose = () => {
        setShow(false)
    };

    const valPrinter = (val) => {
       if  (val === null) {
           return "null"
       } else if (val === true) {
           return "true"
       } else if (val === false) {
           return "false"
       } else {
           return val;
       }
    }


    return <Modal header={header} show={show} handleClose={() => setShow(false)} animation={false}>
        <Formik
            initialValues={newVals}
            onSubmit={(newVals, {setSubmitting}) => {
                setSubmitting(true);
                editFunc(newVals, id, additionalFields)
                    .finally(() => setSubmitting(false))
                    .then(() => {
                    history.goBack()
                })
            }}
        >
            {({
                  handleSubmit,
                  isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                    {Object.keys(newVals).map((key, index) => {
                        if (newVals[key] !== existingVals[key]) {
                            return (
                                <div key={index}>
                                    <b><RegularText text={intlKeys[key]}/></b>
                                    <div className={altStyles.valuePair}>
                                        <p data-cy={"orig"+key}>{valPrinter(existingVals[key])}</p>
                                        <span className="material-icons-outlined">{"arrow_right_alt"}</span>
                                        <p data-cy={"change"+key}>{valPrinter(newVals[key])}</p>
                                    </div>
                                </div>
                        )}
                        return null;
                    })}

                    <Modal.Footer>
                        <Button label={"accounts.modal.ChangeFastformCancelButton"} variant={"secondary"} onClick={handleClose} type="button"/>
                        <Button label={"accounts.modal.ChangeFastformChangeButton"} variant={"primary"} loading={isSubmitting}
                                type="submit" className={styles.submitButton}/>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    </Modal>
};
export {EditConfirmationModal};