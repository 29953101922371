import React from "react";
import FormModal from "../Modals/FormModal";
import RegularText from "../common/_RegularText";

const JoinModal = ({onYes, onNo, show, setShow}) => {
    return <FormModal header={"accounts.modal.headerJoin"}
                   message={<RegularText text="accounts.modal.messageSupportJoin"/>}
                   affLabel={"accounts.modal.buttonSupportJoin"}
                   affVariant={"primary"}
                   negLabel={"accounts.modal.buttonNo"}
                   negVariant={"secondary"}
                   onYes={onYes}
                   onNo={onNo}
                   show={show} setShow={setShow}
                   textFieldMessage={"accounts.modal.reasonMessage"}
                   textPlaceholder={"accounts.modal.reasonPlaceholder"}
            />;
};
export {JoinModal};