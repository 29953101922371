import React from "react";
import {Badge, IconText, StandardText} from "../common";
import styles from "./helpers.module.scss";
import {readableDateTime} from "./DateTimeHelpers";

function tickCross(row) {
    return (
        row.status ?
            <span className="material-icons-outlined" style={{color: "green"}}>check</span> :
            <span className="material-icons-outlined" style={{color: "red"}}>close</span>
    );
}

function cellStatus(row) {
    return <StatusBadge status={row['status']} />
}

const StatusBadge = ({status}) => {
    if (status === 0) {
        return(<Badge variant="warning" label="helpers.pendingStatus"/>);
    } else if (status === 1) {
        return(<Badge variant="success" label="helpers.activeStatus"/>);
    } else if (status === 2) {
        return(<Badge variant="secondary" label="helpers.deregisteredStatus"/>);
    } else if (status === 3) {
        return(<Badge variant="danger" label="helpers.failedStatus"/>);
    }
    return null;
}

function cellAccStatus(row) {
    return <AccountStatusBadge status={row['status']} />
}

const AccountStatusBadge = ({status}) => {
    if (status === 0) {
        return(<Badge variant="warning" label="helpers.pendingStatus"/>);
    } else if (status === 1) {
        return(<Badge variant="success" label="helpers.activeStatus"/>);
    } else if (status === 2) {
        return(<Badge variant="secondary" label="helpers.deactivatedStatus"/>);
    } else if (status === 3) {
        return(<Badge variant="danger" label="helpers.failedStatus"/>);
    }
    return null;
}

const IntegratorStatusBadge = ({status}) => {
    if (status === 0) {
        return(<Badge variant="success" label="helpers.activeStatus"/>);
    } else if (status === 1) {
        return(<Badge variant="secondary" label="helpers.deactivatedStatus"/>);
    }
    return null;
}

function cellExpired(row) {
    return <ExpiredBadge status={row['expired']} />
}

const ExpiredBadge = ({status: expired}) => {
    if (expired) {
        return(<Badge variant="danger" label="helpers.status.expired"/>);
    } else {
        return(<Badge variant="success" label="helpers.status.valid"/>);
    }
}

function accountType(row) {
    const accountType = row.systemId;

    if (accountType === "inbox") {
        return <IconText icon={"markunread_mailbox"} text={"helpers.accType.inbox"} className={styles.accountType}/>;
    } else if (accountType === "inbox-business") {
        return <IconText icon={"cases"} text={"helpers.accType.inboxBusiness"} className={styles.accountType}/>;
    } else if (accountType === "delivery") {
        return <IconText icon={"local_shipping"} text={"helpers.accType.delivery"} className={styles.accountType}/>;
    }
}

const LockoutStatus = (failCount, lockoutDate) => {
    if (lockoutDate && failCount !== 0) {
        return <div className={styles.lockoutInfo}>
            <span className={styles.lockoutBadgeLocked}>
                <Badge variant="danger" label="helpers.lockout.locked" values={{lockoutDate: readableDateTime(lockoutDate)}}/>
            </span>
            {(failCount === 1) && <StandardText text={"helpers.lockout.attempt"} values={{failCount: failCount}}/>}
            {(failCount !== 1) && <StandardText text={"helpers.lockout.attempts"} values={{failCount: failCount || 0}}/>}
        </div>;
    } else {
        return <div className={styles.lockoutInfo}>
            <span className={styles.lockoutBadge}>
                <Badge variant="success" label="helpers.lockout.fullAccess"/>
            </span>
            {(failCount === 1) && <StandardText text={"helpers.lockout.attempt"} values={{failCount: failCount}}/>}
            {(failCount !== 1) && <StandardText text={"helpers.lockout.attempts"} values={{failCount: failCount || 0}}/>}
        </div>;
    }
}

export {tickCross, StatusBadge, LockoutStatus, cellStatus, cellAccStatus, cellExpired, accountType, IntegratorStatusBadge};

