import {Form} from "react-bootstrap";
import {useIntl} from "react-intl";
import styles from './_TextInput.module.scss';
import React from 'react';

const _TextInput = ({id, label, placeholder, hint, type = "text", as, onBlur, onChange, value, errors, touched, className, defaultValue, onKeyDown}) => {
    const intl = useIntl();
    const resolvedPlaceholder = typeof(placeholder) === "string" ? intl.formatMessage({id: placeholder}) : '';
    return (
        <Form.Group controlId={id} className={[styles.formGroup, className].join(" ")}>
            {label && <b><Form.Label className={styles.label}>{intl.formatMessage({id: label})}</Form.Label></b>}
            <Form.Control type={type}
                          as={as}
                          placeholder={resolvedPlaceholder}
                          onChange={onChange}
                          onBlur={onBlur}
                          defaultValue={defaultValue}
                          value={value}
                          isInvalid={touched && !!errors}
                          className={styles.formEntry}
                          onKeyDown={onKeyDown}
            />
            {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
            <Form.Control.Feedback type="invalid">
                {typeof(errors) === "string" && <span className={styles.errors}>{intl.formatMessage({id: errors})}</span>}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

export default _TextInput;