import React from "react";
import {Button, IconText, StandardText} from "../common";
import {Link, useHistory} from "react-router-dom";
import {ButtonGroup} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "./ButtonCluster.module.scss";

const _Button = ({path, label, string, options, icon, onClick, disabled, variant, size, className, visible = true, history}) => {

    if (options) {
        return <Dropdown as={ButtonGroup} variant={variant} size={size}>
            <Dropdown.Toggle className={className}>
                {label}
                {icon && <span className={"material-icons-outlined " + styles.dropdownIcon} >{icon}</span>}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {options.map((item, index) =>
                    <React.Fragment key={index}>
                        {!item.hide &&
                            <Dropdown.Item onClick={item.onClick} disabled={item.disabled}><StandardText
                                text={item.label}/></Dropdown.Item>}
                    </React.Fragment>
                )}
            </Dropdown.Menu>
        </Dropdown>
    } else if (path && label && icon) {
        return (
            <React.Fragment>
                {visible &&
                    <Button onClick={() => {history.push(path)}} disabled={disabled} variant={variant} size={size}
                            className={className} component={Link}>
                        <IconText icon={icon} text={label}/>
                    </Button>}
            </React.Fragment>
        );
    } else if (path) {
        return <Button onClick={() => {history.push(path)}} className={className} label={label} icon={icon} size={size} variant={variant}/>
    } else if (label && icon) {
        return (
            <React.Fragment>
                {visible &&
                <Button onClick={onClick} disabled={disabled} variant={variant} size={size} className={className}>
                    <IconText icon={icon} text={label}/>
                </Button>}
            </React.Fragment>
        );
    } else if (string) {
        return (
            <Button className={className} string={string} onClick={onClick} disabled={disabled} variant={variant}
                    icon={icon} size={size}/>
        );
    } else {
        return (
            <Button className={className} label={label} onClick={onClick} disabled={disabled} variant={variant}
                    icon={icon} size={size}/>
        );
    }
}

const ButtonCluster = ({buttons, children}) => {
    const history = useHistory();
    return (
        <ButtonGroup>
            {/* eslint-disable-next-line */}
            {buttons && buttons.map((buttonInfo, index) => <_Button path={buttonInfo['path']} label={buttonInfo['label']}
                                                           string={buttonInfo['string']} onClick={buttonInfo['onClick']}
                                                           disabled={buttonInfo['disabled']} options={buttonInfo['options']}
                                                           variant={buttonInfo['variant']} icon={buttonInfo['icon']}
                                                           size={buttonInfo['size']} className={buttonInfo['className']}
                                                            visible={buttonInfo['visible']} history={history} key={index}/>)}
            {children}
        </ButtonGroup>
    );

}

ButtonCluster.Button = _Button

export default ButtonCluster;