import React, {useState} from "react";
import {Alert, BoolField, Button, DropdownSelect, Modal, StandardText, TextInput} from "../../../common";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import styles from "./EditTemplateModal.module.scss";
import axios from "axios";
import {canadaTypes, getCreditType} from "../MailTemplateUtils";




const templateTypeOpts = ["paystatement", "payt4", "credit"];

const updateTemplate = (template, setError, setSuccessAlert, handleClose) => {
    return axios.post("/api/templates/update-template", template)
        .then(({data}) => {
            if (data.success) {
                handleClose()
                setTimeout(() => setSuccessAlert(), 6000);
                setSuccessAlert("template.edit.updateSuccess");
            } else {
                setTimeout(() => setError(false), 6000);
                setError(true);
                throw new Error("Invalid Request")
            }
        })
}

const addTemplate = (template, setError, setSuccessAlert, handleClose) => {
    return axios.post("/api/templates/add-template", template)
        .then(({data}) => {
            if (data.success) {
                handleClose()
                setTimeout(() => setSuccessAlert(), 6000);
                setSuccessAlert("template.edit.addSuccess");
            } else {
                setTimeout(() => setError(false), 6000);
                setError(true);
                throw new Error("Invalid Request")
            }
        })
}

export const EditTemplateModal = ({template = {}, isCreateTemplate = false, show, setShow, setSuccessAlert, onSuccess}) => {
    const [error, setError] = useState(false);

    const handleClose = () => {
        setError(false)
        setShow(false)
    };

    const validate = Yup.object().shape({
        displayName: Yup.string().nullable().required("formModal.required"),
        typeName: Yup.string().required("formModal.required"),
        indicator: Yup.string().nullable().required("formModal.required"),
        forceIgnoreContacts: Yup.boolean().required("formModal.required"),
        readBillerAccountNumberFromContact: Yup.boolean().required("formModal.required")
    });

    return (
        <Modal header={"templateRule.edit.heading"} show={show} setShow={setShow} handleClose={() => handleClose()}>
            <Formik
                initialValues={{...template,
                    dispatchSubject: template.dispatchSubject || null,
                    typeName: getCreditType(template.typeName),
                    forceIgnoreContacts: template.forceIgnoreContacts || false,
                    readBillerAccountNumberFromContact: template.readBillerAccountNumberFromContact || false,
                    indicator: (template.multiBillInfo && template.multiBillInfo['indicator']) || null,
                    background: (template.multiBillInfo && template.multiBillInfo['backgroundPdf']) || null}}
                validationSchema={validate}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(true);
                    if (isCreateTemplate === true){
                        addTemplate(values, setError, setSuccessAlert, handleClose)
                            .then(() => onSuccess())
                            .catch(() => {})
                            .finally(() => setSubmitting(false))
                    } else {
                        updateTemplate(values, setError, setSuccessAlert, handleClose)
                            .then(() => onSuccess())
                            .catch(() => {})
                            .finally(() => setSubmitting(false))
                    }

                }}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <TextInput id="displayName"
                                   label={"templateDetail.displayName"}
                                   placeholder={"templateDetail.displayName"}
                                   type={"text"}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.displayName}
                                   errors={errors.displayName}
                                   touched={touched.displayName}/>
                        <DropdownSelect label="templateDetail.creditType" name={"typeName"}
                                        options={templateTypeOpts.map((opt) => [`accounts.templates.type.${opt}`, opt])}
                                        onChange={(e) => {
                                            setFieldValue("typeName", e.target.value);
                                        }}
                                        value={values.typeName} onBlur={handleBlur} errors={errors.typeName} touched={touched}/>
                        <TextInput id="dispatchSubject"
                                   label={"templateDetail.dispatchSubject"}
                                   placeholder={canadaTypes.includes(values.typeName) ? `template.edit.documentType.${values.typeName}` : "templateDetail.dispatchSubject"}
                                   type={"text"}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.dispatchSubject}
                                   errors={errors.dispatchSubject}
                                   touched={touched.dispatchSubject}/>
                        <TextInput id="indicator"
                                   label={"templateDetail.indicator"}
                                   placeholder={"templateDetail.indicator"}
                                   type={"text"}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.indicator}
                                   errors={errors.indicator}
                                   touched={touched.indicator}/>
                        <TextInput id="background"
                                   label={"templateDetail.background"}
                                   placeholder={"templateDetail.background"}
                                   type={"text"}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.background}
                                   errors={errors.background}
                                   touched={touched.background}/>
                        <BoolField id="forceIgnoreContacts"
                                   label="templateDetail.forceIgnoreContacts"
                                   name={"forceIgnoreContacts"}
                                   onChange={handleChange}
                                   defaultValue={values.forceIgnoreContacts}
                                   value={values.forceIgnoreContacts}
                                   onBlur={handleBlur}
                                   errors={errors.forceIgnoreContacts}
                                   touched={touched}/>
                        <BoolField id="readBillerAccountNoFromContacts"
                                   label="templateDetail.readBillerAccountNoFromContacts"
                                   name={"readBillerAccountNumberFromContact"}
                                   onChange={handleChange}
                                   defaultValue={values.readBillerAccountNumberFromContact}
                                   value={values.readBillerAccountNumberFromContact}
                                   onBlur={handleBlur}
                                   errors={errors.readBillerAccountNumberFromContact}
                                   touched={touched}/>


                        {error && <Alert variant={"danger"}><StandardText text={"templateRule.edit.error"}/></Alert>}

                        <Modal.Footer className={styles.buttons}>
                            <Button label={"accounts.convertModal.cancel"} variant={"secondary"}
                                    type="button" onClick={handleClose}/>
                            <Button label={"templateRule.edit.modalSave"} variant={"primary"} loading={isSubmitting}
                                    type="submit" className={styles.submitButton}/>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>)
};