import styles from "./ContactList.module.scss"
import React, {useState} from "react";
import Pagination from "../../../Pagination/Pagination";
import ErrorMessage from "../../../ErrorMessage";
import RegularText from "../../../common/_RegularText";
import {Button, IconText} from "../../../common";
import copyToClipboard from "../../../copyToClipboard";
import axios from "axios";
import {useAppState} from "../../../../state";
import {CONTACT_REMOVED} from "../../../../state/reducers/alertReducer";
import {useHistory} from "react-router-dom";
import {ConfirmButton as DeleteButton} from "../../../common/index";

const removeSupportContact = (id, setLoading, handleChange, dispatch) => {
    if (id >= 0) {
        setLoading(true);
        axios.post("/api/payreq-accounts/remove-support-contact", {contactId: id})
            .then(() => {
                handleChange();
                dispatch({type: CONTACT_REMOVED})
            })
            .finally(() => setLoading(false))
    }
}

const copyValueToClipboard = (value, setLabel) => {
    setTimeout(() => setLabel(""), 6000)
    setLabel("accounts.contacts.copied");
    copyToClipboard(value);
}

const ContactRow = ({history, contact, handleChange}) => {

    const [emailButtonLabel, setEmailButtonLabel] = useState("");
    const [phoneButtonLabel, setPhoneButtonLabel] = useState("");
    const [loading, setLoading] = useState(false);
    const [, dispatch] = useAppState();

    let rowText = "";

    if (contact.email && contact.phone) {
        rowText = "accounts.contacts.contactDetails";
    } else if (contact.email) {
        rowText = "accounts.contacts.contactDetailsNoPhone";
    } else if (contact.phone) {
        rowText = "accounts.contacts.contactDetailsNoEmail";
    }

    return (
        <span key={contact.id} className={styles.contactRow}>
            <IconText icon={contact.icon}
                      text={rowText}
                      values={{
                          b: (...chunks) => <b>{chunks}</b>,
                          label: contact.label,
                          name: contact.name,
                          email: contact.email,
                          phone: contact.phone}}
                      className={styles.contactTitle}/>
            <span className={styles.rowButtons}>
                {contact.userId &&
                    <Button icon={"person_search"} variant={"link"} onClick={() => history.push("/users/" + contact.userId)}/>}
                {contact.email &&
                    <Button icon={"mail"} variant={"link"} label={emailButtonLabel}
                         onClick={() => copyValueToClipboard(contact.email, setEmailButtonLabel)}/>}
                {contact.phone &&
                    <Button icon={"phone"} variant={"link"} label={phoneButtonLabel}
                         onClick={() => copyValueToClipboard(contact.phone, setPhoneButtonLabel)}/>}
                <DeleteButton icon="delete" confirmIcon="delete_forever" variant={"link"} label="" confirmLabel="accounts.contacts.confirmDelete" confirmClassName={styles.confirmRed}
                        onClick={() => removeSupportContact(contact.id, setLoading, handleChange, dispatch)}
                        loading={loading}/>
            </span>
        </span>
    );
}

const ContactList = ({contactData, contactMeta, pageNumber, setPageNumber, emptyMessage, handleChange, loading}) => {

    const history = useHistory();

    if (!contactData || contactData.length < 1) return (
    <div className={styles.listStyle}>
        <ErrorMessage><RegularText text={emptyMessage} className={styles.rowPadding}/></ErrorMessage>
    </div>);
    return (
        <div className={styles.listStyle}>
            {contactData.map((contact, index) =>
                <ContactRow contact={contact} history={history}
                handleChange={() => {
                    handleChange()
                    setPageNumber(0);
                }} key={index}/>
            )}
            {contactMeta && <Pagination meta={contactMeta} pageNumber={pageNumber} setPageNumber={setPageNumber} loading={loading}/>}
        </div>
    );
};

export default ContactList;