import React from "react";
import styles from './Dashboard.module.scss'
import {PrimaryHeading, RegularText} from "../common";

const Dashboard = () => {

    return (
        <>
            <PrimaryHeading text={"dashboard.heading"}/>
            <div className={styles.greetingBox}>
                <RegularText text={"dashboard.greeting"}/>
                <RegularText text={"dashboard.description"}/>
                <RegularText text={"dashboard.getStarted"}/>
                <RegularText text={"dashboard.contact"}/>
                <RegularText text={"dashboard.signOff"}/>
            </div>
        </>
    );
};

export default Dashboard;