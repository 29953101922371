import React, {useEffect, useState} from "react";
import {Alert, Button, Modal, RegularText} from "../common";
import styles from "../common/_Modal/_Modal.module.scss";
import axios from "axios";
import StandardText from "../common/_StandardText";
import Loading from "../Loading/Loading";

const deactivateAccount = (id, setSuccessAlert, setFormError, handleClose, onSuccess) => {

    return axios.post("/api/payreq-accounts/deactivate-account", {accountId: id})
        .then(({data}) => {
            if (data.changed) {
                handleClose();
                setTimeout(() => setSuccessAlert(null), 6000);
                setSuccessAlert("accounts.modal.deactivateAccountSuccess");
                onSuccess();
            } else {
                setFormError(true);
            }
        })
};

const getAccountRegistrations = (accountId, setAccountRegos, setIsLoading) => {
    axios.post("/api/payreq-accounts/check-for-active-registrations", {billerId: accountId})
        .then(({data}) => {
            setAccountRegos(data);
        })
        .finally(() => {
            setIsLoading(false);
        });
}

const ActiveOrPendingRegosError = () => {
    return (
        <React.Fragment>
            <b><RegularText text={"accounts.modal.headerPreventLeave"}/></b>
            <RegularText text={"accounts.modal.messagePreventLeave"}/>
        </React.Fragment>
    )
}

const DeactivateInboxAccountModal = ({accountId, name, show, setShow, setSuccessAlert, onSuccess}) => {

    const [formError, setFormError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [accountRegos, setAccountRegos] = useState({});

    const handleClose = () => {
        setFormError(false)
        setShow(false)
    };

    useEffect(() => getAccountRegistrations(accountId, setAccountRegos, setIsLoading),
        [accountId]);

    return <Modal header={"accounts.deactivateAccount"} show={show} handleClose={() => setShow(false)}>
        {isLoading && <Loading/>}
        {!isLoading && accountRegos.hasActiveOrPending && <ActiveOrPendingRegosError/>}
        {!isLoading && !accountRegos.hasActiveOrPending && <RegularText text={"accounts.modal.deactivateAccountMessage"} values={{name: name}}/>}
        {formError && <Alert variant={"danger"}><StandardText text={"accounts.modal.deactivateAccountError"}/></Alert>}
        <Modal.Footer>
            <Button label={"accounts.modal.deactivateAccountCancelButton"} variant={"secondary"} onClick={handleClose} type="button"/>
            <Button label={"accounts.modal.deactivateAccountSubmitButton"} variant={"primary"}  loading={isLoading}
                    disabled={accountRegos.hasActiveOrPending} type="submit" className={styles.submitButton}
                    onClick={() => deactivateAccount(accountId, setSuccessAlert, setFormError, handleClose, onSuccess)}/>
        </Modal.Footer>
    </Modal>
};
export {DeactivateInboxAccountModal};