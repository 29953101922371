import React from "react";
import RegularText from "../common/_RegularText";
import {Button, Modal} from "../common";
import styles from "../common/_Modal/_Modal.module.scss";

const DeleteIntegratorModal = ({onYes, show, setShow}) => {
    return <Modal header={"integrator.deleteModal.header"} show={show} setShow={setShow} handleClose={() => setShow(false)}>
        <RegularText text="integrator.deleteModal.message"/>
        <Modal.Footer>
            <Button label={"integrator.deleteModal.noButton"} variant={"secondary"}
                    onClick={() => setShow(false)}/>
            <Button label={"integrator.deleteModal.deleteButton"} variant={"danger"}
                    onClick={() => {
                        onYes();
                        setShow(false);
                    }} className={styles.submitButton}/>
        </Modal.Footer>
    </Modal>
};
export {DeleteIntegratorModal};