import styles from "./InfiniteList.module.scss"
import React from "react";
import {Button} from "../common";
import {readableDate, readableTime} from "../Helpers/DateTimeHelpers";
import Loading from "../Loading/Loading";
import StandardText from "../common/_StandardText";

function isLastPage(listMeta){
    return (listMeta['showing'] && listMeta['showing'][1] >= listMeta['total']['count']);
};

const InfiniteList = ({listData, listMeta, page, setPage, loading}) => {

    const emptyList = !listData || listData.length < 1;

    if (!loading && emptyList) return <div className={styles.listStyle}><StandardText text="specific.events.empty"/></div>
    return (
        <div className={styles.listStyle}>
            {emptyList && loading && <Loading message={"specific.events.loading"}/>}
            {!emptyList && Array.isArray(listData) && listData.map((day, index) =>
                <div key={index} className={styles.eventBlock}><h6>{readableDate(day[0].eventTime)}</h6>
                {day.map((entry, index) => (
                    <div key={index} className={styles.event}>
                        <div className={styles.eventTime}>{readableTime(entry.eventTime)}:</div>
                        <b>{<StandardText text={"specific.events." + entry.eventType}/>}</b>{entry.billId &&`- #${entry.billId}`}
                    </div>
                ))}
                </div>
            )}
            {!emptyList && !isLastPage(listMeta) && <Button onClick={() => {setPage(page + 1)}} loading={loading}><StandardText text="specific.events.LoadMore"/></Button>}
        </div>
    );
};

export default InfiniteList;