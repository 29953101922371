
const documentLocations = {
    1: {country: 'Australia',   regionCode: 'AU'},
    2: {country: 'Canada',      regionCode: 'CA'},
    3: {country: 'UK',          regionCode: 'GB'},
}

export const getDocLocationLabel = (docLocationId, intl) => {
    return intl.formatDisplayName(documentLocations[docLocationId].regionCode, {type: 'region'});
}

export const getBooleanLabel = (boolean, intl) => {
    return intl.formatMessage({id: `integrator.${boolean}`});
}

