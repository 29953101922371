import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import styles from "./EditContactFields.module.scss";
import {
    Box,
    Button, DropdownSelect,
    PrimaryHeading, RegularText, SecondaryHeading,
    StandardPage,
    TextInput
} from "../../../../../common";
import axios from "axios";
import Loading from "../../../../../Loading/Loading";
import {Form, Formik} from "formik";
import {useHistory} from "react-router-dom";
import {EditConfirmationModal} from "../../../../../common/EditConfirmationModal/EditConfirmationModal";

const dropdownLanguages = [["French", "fr"]]

const getSubTitle = (id, setTitle, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/acc-name", {params: {accountId: id}})
        .then(({data}) => {
            setTitle(data)
        })
        .finally(() => {
            setLoading(false);
        });
};

const getContactFields = (id, setContactFields, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/account-contact-fields", {params: {accountId: id}})
        .then(({data}) => {
            setContactFields(data)
        })
        .finally(() => {
            setLoading(false);
        });
};

const makeEdit = (values, id, translations = true) => {
    return axios.post("/api/payreq-accounts/edit-contact-fields",
        {
            id: id,
            translations: translations,
            data: values
        })
}

const intlKeys = {"fr": {
    contactField1: "accounts.advanced.edit.contactField1.french",
    contactField2: "accounts.advanced.edit.contactField2.french",
    contactField3: "accounts.advanced.edit.contactField3.french",
    contactField4: "accounts.advanced.edit.contactField4.french",
    contactField5: "accounts.advanced.edit.contactField5.french",
    contactField6: "accounts.advanced.edit.contactField6.french",
    contactField7: "accounts.advanced.edit.contactField7.french",
    contactField8: "accounts.advanced.edit.contactField8.french",
}}

const parseVals = (vals) => {
    for (let val in vals) {
        if (vals[val] === '' || vals[val] === '-') {
            vals[val] = null;
        }
    }
    return vals;
}

const parseValsAddLanguage = (vals, language) => {
    return {...parseVals(vals), selectLanguage: language}
}

const EditChannels = () => {

    const id = parseInt(useParams().id);
    const [title, setTitle] = useState();
    const [loading, setLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(true)
    const [contactFields, setContactFields] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [modalVals, setModalVals] = useState({selectLanguage: dropdownLanguages[0][1]});
    const history = useHistory();

    useEffect(() => getSubTitle(id, setTitle, setLoading),
        [id]);

    useEffect(() => getContactFields(id, setContactFields, setDataLoading, setModalVals),
        [id]);

    if (loading || dataLoading) return <Loading/>;
    return (Object.keys(contactFields).length) ?
        <>
            <EditConfirmationModal newVals={parseVals(modalVals)} existingVals={parseValsAddLanguage(contactFields[dropdownLanguages[0][1]], dropdownLanguages[0][1])} show={showConfirmation} setShow={setShowConfirmation} id={id} editFunc={makeEdit}
                                   intlKeys={intlKeys[modalVals.selectLanguage]} additionalFields={true} header={"accounts.contacts.edit"}/>
            <StandardPage header="accounts.primaryHeader"  values={{header: title.tagName}}
                          sub="accounts.contacts.edit.sub" parentPath={"/accounts/" + id + "/config-lists"}>
                <Box>
                    <Formik
                        initialValues={{
                            selectLanguage: dropdownLanguages[0][1],
                            contactField1: contactFields[dropdownLanguages[0][1]]["contactField1"],
                            contactField2: contactFields[dropdownLanguages[0][1]]["contactField2"],
                            contactField3: contactFields[dropdownLanguages[0][1]]["contactField3"],
                            contactField4: contactFields[dropdownLanguages[0][1]]["contactField4"],
                            contactField5: contactFields[dropdownLanguages[0][1]]["contactField5"],
                            contactField6: contactFields[dropdownLanguages[0][1]]["contactField6"],
                            contactField7: contactFields[dropdownLanguages[0][1]]["contactField7"],
                            contactField8: contactFields[dropdownLanguages[0][1]]["contactField8"],
                        }}
                        onSubmit={(values, {setSubmitting}, event) => {
                            setSubmitting(false)
                        }}
                    >
                        {({
                              values,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              errors,
                              touched,
                              isSubmitting,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className={styles.headerWarning}>
                                    <PrimaryHeading text="accounts.active.fields.header"/>
                                    <RegularText className={styles.warning} text="accounts.advanced.edit.notice"/>
                                </div>
                                <DropdownSelect id="selectLanguage"
                                                className={styles.loneAuthField}
                                                label="accounts.selectLanguage"
                                                name="selectLanguage"
                                                options={dropdownLanguages}
                                                value={values.selectLanguage}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errors={errors.selectLanguage}
                                                touched={touched.selectLanguage}
                                                labelFn={x => x}/>

                                <div className={styles.authBlock}>
                                    {[...Array(9).keys()].slice(1).map((index) => {
                                        return <TextInput id={"contactField" + index}
                                                          key={index}
                                                          label={"accounts.advanced.edit.contactField" + index + ".french"}
                                                          value={values["contactField" + index]}
                                                          placeholder={"accounts.advanced.edit.contactField" + index + ".placeholder.french"}
                                                          onChange={handleChange}/>
                                    })}
                                </div>
                                <Button className={styles.buttons} label="accountInfo.edit.save" variant="primary" loading={isSubmitting}
                                        type="submit" onClick={() => {
                                    if (!(Object.keys(errors).length)) {
                                        setModalVals(() => {
                                            let obj = {...values}
                                            delete obj.selected
                                            return obj})
                                        setShowConfirmation(true);
                                    }}}/>
                                <Button className={styles.buttons} label="accountInfo.edit.cancel" variant="secondary" onClick={() => history.push("/accounts/" + id + "/config-lists")} type="button"/>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </StandardPage>
        </>
        :
        <StandardPage header="accounts.primaryHeader"  values={{header: title.tagName}}
                      sub="accounts.contacts.edit.sub" parentPath={"/accounts/" + id + "/config-lists"}>
            <Box>
                <SecondaryHeading className={styles.channelHeader} text="accounts.contacts.edit.empty"/>
                <Button className={styles.buttons} label="accountInfo.edit.cancel" variant="secondary" onClick={() => history.push("/accounts/" + id + "/config-lists")} type="button"/>
            </Box>
        </StandardPage>
}

export default EditChannels;