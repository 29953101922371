import React, {useCallback, useState} from "react";
import axios from "axios";
import * as Yup from "yup";
import {Alert, Button, Modal, TextInput} from "../common";
import {Form, Formik} from "formik";
import RegularText from "../common/_RegularText";
import StandardText from "../common/_StandardText";
import styles from "../common/_Modal/_Modal.module.scss";

const validateBox = Yup.object().shape({
    ticket: Yup.string()
        .required("formModal.required")
});

const disableUserMFA = (userId, ticketNumber, mfaActivated, mfaRequired, mfaActivatedEmail, setDisableMFAAlertShow, setFormError, handleClose) => {

    return axios.post("/api/approvals/create-request", {
        action: "disabling-user-mfa",
        reason: ticketNumber,
        details: {id: userId, ticketNumber: ticketNumber, mfaActivated: mfaActivated, mfaRequired: mfaRequired, mfaActivatedEmail: mfaActivatedEmail},
        attribute: "id",
        value: userId.toString()})
        .then(({data}) => {
            if (data.requestExists) {
                setFormError(true);
            } else {
                handleClose();
                setTimeout(() => setDisableMFAAlertShow(false), 6000);
                setDisableMFAAlertShow(true);
            }
        })
};

const DisableUserMFAModal = ({show, setShow, setDisableMFAAlertShow, userId, mfaActivated, mfaRequired, mfaActivatedEmail}) => {

    const [formError, setFormError] = useState(false);

    const handleClose = useCallback(() => {
        setFormError(false)
        setShow(false)
    }, [setShow]);


    return <Modal header={"specific.modal.disableUserMFAHeader"} show={show} setShow={setShow} handleClose={handleClose}>
        <Formik
            initialValues={{ticket: ''}}
            validationSchema={validateBox}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(true);
                disableUserMFA(userId, values.ticket, mfaActivated, mfaRequired, mfaActivatedEmail, setDisableMFAAlertShow, setFormError, handleClose)
                    .finally(() => setSubmitting(false));
            }}
        >
            {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  setFieldValue,
                  isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                    <b><label htmlFor="ticket">{<RegularText text={"specific.modal.disableUserMFAMessage"}/>}</label></b>
                    <TextInput id="ticket"
                               placeholder={"specific.modal.messagePlaceholder"}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               value={values.ticket}
                               errors={errors.ticket}
                               touched={touched.ticket}/>

                    {formError && <Alert variant={"danger"}><StandardText text={"specific.modal.userMFAAlreadyScheduled"}/></Alert>}
                    <Modal.Footer>
                        <Button label={"specific.modal.buttonCancel"} variant={"secondary"} onClick={handleClose} type="button"/>
                        <Button label={"specific.modal.disableUserMFAYes"} variant={"primary"} loading={isSubmitting}
                                type="submit" className={styles.submitButton}/>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    </Modal>;
};
export default DisableUserMFAModal;