import React, {useEffect, useState} from "react";
import {Button, Modal, RegularText} from "../../../common";
import {RuleForm} from "../RuleForm/RuleForm";
import styles from "./CreateRuleForm.module.scss";
import axios from "axios";
import Loading from "../../../Loading/Loading";

const getValidFields = (setValidFields, setLoading) => {
    setLoading(true);
    axios.get("/api/templates/valid-rule-fields")
        .then(({data}) => {
            setValidFields(data.validFields);
        })
        .finally(() => {
            setLoading(false);
        });
}

export const CreateRuleModal = ({templateId, mailTemplateRules, show, setShow, setSuccessAlert, onSuccess}) => {
    const [loading, setLoading] = useState(true);
    const [vaildFields, setValidFields] = useState([]);

    useEffect(() => getValidFields(setValidFields, setLoading),
        []);

    const existingFields = mailTemplateRules.map((rule) => rule.fieldName);
    const availableFields = vaildFields.filter((item) => !existingFields.includes(item)).map((item) => [`templateRule.field.${item}`, item])

    return (
        <Modal header={"templateRule.edit.heading"} show={show} setShow={setShow} handleClose={() => setShow(false)}>
            {loading && <Loading/>}
            {!loading && availableFields.length === 0  ?
                <React.Fragment>
                    <RegularText text={"templateRule.edit.noFieldsToAdd"}/>
                    <Modal.Footer className={styles.buttons}>
                        <Button label={"templateRule.edit.modalOkay"} variant={"primary"}
                                type="button" onClick={() => setShow(false)}/>
                    </Modal.Footer>
                </React.Fragment>
                :<RuleForm isCreateRule={true} rule={{actorSystemBillId: templateId}} availableFields={availableFields} show={show} setShow={setShow} onSuccess={onSuccess} setSuccessAlert={setSuccessAlert}/>
            }
        </Modal>)
};