import styles from "./SSOSAMLDetails.module.scss";
import {IconText, SecondaryHeading, RegularText} from "../../../../common";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {valueParse} from "../../../../../utils/display-helper-utils";
import Loading from "../../../../Loading/Loading";

const getSSODetails = (id, setSSODetails, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/sso-saml-details", {params: {accountId: id}})
        .then(({data}) => {
            setSSODetails(data)
        })
        .finally(() => {
            setLoading(false);
        });
};

const getSubDetails = (id, setSubDetails, setLoading) => {
    if (id >= 0) {
        axios.get("/api/payreq-accounts/sso-saml-sub-details", {params: {accountId: id}})
            .then(({data}) => {
                setSubDetails(data)
            })
            .finally(() => {
                setLoading(false);
            });
    }
};

const SSOSAMLDetails = () => {

    const [ssoDetails, setSSODetails] = useState([]);
    const [subDetails, setSubDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const id = parseInt(useParams().id);

    useEffect(() => getSSODetails(id, setSSODetails, setLoading),
        [id]);

    useEffect(() => getSubDetails(id, setSubDetails, setLoading),
        [id]);

    if (loading) return <Loading/>;
    if (ssoDetails.customerActorId === undefined) {
        return (
            <div className={styles.Box}>
                <div className={styles.titleDiv}>
                    <SecondaryHeading className={styles.titleElement} text={"accounts.sso.notconfig"}/>
                </div>
            </div>

        );
    }

    return (
        (ssoDetails.customerActorId !== null) &&
                <div className={styles.Box}>
                    <div className={styles.titleDiv}>
                    <SecondaryHeading className={styles.titleElement} text={"accounts.sso.header"}/>
                    {(ssoDetails.permissionsClaim) ?
                            <SecondaryHeading className={styles.secondaryHeading} text={"accounts.sso.customised"}/>
                            : <SecondaryHeading className={styles.secondaryHeading} text={"accounts.sso.default"}/>
                        }
                    </div>
                    <div>
                        <div className={styles.subFields}>
                            <div className={styles.keyVal}>
                                <IconText className={styles.fieldLabel} text={"accounts.sso.endpoint"}/>
                                <RegularText className={styles.fieldText}>{valueParse(ssoDetails.acsId)}</RegularText>
                            </div>
                            <div className={styles.keyVal}>
                                <IconText className={styles.fieldLabel} text={"accounts.sso.mandatory.sso"}/>
                                <RegularText className={styles.fieldText}>{valueParse(ssoDetails.ssoRequired)}</RegularText>
                            </div>
                        </div>
                        <div className={styles.subFields}>
                            <div className={styles.keyVal}>
                                <IconText className={styles.fieldLabel} text={"accounts.sso.idp.url"}/>
                                <RegularText className={styles.fieldText}>{valueParse(ssoDetails.idpUrl)}</RegularText>
                            </div>
                            <div className={styles.keyVal}>
                                <IconText className={styles.fieldLabel} text={"accounts.sso.certificate.name"}/>
                                <RegularText className={styles.fieldText}>{valueParse(ssoDetails.idpCert)}</RegularText>
                            </div>
                        </div>
                        {(ssoDetails.permissionsClaim !== 0) &&
                            <div className={styles.subFieldsTable}>
                                <div>
                                    <IconText className={styles.fieldLabel} text={"accounts.sso.payreq.role"}/>
                                    {subDetails.map((element, index) =>
                                        <div key={index}>
                                            <RegularText className={styles.subElement}>{element.payreqUserRole}</RegularText>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.rightDiv}>
                                    <IconText className={styles.fieldLabel} text={"accounts.sso.claim.value"}/>
                                    {subDetails.map((element, index) =>
                                        <div key={index}>
                                            <RegularText className={styles.subElement}>{element.permissionId}</RegularText>
                                        </div>
                                    )}
                                </div>
                            </div>}
                    </div>

                </div>

    );

}

export default SSOSAMLDetails;


