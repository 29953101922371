import React from "react";
import {Button, Check, Modal, TextInput} from "../common";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import styles from "./FormModal.module.scss"
import RegularText from "../common/_RegularText";

const handleSubmit = async (onYes, values, {setSubmitting}) => {

    await onYes(values.textBox, Object.keys(values).filter(val => val !== "textBox" && values[val]));
    setSubmitting(false)
}

const FormModal = ({header, message, affLabel, affVariant, negLabel, negVariant, onYes, onNo, show, setShow,
                       textFieldMessage, textPlaceholder, checkBoxOptions, checkBoxMessage}) => {

    const handleClose = () => {
        setShow(false)
    };
    const validateBox = Yup.object().shape({
        textBox: Yup.string()
            .required("formModal.required")
    })

    return (
        <Modal header={header} show={show} handleClose={handleClose}>
            {message && <div className={styles.message}>{message}</div>}
            <Formik
                initialValues={{textBox: ''}}
                validationSchema={validateBox}
                onSubmit={(values, helpers) => {
                    handleSubmit(onYes, values, helpers)
                        .finally(handleClose)
                }}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched,
                      setFieldValue,
                      isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <b><label htmlFor="textBox">{textFieldMessage &&
                        <RegularText text={textFieldMessage}/>}</label></b>
                        <TextInput id="textBox"
                                   placeholder={textPlaceholder && textPlaceholder}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.textBox}
                                   errors={errors.textBox}
                                   touched={touched.textBox}/>
                        {checkBoxMessage && (checkBoxOptions.length > 0) && <b><label htmlFor="checkBox">
                            <RegularText text={checkBoxMessage}/></label></b>}
                        {checkBoxOptions &&
                        <Check options={checkBoxOptions} id={"checkbox"} setFieldValue={setFieldValue}/>}
                        <Modal.Footer className={styles.buttons}>
                            {negLabel && <Button label={negLabel} variant={negVariant} onClick={onNo} type="button"/>}
                            <Button label={affLabel} variant={affVariant} type={"submit"} loading={isSubmitting} className={styles.submitButton}/>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default FormModal;