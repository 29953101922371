import React, {useState} from "react";
import {Alert, Button, DropdownSelect, Modal, StandardText, TextInput} from "../common";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import axios from "axios";
import {getDocLocationLabel} from "../Integrators/IntegratorUtils";
import styles from "./IntegratorDetails.module.scss"

const documentLocationOpts = [1, 2, 3].map(id => [id, intl => getDocLocationLabel(id, intl)]);
const trueAndFalseOpts = [true, false].map((opt) => [`integrator.${opt}`, opt]);

const updateIntegrator = (integrator, setError, setSuccessAlert, handleClose) => {
    return axios.post("/api/integrators/update-integrator", integrator)
        .then(({data}) => {
            if (data.success) {
                handleClose()
                setTimeout(() => setSuccessAlert(), 6000);
                setSuccessAlert("integrator.editModal.updateSuccess");
            } else {
                setTimeout(() => setError(false), 6000);
                setError(true);
                throw new Error("Invalid Integrator")
            }
        })
}

export const EditIntegratorModal = ({
        integrator = {},
        show, setShow,
        setSuccessAlert,
        onSuccess
    }) => {

    const [error, setError] = useState(false);

    const handleClose = () => {
        setError(false)
        setShow(false)
    };

    const validate = Yup.object().shape({
        description: Yup.string().nullable().required("formModal.required"),
        documentLocationId: Yup.number().required("formModal.required"),
        billFiles: Yup.boolean().required("formModal.required"),
        contactFiles: Yup.boolean().required("formModal.required"),
        fileTxRequiresAck: Yup.boolean().required("formModal.required"),
        printFiles: Yup.boolean().required("formModal.required"),
        usingAwsTransfer: Yup.boolean().required("formModal.required"),
        usingThirdPartyTransfer: Yup.boolean().required("formModal.required")
    });

    return (
        <Modal
            header={"integrator.editModal.heading"}
            show={show}
            setShow={setShow}
            handleClose={() => handleClose()}
        >

            <Formik
                initialValues={integrator}
                validationSchema={validate}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(true);
                    updateIntegrator(values, setError, setSuccessAlert, handleClose)
                        .then(() => onSuccess())
                        .finally(() => setSubmitting(false))
                }}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                  }) => (
                      <Form onSubmit={handleSubmit}>
                          <TextInput
                              id="description"
                              label={"integratorDetail.description"}
                              placeholder={"integratorDetail.description"}
                              type={"text"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.description}
                              errors={errors.description}
                              touched={touched.description}/>
                          <DropdownSelect
                              label="integratorDetail.documentLocation"
                              name={"documentLocation"}
                              options={documentLocationOpts}
                              onChange={(e) => {
                                  setFieldValue("documentLocationId", parseInt(e.target.value));
                              }}
                              value={values.documentLocationId}
                              onBlur={handleBlur}
                              errors={errors.documentLocationId}
                              touched={touched}/>
                          <DropdownSelect
                              label="integratorDetail.contactFiles"
                              name={"contactFiles"}
                              options={trueAndFalseOpts}
                              onChange={(e) => {
                                  setFieldValue("contactFiles", e.target.value === 'true');
                              }}
                              value={values.contactFiles}
                              onBlur={handleBlur}
                              errors={errors.contactFiles}
                              touched={touched}/>
                          <DropdownSelect
                              label="integratorDetail.billFiles"
                              name={"billFiles"}
                              options={trueAndFalseOpts}
                              onChange={(e) => {
                                  setFieldValue("billFiles", e.target.value === 'true');
                              }}
                              value={values.billFiles}
                              onBlur={handleBlur}
                              errors={errors.billFiles}
                              touched={touched}/>
                          <DropdownSelect
                              label="integratorDetail.fileTxRequiresAck"
                              name={"fileTxRequiresAck"}
                              options={trueAndFalseOpts}
                              onChange={(e) => {
                                  setFieldValue("fileTxRequiresAck", e.target.value === 'true');
                              }}
                              value={values.fileTxRequiresAck}
                              onBlur={handleBlur}
                              errors={errors.fileTxRequiresAck}
                              touched={touched}/>
                          <DropdownSelect
                              label="integratorDetail.printFiles"
                              name={"printFiles"}
                              options={trueAndFalseOpts}
                              onChange={(e) => {
                                  setFieldValue("printFiles", e.target.value === 'true');
                              }}
                              value={values.printFiles}
                              onBlur={handleBlur}
                              errors={errors.printFiles}
                              touched={touched}/>

                          <DropdownSelect
                              label="integratorDetail.usingAwsTransfer"
                              name={"usingAwsTransfer"}
                              options={trueAndFalseOpts}
                              onChange={(e) => {
                                  setFieldValue("usingAwsTransfer", e.target.value === 'true');
                              }}
                              value={values.usingAwsTransfer}
                              onBlur={handleBlur}
                              errors={errors.usingAwsTransfer}
                              touched={touched}/>

                          <DropdownSelect
                              label="integratorDetail.usingThirdPartyTransfer"
                              name={"usingThirdPartyTransfer"}
                              options={trueAndFalseOpts}
                              onChange={(e) => {
                                  setFieldValue("usingThirdPartyTransfer", e.target.value === 'true');
                              }}
                              value={values.usingThirdPartyTransfer}
                              onBlur={handleBlur}
                              errors={errors.usingThirdPartyTransfer}
                              touched={touched}/>

                        {error && <Alert variant={"danger"}><StandardText text={"integrator.editModal.error"}/></Alert>}

                        <Modal.Footer className={styles.buttons}>
                            <Button label={"integrator.editModal.cancelButton"} variant={"secondary"}
                                    type="button" onClick={handleClose}/>
                            <Button label={"integrator.editModal.saveButton"} variant={"primary"} loading={isSubmitting}
                                    type="submit" className={styles.submitButton}/>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>)
};