import React, {useState} from "react";
import {Alert, Button, Check, Modal, StandardText, TextInput} from "../../../common";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import styles from "../../../common/_Modal/_Modal.module.scss";
import axios from "axios";
import {useAppState} from "../../../../state";
import {NOTE_EDITED} from "../../../../state/reducers/alertReducer";

const editNote = (id, content, customerNote, setFormError, handleClose, dispatch) => {

    return axios.post("/api/payreq-accounts/edit-note",
        {
            noteId: id,
            note: content,
            customerNote: customerNote
        })
        .then(({data}) => {
            if (data.updated) {
                dispatch({type: NOTE_EDITED})
                handleClose()
            } else {
                setFormError(true);
            }
        })
}

const EditNoteModal = ({notePrefill, setNotePrefill, handleChange, show, setShow}) => {

    const [formError, setFormError] = useState(false);
    const [, dispatch] = useAppState();
    const {noteId, noteContent, customerNote} = notePrefill;

    const handleClose = () => {
        setFormError(false)
        setShow(false)
        setNotePrefill({})
    };

    const validateBox = Yup.object().shape({
        note: Yup.string()
            .required("formModal.required")
    })

    return <Modal header={"accounts.notes.editModal.header"} show={show} handleClose={() => setShow(false)}>
        <Formik
            initialValues={{note: noteContent, customerNote: customerNote}}
            validationSchema={validateBox}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(true);
                editNote(noteId, values.note, values.customerNote, setFormError, handleClose, dispatch)
                    .then(() => handleChange())
                    .finally(() => setSubmitting(false))
            }}
        >
            {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                    <TextInput id="note"
                               as={"textarea"}
                               label={"accounts.notes.editModal.label"}
                               placeholder={"accounts.notes.editModal.placeholder"}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               value={values.note}
                               errors={errors.note}
                               touched={touched.note}/>
                    <Check id="customerNote" setFieldValue={setFieldValue} options={[{
                        label: <StandardText text={"accounts.notes.editModal.share"}/>,
                        val: "customerNote",
                        defaultChecked: customerNote
                    }]}/>
                    {formError && <Alert variant={"danger"}><StandardText text={"accounts.notes.editModal.formError"}/></Alert>}
                    <Modal.Footer>
                        <Button label={"accounts.notes.editModal.cancel"} variant={"secondary"} onClick={handleClose} type="button"/>
                        <Button label={"accounts.notes.editModal.editNote"} variant={"primary"} loading={isSubmitting}
                                type="submit" className={styles.submitButton}/>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    </Modal>
};
export {EditNoteModal};