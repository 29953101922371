import styles from "./EditCustomer.module.scss";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import {Button, DropdownSelect, TextInput} from "../../../common";
import Loading from "../../../Loading/Loading";
import {SecondaryHeading, StandardPage, Box} from "../../../common";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import StandardText from "../../../common/_StandardText";
import ErrorMessage from "../../../ErrorMessage";
import {useIntl} from "react-intl";
import RegularText from "../../../common/_RegularText";
import Pagination from "../../../Pagination/Pagination";
import {parseUTCDateString} from "../../../Helpers/DateTimeHelpers";


const getSubTitle = (id, setTitle, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/acc-name", {params: {accountId: id}})
        .then(({data}) => {
            setTitle(data)
        })
        .finally(() => {
            setLoading(false);
        });
};

const getCustomers = (searchTerm, pageNumber, setCustomers, setCustomerMeta, setLoading, searched) => {
    setLoading(true);
    if (searched) {
        axios.get("/api/payreq-accounts/customers", {params: {search: searchTerm, page: pageNumber}})
            .then(({data}) => {
                setCustomers(data.customers);
                setCustomerMeta(data.meta);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        setLoading(false);
    }


};

const getCountries = (setCountries, setLoadingCountries) => {
    setLoadingCountries(true);
    axios.get("/api/payreq-accounts/countries", {params: {}})
        .then(({data}) => {
            setCountries(data.map((country) => [country.name, country.id]));
        })
        .finally(() => {
        setLoadingCountries(false);
    });
};

const getStates = (setStates, setLoadingState) => {
    setLoadingState(true);
    axios.get("/api/payreq-accounts/states", {params: {}})
        .then(({data}) => {
            setStates(data.reduce((accu, x) => {
                const x1 = [x.name, x.id];
                if (accu[x.countryId]) { accu[x.countryId].push(x1); } else { accu[x.countryId] = [x1]; }
                return accu;
            }, {}))
        })
        .finally(() => {
        setLoadingState(false);
    });
};

const makeEdit = (values, history) => {
    if (values.customer) {
        return axios.post("/api/payreq-accounts/add-existing-customer", {accountId: parseInt(values.accountId), customer: parseInt(values.customer)})
            .then(({data}) => {
                if (data[0].updateCount) {
                    history.replace("/accounts/" + values.accountId)
                }
            })
    } else {
        countryStateInit(values);
        return axios.post("/api/credits/init-customer", {accountId: parseInt(values.accountId), name: values.name,
            country: parseInt(values.country), state: parseInt(values.state), packId: parseInt(values.plan), expiry: calcExpiry(parseInt(values.plan))})
            .then(() => {
                history.replace("/accounts/" + values.accountId)
            })}
};

const countryStateInit = (values) => {
    if (values.country === '') {
        values.country = null;
    }
    if (values.state === '') {
        values.state = null;
    }
};
const endOfTime = "9999-12-31";
const calcExpiry = (plan) => {
    if (plan === 1) {
        return parseUTCDateString(endOfTime);
    } else {
        let six = new Date(Date.now());
        six = new Date(six.setMonth(six.getMonth()+6)).toISOString();
        return six;
    }
}

const CustomersTable = ({customers, customerMeta, pageNumber, setPageNumber, searched, setFieldValue, values}) => {

    const invalid = !customers || !customers.length;
    if (invalid) return (searched) ?
        <ErrorMessage><StandardText text="accounts.customer.search.empty"/></ErrorMessage> :
        null;

    return (
        <div className={styles.list}>
            <b><RegularText className={styles.example} text={"accounts.customer.guide"}/></b>
            <hr/>
            {customers.map((user) =>
                <div className={(values.customer === user.id) ? styles.rowSelected : styles.row} key={user.id}
                     onClick={() => {
                    if (values.customer === user.id) {
                         setFieldValue("customer", "");
                     } else {
                         setFieldValue("customer", user.id);
                     }}}>
                    <div className={styles.label}>
                        {user['name'] + ' (' + user['id'] + ') '}
                    </div>
                </div>
            )}
            <Pagination meta={customerMeta} pageNumber={pageNumber} setPageNumber={setPageNumber}/>
        </div>
    );
};

export const SelectCustomer = ({handleChange, handleBlur, values, intl, setFieldValue, history}) => {

    const [pageNumber, setPageNumber] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [searched, setSearched] = useState(false);
    const [customerMeta, setCustomerMeta] = useState([]);
    const [loadingCustomer, setLoadingCustomer] = useState(true);
    const [customers, setCustomers] = useState([]);


    useEffect(() => getCustomers(searchTerm, pageNumber, setCustomers, setCustomerMeta,
        setLoadingCustomer, searched), [searchTerm, searched, pageNumber]);

    return (
        <>
            <TextInput id="search"
                       className={styles.searchBar}
                       placeholder={"searchBar.placeholder"}
                       onChange={(change) => {
                           handleChange(change);
                       }}
                       onKeyDown={(event) => {
                           if (event.keyCode === 13) {
                               event.preventDefault();
                               setSearchTerm(values.search);
                               setPageNumber(0);
                               setSearched(true);
                           }
                       }}
                       onBlur={handleBlur}
                       value={values.search}/>
            {!loadingCustomer &&
            <CustomersTable customers={customers} customerMeta={customerMeta} pageNumber={pageNumber}
                            setPageNumber={setPageNumber} searched={searched} history={history}
                            loading={loadingCustomer} intl={intl} setFieldValue={setFieldValue}
                            values={values}/>

            }
        </>
    )

}

export const CreateCustomer = ({values, errors, handleChange, touched,
                     handleBlur}) => {

    const [loadingCountries, setLoadingCountries] = useState(true);
    const [loadingState, setLoadingState] = useState(true);
    const [countries, setCountries] = useState();
    const [states, setStates] = useState({});
    const plans = [["Prepay", 22],["Postpay", 1]]

    useEffect(() => getCountries(setCountries, setLoadingCountries), []);

    useEffect(() => getStates(setStates, setLoadingState), []);

    return (
        <>
            <TextInput
                id="name"
                label={"accounts.customer.name"}
                placeholder={"accounts.customer.placeholder.name"}
                value={values.name}
                errors={errors.name}
                onChange={(e) => {
                    values.customer = '';
                    handleChange(e)
                }}
            />

            {!loadingCountries &&
            <DropdownSelect id="country" label={"accounts.customer.country"} name={`country`} options={countries}
                            textDefault={"accounts.customer.placeholder.country"}
                            onChange={(e) => {
                                values.customer = '';
                                values.state = '';
                                handleChange(e)
                            }}
                            value={values.country} onBlur={handleBlur}
                            errors={errors.country} touched={touched.country}
                            labelFn={x => x}/>}

            {!loadingState && states[values.country] &&
            <DropdownSelect id="state" label={"accounts.customer.state"} name={`state`} options={states[values.country]}
                            textDefault={"accounts.customer.placeholder.state"}
                            onChange={(e) => {
                                handleChange(e)
                            }}
                            value={values.state} onBlur={handleBlur}
                            errors={errors.state} touched={touched.state}
                            labelFn={x => x}/>}
            {!loadingCountries &&
            <DropdownSelect id="plan" label={"accounts.customer.plan"} name={`plan`} options={plans}
                            onChange={(e) => {
                                handleChange(e)
                            }}
                            defaultValue={values.plan}
                            value={values.plan} onBlur={handleBlur}
                            errors={errors.plan} touched={touched.plan}
                            labelFn={x => x}/>}
        </>
    )
}

export const EditCustomer = () => {

    const id = parseInt(useParams().id);
    const [title, setTitle] = useState();
    const [loading, setLoading] = useState(true);

    const history = useHistory();

    const intl = useIntl();


    useEffect(() => getSubTitle(id, setTitle, setLoading),
        [id]);


    let validationSchema = Yup.object().shape({
            name: Yup.string().when('customer', {
            is: (customer) => (!customer),
            then: Yup.string().required("accounts.customer.name.error"),
            otherwise: Yup.string().optional()
            }),
    });

    if (loading) return <Loading/>;

    return (
        <StandardPage header={"accounts.primaryHeader"}  values={{header: title.tagName}}
                      sub={"accounts.customer.subedit"} parentPath={"/accounts/" + id}>
            <Box>
                <span className={styles.editCustomerHeader}> <SecondaryHeading className={styles.selectHeader} text={"accounts.customer.existing"}/></span>
                <Formik
                    initialValues={{accountId: id, name: '', country: '', state: '', customer: '', search: "", plan: 22}}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        makeEdit(values, history)
                            .finally(() => setSubmitting(false))}}
                >
                    {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          errors,
                          touched,
                          isSubmitting,
                          setFieldValue
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <SelectCustomer values={values} setFieldValue={setFieldValue}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange} intl={intl} history={history}/>
                            <span className={styles.orText}><b><StandardText text={"accounts.customer.or"}/></b></span>
                            <hr/>
                            <SecondaryHeading text={"accounts.customer.create"}/>
                            <CreateCustomer
                                values={values} errors={errors} handleChange={handleChange}
                                touched={touched}
                                handleBlur={handleBlur}/>
                            <Button className={styles.buttons} label={"accounts.customer.save"} variant={"primary"} loading={isSubmitting}
                                    type="submit"/>
                            <Button className={styles.buttons} label={"accounts.customer.cancel"} variant={"secondary"} onClick={() => history.push("/accounts/" + id)} type="button"/>
                        </Form>
                    )}
                </Formik>
            </Box>
        </StandardPage>
    );
};

export default EditCustomer;