import {Button} from "react-bootstrap";
import React from "react";
import LoadingSpinner from "../Loading/LoadingSpinner";
import styles from './Button.module.scss'
import {StandardText} from "./index";

const _Button = ({variant = "primary", type = "submit", label, string, icon, filledIcon, disabled, onClick, size, className, as, component, loading, children, form}) => {
    return (
        <Button variant={variant}
                type={type}
                disabled={disabled || loading}
                onClick={onClick}
                size={size}
                className={styles.button + " " + className}
                as={as}
                component={component}
                form={form}
        >
            <div className={styles.buttonContent}>
                {children}
                {icon && <span className={"material-icons-outlined "+ styles.icon}>{icon}</span>}
                {filledIcon && <span className={"material-icons "+ styles.icon}>{filledIcon}</span>}
                {label && <StandardText text={label}/>}
                {string}
                {loading && <div className={styles.spinner}><LoadingSpinner/></div>}
            </div>
        </Button>
    );
};

export default _Button;