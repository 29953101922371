import styles from "./Footer.module.scss"
import React from 'react';

const Footer = () => {
    const now = new Date();
    return (
        <div className={styles.footer}>
            <p className={styles.footerText}>© {now.getFullYear()}, Payreq Pty Ltd.</p>
        </div>
    )
}

export default Footer;