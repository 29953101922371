import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {Alert, Button, PrimaryHeading, SecondaryHeading, StandardPage, StandardText} from "../common";
import {useParams} from "react-router";
import InfoBox from "../InfoBox/InfoBox";
import ButtonCluster from "../ButtonCluster/ButtonCluster";
import {useHistory} from "react-router-dom";
import PaginatedList from "../PaginatedList/PaginatedList"
import {useIntl} from "react-intl";
import {SearchBar} from "../SearchBar/SearchBar";
import styles from "./AccountDetails.module.scss";
import {accountType as accountTypeIcon, cellAccStatus} from "../Helpers/Helpers";
import {readableDateTime} from "../Helpers/DateTimeHelpers";
import {RemoveUserModal} from "./RemoveUserModal";
import {ActionsUnavailableModal} from "./ActionsUnavailableModal";
import {JoinModal} from "./JoinModal";
import {LeaveModal} from "./LeaveModal";
import Loading from "../Loading/Loading";
import MailerSummary from "./MailerSummary/MailerSummary";
import SupportContacts from "./SupportContacts/SupportContacts";
import SupportNotes from "./SupportNotes/SupportNotes";
import MailTemplates from "./MailTemplates/MailTemplates";
import {ConvertInboxBusinessModal} from "./ConvertInboxType/ConvertInboxBusinessModal";
import {ConvertInboxPersonalModal} from "./ConvertInboxType/ConvertInboxPersonalModal";
import {DeactivateInboxAccountModal} from "./DeactivateInboxAccountModal";
import CustomerSummary from "./CustomerSummary/CustomerSummary";

const getAccountUsers = (searchTerm, setAccountUsers, setUserMeta, setLoading, setAccountJoined) => {
    setLoading(true);
    if (searchTerm.accountId >= 0) {
        axios.post("/api/payreq-accounts/account-users", searchTerm)
            .then(({data}) => {
                setAccountUsers(data.userRegos);
                setUserMeta(data.meta);
                setAccountJoined(data.currentUserJoined);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        setLoading(false);
    }
};

const getAccountMetaData = (accountId, setAccountMetaData, setLoading, setUploadLimit, setPendingLimit, setAccountName) => {
    setLoading(true);
    if (accountId >= 0) {
        axios.post("/api/payreq-accounts/account-info", {accountId})
            .then(({data}) => {
                setAccountMetaData(data);
                setUploadLimit(data.maxRegoBulk);
                setPendingLimit(data.maxRegoPending);
                setAccountName(data.tagName);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        setLoading(false);
    }
};

const joinAccount = (billerId, setAccountJoined, reason) => {
    return axios.post("/api/payreq-accounts/join-account", {billerId: billerId, message: reason})
        .then(() => {
            setAccountJoined(accountJoined => !accountJoined);
        })
};

const leaveAccount = (billerId, userId, setAccountJoined, reason = "Leaving Account") => {
    return axios.post("/api/payreq-accounts/leave-account", {billerId: billerId, id: userId, message: reason})
        .then(() => {
            setAccountJoined(accountJoined => !accountJoined);
        })
};

const InvalidAccount = () => (
    <div>
        <PrimaryHeading text="accounts.invalidAccount" sub="accounts.primarySubHeader"/>
    </div>
);

function handleChangeInboxType(accountType, setShowConvertToBusiness, setShowConvertToPersonal) {
    (accountType === "inbox") ?
        setShowConvertToBusiness(true) :
        setShowConvertToPersonal(true)
}

const ImagePreviewLink = ({href, placeholder}) => {
    if (href) {
        return (
            <a href={href} target={"_blank"} rel="noreferrer">
                {href.substring(href.lastIndexOf("/") + 1)}
            </a>
        );
    }
    return placeholder;
};

const AccountInfo = ({accountMetaData, accountId, accountUserId, setShowDeleteModal,
                         showDeleteModal, accountJoined, setAccountJoined, setSuccessAlert, handleChange}) => {
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [showLeaveModal, setShowLeaveModal] = useState(false);
    const [showDisabledModal, setShowDisabledModal] = useState(false);
    const [showConvertInboxBusiness, setShowConvertInboxBusiness] = useState(false);
    const [showConvertInboxPersonal, setShowConvertInboxPersonal] = useState(false);
    const [showDeactivateAccountModal, setShowDeactivateAccountModal] = useState(false);
    const id = parseInt(useParams().id);
    const history = useHistory();

    if (!accountMetaData || accountMetaData.length === 0) return <InvalidAccount/>

    const accountType = accountMetaData['systemId'];
    return (
        <div>
            <ButtonCluster buttons={[
                {icon: (!accountJoined)? "group_add" :
                        "group_remove",
                    label: (!accountJoined)? "accounts.join" :
                        "accounts.leave",
                    onClick: (accountType === "delivery") ?
                        () => {setShowDisabledModal(show => !show)}
                        :
                        () => {(accountJoined)? setShowLeaveModal(show => !show) : setShowJoinModal(show => !show)},
                    variant: (accountJoined) ? "danger" : "success",
                    className: "styles.joinLeaveButton",
                },
                {icon: "open_in_browser", label: "accounts.goToAccount", variant: "primary", className: styles.button,
                    onClick: () => {
                        (accountMetaData.systemId === "delivery") ?
                            window.open(`${process.env.REACT_APP_MYBILLS_BASE_URL}/customer#/biller/${accountId}/registrations/registered`, "_blank") :
                            window.open(`${process.env.REACT_APP_MYBILLS_BASE_URL}/portal/customer/biller/${accountId}/dashboard`, "_blank")
                    },
                    disabled: !accountJoined},
                {icon: "price_change", label: "accounts.credits", path: `/accounts/${accountId}/credits`, variant: "primary", visible: accountType === "delivery"},
                {icon: "settings", label: "accounts.advanced.button", path: `/accounts/${accountId}/config-lists`, variant: "primary", visible: accountType === "delivery"},
                {icon: "mail", variant: "primary", className: styles.button, label: (accountType === "inbox") ?
                        "accounts.convert.business"
                        :
                        "accounts.convert.personal",
                    visible: accountType !== "delivery",
                    onClick: () => handleChangeInboxType(accountType, setShowConvertInboxBusiness, setShowConvertInboxPersonal)}]}/>
            <div className={styles.box}>
            <InfoBox rowsAlwaysShown={3}
                classname={styles.noBox}
                rows={[
                [{label: "accountInfo.accountName",
                    value: accountMetaData['tagName']},
                    {label: "accountInfo.accountStatus", value: cellAccStatus(accountMetaData)}],

                [{label: "accountInfo.accountAccountType", value: accountTypeIcon(accountMetaData)},
                {label: "accountInfo.accountCreated", value: readableDateTime(accountMetaData['creationDate'])}],

                [{label: "accountInfo.accountId", value: accountMetaData['customerActorId']},
                    {label: "accountInfo.accountTimezone", value: accountMetaData['timezone']}],

                [{label: "accountInfo.maxRegoBulk", value: accountMetaData['maxRegoBulk']},
                    {label: "accountInfo.maxPending", value: accountMetaData['maxRegoPending']}],

                [{label: "accountInfo.billerCode", value: accountMetaData['billerCode']},
                    {label: "accountInfo.accountShowTerms", value: accountMetaData['showTerms']}],

                [{label: "accountInfo.accountDefaultChannel", value: accountMetaData['channelPartnerSystemId']},
                    {label: "accountInfo.accountUpdatedBy", value: accountMetaData['updatedBy']}],

                [{label: "accountInfo.fastformKey", value: accountMetaData['fastformKey']},
                    {label: "accountInfo.requiresMfa", value: accountMetaData['mfaRequired']}],

                [{label: "accountInfo.mailhouseId", value: accountMetaData['mailhouseSystemId']},
                    {label: "accountInfo.logoUrl", value: <ImagePreviewLink href={accountMetaData['logoPath']} placeholder="–"/>}],

                    [{label: "accountInfo.helpAuthItemUrl", value: <ImagePreviewLink href={accountMetaData['helpImageAuthItem1']} placeholder="–"/>},
                    {label: "accountInfo.emailUrl", value:  <ImagePreviewLink href={accountMetaData['emailLogoS3']} placeholder="–"/>}],

                    [{label: "accountInfo.helpAccNumUrl", value: <ImagePreviewLink href={accountMetaData['helpImageAccountNumber']} placeholder="–"/>},
                    {label: "accountInfo.multiBillInfo", value: (accountMetaData['multiBillInfo'] === "{}")}],

                    [{label: "accountInfo.downloadLimit", value: accountMetaData['downloadLimit']},],

            ]}/>
                <Button className={styles.button} icon={"edit"} variant={"link"} label={"accountInfo.edit.mailer"} onClick={() => history.push(id + '/edit-mailer-details')}/>
            </div>
            <JoinModal show={showJoinModal} setShow={setShowJoinModal}
                               onYes={(reason) => {
                                   return joinAccount(accountMetaData['customerActorId'], setAccountJoined, reason)
                               }}
                               onNo={() => setShowJoinModal(show => !show)}
            />
            <LeaveModal show={showLeaveModal} setShow={setShowLeaveModal}
                            onYes={() => {
                                return leaveAccount(accountMetaData['customerActorId'], 0, setAccountJoined)
                            }}
                            onNo={() => setShowLeaveModal(show => !show)}
            />
            <RemoveUserModal show={showDeleteModal} setShow={setShowDeleteModal}
                                onYes={(reason) => {
                                   return leaveAccount(accountMetaData['customerActorId'], accountUserId, setAccountJoined, reason)
                                }}
                               onNo={() => setShowDeleteModal(show => !show)}
                               acceptText={(accountUserId !== 0)}
            />
            <ActionsUnavailableModal show={showDisabledModal} setShow={setShowDisabledModal} accountJoined={accountJoined}
                               onYes={() => {
                                   setShowDisabledModal(show => !show)
                               }}
                               onNo={() => setShowDisabledModal(show => !show)}
            />
            <ConvertInboxBusinessModal accountId={accountMetaData.customerActorId} show={showConvertInboxBusiness} setShow={setShowConvertInboxBusiness} handleChange={handleChange}/>
            <ConvertInboxPersonalModal accountId={accountMetaData.customerActorId} show={showConvertInboxPersonal} setShow={setShowConvertInboxPersonal} handleChange={handleChange}/>
            <DeactivateInboxAccountModal accountId={accountMetaData.customerActorId} name={accountMetaData.tagName} show={showDeactivateAccountModal} setShow={setShowDeactivateAccountModal} setSuccessAlert={setSuccessAlert} onSuccess={handleChange} />
        </div>
    );
};

const handleInfo = (userId, history) => {
    history.push('/users/' + userId);
}
const handleRemove = (userId, setAccountUserId, setShowDeleteModal) => {
    setAccountUserId(userId);
    setShowDeleteModal(true);
}

 const AccountDetails = () => {
     const [accountUsers, setAccountUsers] = useState([]);
     const [userMeta, setUserMeta] = useState([]);
     const [accountMetaData, setAccountMetaData] = useState([]);
     const [loadingMeta, setLoadingMeta] = useState(true);
     const [loadingUsers, setLoadingUsers] = useState(true);
     const [userPageNumber, setUserPageNumber] = useState(0);
     const [searchTerm, setSearchTerm] = useState("");
     const [showDeleteModal, setShowDeleteModal] = useState(false);
     const [successAlert, setSuccessAlert] = useState(null); //TODO this needs to be changed over to use the app state alert
     const [accountUserId, setAccountUserId] = useState(0);
     const [accountJoined, setAccountJoined] = useState(false);
     const [pendingLimit, setPendingLimit] = useState(0);
     const [uploadLimit, setUploadLimit] = useState(0);
     const [accountName, setAccountName] = useState(accountMetaData.tagName);
     const id = parseInt(useParams().id);
     const isDelivery = accountMetaData.systemId === "delivery";

     const handleChange = useCallback(() => getAccountMetaData(id, setAccountMetaData, setLoadingMeta, setUploadLimit, setPendingLimit,
             setAccountName),
         [id]);

     useEffect(() => getAccountMetaData(id, setAccountMetaData, setLoadingMeta, setUploadLimit, setPendingLimit,
         setAccountName),
         [id, pendingLimit, uploadLimit, accountName]);

     useEffect(() => getAccountUsers({accountId: id, search: searchTerm, pageNumber: userPageNumber},
         setAccountUsers, setUserMeta, setLoadingUsers, setAccountJoined),
         [id, userPageNumber, searchTerm, accountJoined]);

     const history = useHistory();
     const intl = useIntl();

     if (loadingMeta) return <Loading/>;

     return(
         <StandardPage header="accounts.primaryHeader" values={{header: accountName}} sub="accounts.primarySubHeader">
             {successAlert && <Alert variant={"success"}><StandardText text={successAlert}/></Alert>}
             <AccountInfo accountMetaData={accountMetaData} setShowDeleteModal={setShowDeleteModal}
                          showDeleteModal={showDeleteModal} accountUserId={accountUserId} accountJoined={accountJoined}
                          setAccountJoined={setAccountJoined} accountId={id} uploadLimit={uploadLimit}
                          setUploadLimit={setUploadLimit} pendingLimit={pendingLimit} setPendingLimit={setPendingLimit}
                          setSuccessAlert={setSuccessAlert} accountName={accountName} setAccountName={setAccountName}
                          handleChange={handleChange} history={history}/>
             {isDelivery &&
             <div className={styles.box}>
                 <SecondaryHeading className={styles.usersHeader} text={"accounts.summary.header"}/>
                 <MailerSummary/>
             </div>}
             {isDelivery &&
                 <div className={styles.box}>
                     <SecondaryHeading className={styles.usersHeader} text={"accounts.contacts.header"}/>
                     <SupportContacts/>
                 </div>}
             {isDelivery &&
                 <div className={styles.box}>
                     <SecondaryHeading className={styles.notesHeader} text={"accounts.notes.header"}/>
                     <SupportNotes/>
                 </div>}
             {isDelivery &&
                 <div className={styles.box}>
                    <SecondaryHeading className={styles.usersHeader} text={"accounts.customer.details"}/>
                    <CustomerSummary/>
                 </div>}
             {isDelivery &&
                <div className={styles.box}>
                    <SecondaryHeading className={styles.notesHeader} text={"accounts.templates.header"}/>
                    <MailTemplates/>
                </div> }
             <div className={styles.box}>
                 <SecondaryHeading className={styles.usersHeader} text={"accounts.users"}/>
                 <SearchBar setPageNumber={setUserPageNumber}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            intl={intl}/>
                 <PaginatedList listData={accountUsers} listMeta={userMeta} handleInfo={handleInfo}
                                emptyMessage={"accounts.noResults"}
                                handleRemove={handleRemove} pageNumber={userPageNumber}
                                setPageNumber={setUserPageNumber} history={history}
                                setShowDeleteModal={setShowDeleteModal} setAccountUserId={setAccountUserId}
                                deleteDisabled={accountMetaData['systemId'] === "delivery"} loading={loadingUsers}/>
             </div>
         </StandardPage>
     )
 }

export default AccountDetails;