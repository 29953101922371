import styles from "./BillFields.module.scss";
import {IconText} from "../../../../common";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import Loading from "../../../../Loading/Loading";
import {valueParse} from "../../../../../utils/display-helper-utils";

const getBillFields = (id, setBillFields, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/account-bill-fields", {params: {accountId: id}})
        .then(({data}) => {
            setBillFields(data)
        })
        .finally(() => {
            setLoading(false);
        });
};

const BillFields = () => {

    const [billFields, setBillFields] = useState([]);
    const [loading, setLoading] = useState(true);
    const id = parseInt(useParams().id);

     useEffect(() => getBillFields(id, setBillFields, setLoading),
         [id])

    if (loading) return <Loading/>;

    if (Object.values(billFields).every((field) => (field === "" || field === null))) {
        return (
            <IconText className={styles.emptyText} text={"accounts.biller.fields.empty"}/>
        );
    }

    return (
        <div className={styles.billFieldsBox}>
            <div className ={styles.fields}>
                <IconText className={styles.fieldLabel} text={"accounts.biller.fields.1"}/>
                <IconText className={styles.fieldBox}>{valueParse(billFields.f1)}</IconText>
            </div>
            <div className ={styles.fields}>
                <IconText className={styles.fieldLabel} text={"accounts.biller.fields.2"}/>
                <IconText className={styles.fieldBox}>{valueParse(billFields.f2)}</IconText>
            </div>
            <div className ={styles.fields}>
                <IconText className={styles.fieldLabel} text={"accounts.biller.fields.3"}/>
                <IconText className={styles.fieldBox}>{valueParse(billFields.f3)}</IconText>
            </div>
            <div className ={styles.fields}>
                <IconText className={styles.fieldLabel} text={"accounts.biller.fields.4"}/>
                <IconText className={styles.fieldBox}>{valueParse(billFields.f4)}</IconText>
            </div>
            <div className ={styles.fields}>
                <IconText className={styles.fieldLabel} text={"accounts.biller.fields.5"}/>
                <IconText className={styles.fieldBox}>{valueParse(billFields.f5)}</IconText>
            </div>
            <div className ={styles.fields}>
                <IconText className={styles.fieldLabel} text={"accounts.biller.fields.6"}/>
                <IconText className={styles.fieldBox}>{valueParse(billFields.f6)}</IconText>
            </div>
        </div>);

}

export default BillFields;