import React from "react";

const ErrorMessage = ({children}) => {
    return (
        <div>
            <b>{children}</b>
        </div>
    );
};

export default ErrorMessage;