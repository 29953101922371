import React from "react";
import styles from "./InfoRow.module.scss";
import {StandardText} from "../common";
import {valueParse} from "../../utils/display-helper-utils";

const InfoPair = ({label, value}) => {
    return (
        <div className={styles.valPair}>
            <b><StandardText text={label}/></b>
            <div className={styles.value}>{valueParse(value)}</div>
        </div>
    )
}

export const InfoRow = ({fieldOne, fieldTwo}) => {
    return (
        <div className={styles.infoRow}>
            {fieldOne && <InfoPair label={fieldOne.label} value={fieldOne.value}/>}
            {fieldTwo && <InfoPair label={fieldTwo.label} value={fieldTwo.value}/>}
        </div>
    )

}