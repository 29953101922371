import React, {useEffect, useState} from "react";
import axios from "axios";
import RequestsList from "./RequestsList";
import {useHistory} from "react-router-dom";
import {PrimaryHeading} from "../common";
import Loading from "../Loading/Loading";

const getApprovalRequests = (pageNumber, setRequests, setRequestsMeta, setLoading) => {
    setLoading(true);
    axios.post("/api/approvals/approval-requests", {pageNumber: pageNumber})
        .then(({data}) => {
            setRequests(data.approvalRequests);
            setRequestsMeta(data.meta);
        })
        .finally(() => {
            setLoading(false);
        });
};


const ApprovalRequests = () => {

    const [requests, setRequests] = useState([]);
    const [requestsMeta, setRequestsMeta] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const history = useHistory();

    useEffect(() => getApprovalRequests(pageNumber, setRequests, setRequestsMeta, setLoading),
        [pageNumber]);

    if (loading) return <Loading/>;

    return (
        <>
            <div>
                <PrimaryHeading text="approvalRequests.header"/>
            </div>
            <RequestsList listData={requests} listMeta={requestsMeta} emptyMessage={"accounts.noResults"}
                          history={history} pageNumber={pageNumber} setPageNumber={setPageNumber}/>
        </>
    );
}

export default ApprovalRequests;