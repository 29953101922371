import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import Loading from "../../../Loading/Loading";
import {Alert, Button, IconText, SecondaryHeading, StandardPage, StandardText} from "../../../common";
import {getCreditTypeLabel, canadaTypes, textReadingRules} from "../MailTemplateUtils";
import {useIntl} from "react-intl";
import styles from "./MailTemplatesDetails.module.scss";
import {EditRuleModal} from "../EditRule/EditRuleModal";
import {CreateRuleModal} from "../CreateRule/CreateRuleModal";
import {EditTemplateModal} from "../EditTemplate/EditTemplateModal";
import {InfoRow} from "../../../InfoRow/InfoRow"

const getMailTemplate = (id, setMailTemplate, setLoading) => {
    setLoading(true);
    axios.get("/api/templates/detail", {params: {templateId: id}})
        .then(({data}) => {
            setMailTemplate(data.template);
        })
        .finally(() => {
            setLoading(false);
        });
}

const getMailTemplateRules = (id, setMailTemplateRules, setLoadingRules) => {
    setLoadingRules(true);
    axios.get("/api/templates/rules", {params: {templateId: id}})
        .then(({data}) => {
            setMailTemplateRules(data.templateRules);
        })
        .finally(() => {
            setLoadingRules(false);
        });
}

const getDocumentType = (typeName, dispatchSubject, intl) => {
    if (dispatchSubject) return dispatchSubject;
    if (canadaTypes.includes(typeName)) return intl.formatMessage({id: `templateDetail.documentType.${typeName}`});
}

const AbsentRule = ({rule}) => {
    return (
        <div className={styles.labelStyle}>
            <IconText text={rule.name} icon={"cancel"}/>
        </div>
    )
}

const LookInOptions = ({lookInOpts}) => {
    return (
        <React.Fragment>
            {lookInOpts && lookInOpts.pdfValueExtractor &&
            <div className={styles.labelStyle}>
                <IconText text={"templateRule.preProcessor"} icon={"space_bar"}/>
            </div>}
        </React.Fragment>
    )
}

const PdfLocationRule = ({rule}) => {
    const {name, label, fieldTransformFn, lookInOpts} = rule;
    const labelArray = label.split(":");

    return (
        <React.Fragment>
            <div className={styles.labelStyle}>
                <IconText text={name} icon={"place"}/>
            </div>
            <div className={styles.labelStyle}>
                {fieldTransformFn ?
                    <IconText text={"templateRule.locationFn"} values={{pageNo: labelArray[0],coords: labelArray[1].replaceAll(',', ', '), fn: fieldTransformFn}} icon={"arrow_right"}/>
                    :
                    <IconText text={"templateRule.location"} values={{pageNo: labelArray[0],coords: labelArray[1].replaceAll(',', ', ')}} icon={"arrow_right"}/>
                }
            </div>
            <LookInOptions lookInOpts={lookInOpts} />
        </React.Fragment>
    )
}

const textPosition = {
    "0": "before",
    "1": "above",
    "2": "after",
    "3": "below",
    "4": "line-below"
}

const PdfTextRule = ({rule}) => {
    const {name, position, label, fieldTransformFn, lookInOpts} = rule;

    return (
        <React.Fragment>
            <div className={styles.labelStyle}>
                <IconText text={name} icon={"text_snippet"}/>
            </div>
            <div className={styles.labelStyle}>
                {fieldTransformFn ?
                    <IconText text={"templateRule.textfn." + textPosition[position]} values={{textValue: label, fn: fieldTransformFn}}
                              icon={"arrow_right"}/>
                    :
                    <IconText text={"templateRule.text." + textPosition[position]} values={{textValue: label}}
                              icon={"arrow_right"}/>
                }
            </div>
            <LookInOptions lookInOpts={lookInOpts} />
        </React.Fragment>
    )
}

const MailRuleRow = ({rule, handleEditRule}) => {
    return (
        <div className={styles.rowStyleRules} onClick={() => handleEditRule(rule)} key={rule.id}>
            {rule.absent === true && <AbsentRule rule={rule}/>}
            {rule.absent === false && textReadingRules.includes(rule.lookIn) && <PdfTextRule rule={rule}/>}
            {rule.absent === false && !textReadingRules.includes(rule.lookIn) && <PdfLocationRule rule={rule}/>}

        </div>
    )
}

const MailTemplateRulesCard = ({templateId, mailTemplateRules, setSuccessAlert, onSuccess}) => {
    const intl = useIntl();
    const [ruleToEdit, setRuleToEdit] = useState({});
    const [showEditRuleModal, setShowEditRuleModal] = useState(false);
    const [showCreateRuleModal, setShowCreateRuleModal] = useState(false);
    const updateMailTemplateRules = mailTemplateRules.map(v => ({...v, name: intl.formatMessage({id: "templateRule.field." + v.fieldName})}))
                                                     .sort((a, b) => a.name < b.name ? - 1 : Number(a.name > b.name))

    const handleEditRule = (rule) => {
        setRuleToEdit(rule);
        setShowEditRuleModal(true);
    }

    return (
        <div className={styles.box}>
            <SecondaryHeading text={"templateRule.heading"}/>
            <div className={styles.listStyle}>
                {updateMailTemplateRules.map((rule) =>
                    <MailRuleRow rule={rule} handleEditRule={handleEditRule}/>
                )}
            </div>
            <span className={styles.addRule}>
                <Button variant={"link"} icon={"add"} label={"templateRule.edit.addRuleButton"} onClick={() => setShowCreateRuleModal(true)}/>
            </span>
            <EditRuleModal rule={ruleToEdit} show={showEditRuleModal} setShow={setShowEditRuleModal}
                           setSuccessAlert={setSuccessAlert} onSuccess={onSuccess}/>
            <CreateRuleModal templateId={templateId} mailTemplateRules={mailTemplateRules} show={showCreateRuleModal} setShow={setShowCreateRuleModal}
                             setSuccessAlert={setSuccessAlert} onSuccess={onSuccess}/>
        </div>
    )
}

const MailTemplateDetailsCard = ({mailTemplate, setSuccessAlert, onSuccess}) => {
    const [showEditTemplateModal ,setShowEditTemplateModal] = useState(false);
    const intl = useIntl();
    const {typeName, dispatchSubject, multiBillInfo, forceIgnoreContacts, readBillerAccountNumberFromContact} = mailTemplate;

    return (
        <div className={styles.box}>
            <InfoRow fieldOne={{label: "templateDetail.creditType", value: intl.formatMessage({id: getCreditTypeLabel(typeName)})}}
                     fieldTwo={{label: "templateDetail.dispatchSubject", value: getDocumentType(typeName, dispatchSubject, intl)}}/>
            <InfoRow fieldOne={{label: "templateDetail.indicator", value: multiBillInfo && multiBillInfo['indicator']}}
                     fieldTwo={{label: "templateDetail.background", value: (multiBillInfo && multiBillInfo['backgroundPdf'])|| null}}/>
            <InfoRow fieldOne={{label: "templateDetail.forceIgnoreContacts", value: forceIgnoreContacts}}
                     fieldTwo={{label: "templateDetail.readBillerAccountNoFromContacts", value: readBillerAccountNumberFromContact}}/>
            <span className={styles.addRule}> <Button variant={"link"} icon={"edit"} label={"template.edit.button"} onClick={() => setShowEditTemplateModal(true)}/></span>
            <EditTemplateModal template={mailTemplate} show={showEditTemplateModal} setShow={setShowEditTemplateModal}
                               setSuccessAlert={setSuccessAlert} onSuccess={onSuccess}/>
        </div>)
}

const MailTemplatesDetails = () => {
    const [mailTemplate, setMailTemplate] = useState(null);
    const [loading, setLoading] = useState(true);

    const [mailTemplateRules, setMailTemplateRules] = useState([]);
    const [loaddingRules, setLoadingRules] = useState(true);

    const [successAlert, setSuccessAlert] = useState();

    const templateId = parseInt(useParams().templateId);

    const handleSuccessTemplateChange = useCallback(() => getMailTemplate(templateId, setMailTemplate, setLoading),
        [templateId]);

    const handleSuccessRulesChange = useCallback(() => getMailTemplateRules(templateId, setMailTemplateRules, setLoadingRules),
        [templateId]);

    useEffect(() => getMailTemplate(templateId, setMailTemplate, setLoading),
        [templateId]);

    useEffect(() => getMailTemplateRules(templateId, setMailTemplateRules, setLoadingRules),
        [templateId]);

    if (loading || loaddingRules) return <Loading/>;


    return (
        <React.Fragment>
            {successAlert && <Alert variant={"success"}><StandardText text={successAlert}/></Alert>}
            <StandardPage header="accounts.primaryHeader" values={{header: mailTemplate['displayName']}} sub="templateDetail.primarySubHeader"/>
            <MailTemplateDetailsCard mailTemplate={mailTemplate} setSuccessAlert={setSuccessAlert} onSuccess={handleSuccessTemplateChange}/>
            <MailTemplateRulesCard templateId={templateId} mailTemplateRules={mailTemplateRules} setSuccessAlert={setSuccessAlert} onSuccess={handleSuccessRulesChange}/>
        </React.Fragment>)
}

export default MailTemplatesDetails;