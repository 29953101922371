import axios from "axios";
import React, {useEffect, useState} from "react";
import Loading from "../Loading/Loading";
import {Alert, PrimaryHeading, SecondaryHeading, StandardText, Table} from "../common";
import {Button} from "../common";
import {useParams} from "react-router";
import InfoBox from "../InfoBox/InfoBox";
import ButtonCluster from "../ButtonCluster/ButtonCluster";
import {useHistory} from "react-router-dom";
import copyToClipboard from "../copyToClipboard";
import {useIntl} from 'react-intl';
import {SearchBar} from "../SearchBar/SearchBar";
import {accountType, cellStatus, LockoutStatus, StatusBadge} from "../Helpers/Helpers";
import {readableDateTime} from "../Helpers/DateTimeHelpers";
import styles from "./SpecificPayreqUsers.module.scss";
import ChangeInboxLimitModal from "./ChangeInboxLimitModal";
import {DeleteUserModal} from "./DeleteUserModal";
import {ActionsUnavailableModal} from "./ActionsUnavailableModal";
import ErrorMessage from "../ErrorMessage";
import RegularText from "../common/_RegularText";
import DisableUserMFAModal from "./DisableUserMFAModal";

const getUserRegistrations = (id, searchTerm, regoPageNumber, setUserRegistrations, setRegoMeta, setLoading) => {
    setLoading(true);
    if (id >= 0) {
        axios.post("/api/payreq-users/user-regos", {id: id, search: searchTerm, pageNumber: regoPageNumber})
            .then(({data}) => {
                setUserRegistrations(data.userRegos);
                setRegoMeta(data.meta);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        setLoading(false);
    }
};
const getUserAccounts = (id, accsPageNumber, setUserAccounts, setAccMeta, setLoading) => {
    setLoading(true);
    if (id >= 0) {
        axios.post("/api/payreq-users/user-accs", {id: id, pageNumber: accsPageNumber})
            .then(({data}) => {
                setUserAccounts(data.userAccs);
                setAccMeta(data.meta);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        setLoading(false);
    }
};
const getUserMetaData = (id, setUserMetaData, setUserIp, setLoading, setValidCode, setVerifyButtonLabel, setIsMe) => {
    setLoading(true);
    if (id >= 0) {
        axios.post("/api/payreq-users/user-info", {id: id})
            .then(({data}) => {
                setUserMetaData(data);
                getUserIp(data.uid, setUserIp, setLoading);
                setIsMe(data.isCurrent);
                if (!data.code) {
                    setValidCode(false);
                    setVerifyButtonLabel("userInfo.noValidCodes");
                }
            })
            .finally(() => {
                setLoading(false);
            });
        } else {
        setLoading(false);
    }
};

const getUserIp = (uid, setUserIp, setLoading) => {
    setLoading(true);
    axios.post("/api/payreq-users/user-ip", {uid: uid})
        .then(({data}) => {
            setUserIp(data.ip || "No IP address logged");
        })
        .finally(() => {
            setLoading(false);
        });
}

const InvalidUser = () => (
    <div>
        <PrimaryHeading text="specific.invalidUser" sub="specific.primarySubHeader"/>
    </div>
);

const copyAcceptRegoCode = (userMetaData, setVerifyButtonLabel, verifyButtonLabel) => {
    setVerifyButtonLabel("specific.copiedVerificationCode");
    setTimeout(() => setVerifyButtonLabel(verifyButtonLabel), 1500);
    copyToClipboard(`${userMetaData['code']}`);
};

const InlineUnverified = ({userMetaData, verifyButtonLabel, setVerifyButtonLabel, validCode}) => {
    return (
        <div>
            <StatusBadge status={userMetaData['status']}/>
            <Button label={verifyButtonLabel} onClick={() => copyAcceptRegoCode(userMetaData, setVerifyButtonLabel,
                verifyButtonLabel)}
                    variant={"link"} disabled={!validCode}/>
        </div>
    );
};

function changeInboxLimit(id, newLimit, currentLimit, setInboxLimit) {

    if (newLimit !== currentLimit) {
        return axios.post("/api/payreq-users/change-inbox-limit", {id: id, limit: newLimit,})
        .then(() => {
            setInboxLimit(newLimit);
        })
    }
};

const resetUserLockout = (id, setLockoutAlertShow) => {

    axios.post("/api/payreq-users/reset-lockout", {id: id})
        .then(() => {
            setTimeout(() => setLockoutAlertShow(false), 6000);
            setLockoutAlertShow(true);
        })
};

function activeRegistrations(registrations) {
    return registrations.some((rego) => rego.status <= 1)
}

const UserInfo = ({userMetaData, userIp, verifyButtonLabel, setVerifyButtonLabel, validCode, inboxLimit, setInboxLimit,
                      setRemoveAlertShow, setLockoutAlertShow, setDisableMFAAlertShow, isMe, userRegistrations, userAccounts}) => {

    const inboxLimits = [10, 20, 30, 40, 50];
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDisabledModal, setShowDisabledModal] = useState(false);
    const [showInboxLimitModal, setShowInboxLimitModal] = useState(false);
    const [showDisableUserMFAModal, setShowDisableUserMFAModal] = useState(false);

    const invalid = !userMetaData || userMetaData.length === 0 || userMetaData['status'] === 2

    if (invalid) return <InvalidUser/>

    return (
        <React.Fragment>
            <div>
                <PrimaryHeading text="specific.primaryHeader" values={{header: userMetaData['name']}} sub={(isMe) ? "specific.meSubHeader" : "specific.primarySubHeader"}/>
            </div>
            <ButtonCluster buttons={[
                {icon: "history", label: "specific.viewLoginEvents", path: `/users/${userMetaData['id']}/events`, variant: "primary"},
                {icon: "delete",
                    label: "specific.deleteUser",
                    onClick: () => {(!activeRegistrations(userRegistrations) && !isMe) ?
                        setShowDeleteModal(show => !show) :
                        setShowDisabledModal(show => !show)},
                    variant: "danger"},
                {icon: "more_horiz", variant: "primary", className: styles.dropdownNoArrow,
                    options: [
                        {label: "specific.changeInboxLimit", onClick: () => {setShowInboxLimitModal(show => !show)}},
                        {label: "specific.removeUserLockout", onClick: () => {resetUserLockout(userMetaData['id'], setLockoutAlertShow)}},
                        {label: "specific.modal.disableUserMFAHeader", onClick: () => {setShowDisableUserMFAModal(show => !show)}}
                    ]},
            ]}/>
            <InfoBox
                rowsAlwaysShown={3}
                rows={[
                    [{label: "userInfo.name", value: userMetaData['name']},
                        {label: "userInfo.status", value: (userMetaData['status']) ?
                            <StatusBadge status={userMetaData['status']}/> :
                            <InlineUnverified validCode={validCode} verifyButtonLabel={verifyButtonLabel}
                            userMetaData={userMetaData} setVerifyButtonLabel={setVerifyButtonLabel}/>}],
                    [{label: "userInfo.email", value: userMetaData['email']},
                        {label: "userInfo.lockoutStatus", value: LockoutStatus(userMetaData['failedLogins'], userMetaData['lockoutDate'])}],

                    [{label: "userInfo.phone", value: userMetaData['mobileNumber']},
                        {label: "userInfo.timeCreated", value: readableDateTime(userMetaData['createdTime'])}],

                    [{label: "userInfo.uid", value: userMetaData['uid']},
                        {label: "userInfo.payerId", value: userMetaData['id']}],

                    [{label: "userInfo.country", value: userMetaData['countryCode']},
                        {label: "userInfo.notes", value: userMetaData['notes']}],

                    [{label: "userInfo.language", value: userMetaData['language']},
                        {label: "userInfo.helpMessages", value: userMetaData['showHelpMessages']}],

                    [{label: "userInfo.contactDetails", value: userMetaData['contactDetails']},
                        {label: "userInfo.maxIncomingOrgs", value: userMetaData['maxIncomingOrgs']}],

                    [{label: "userInfo.workPhone", value: userMetaData['workPhoneNumber']},
                        {label: "userInfo.mfaRequired", value: userMetaData['mfaRequired']}],

                    [{label: "userInfo.defaultSearchDateRange", value: userMetaData['defaultSearchDateRange']},
                        {label: "userInfo.mfaActivated", value: userMetaData['mfaActivated']}],

                    [{label: "userInfo.verificationCode", value: userMetaData['code']},
                        {label: "userInfo.deviceVerificationRequired", value: userMetaData['deviceVerificationRequired']}],

                    [{label: "userInfo.referrer", value: userMetaData['referrer']},
                        {label: "userInfo.updatedBy", value: userMetaData['updatedBy']}],

                    [{label: "userInfo.userIp", value: userIp},
                        {label: "userInfo.defaultBillStatus", value: userMetaData['defaultBillStatus']}]
            ]}/>
            <DeleteUserModal show={showDeleteModal} setShow={setShowDeleteModal} id={userMetaData['id']}
                             setRemoveAlertShow={setRemoveAlertShow}
                             userAccounts={userAccounts.map(acc => (
                               {label: (acc.totalUsers > 1) ?
                                       <StandardText text="specific.modal.usersJoined" values={{name: acc.tagName, number: acc.totalUsers}}/> :
                                       <StandardText text="specific.modal.userJoined" values={{name: acc.tagName, number: acc.totalUsers}}/>,
                                   val: acc.customerActorId, disabled: (acc.totalUsers > 1)}))}
            />
            <ActionsUnavailableModal isMe={isMe} show={showDisabledModal} setShow={setShowDisabledModal}
                                     onYes={() => {setShowDisabledModal(show => !show)}}
            />
            <ChangeInboxLimitModal show={showInboxLimitModal} setShow={setShowInboxLimitModal}
                                   onYes={(values) => {
                                       return changeInboxLimit(userMetaData['id'], parseInt(values.dropdown0), inboxLimit, setInboxLimit);
                                   }}
                                   onNo={() => setShowInboxLimitModal(false)}
                                   fields={[{label: "specific.changeInboxLimit", options: inboxLimits, defaultValue: userMetaData['maxIncomingOrgs']}]}
            />
            <DisableUserMFAModal show={showDisableUserMFAModal} setShow={setShowDisableUserMFAModal} setDisableMFAAlertShow={setDisableMFAAlertShow}
                                 userId={userMetaData['id']} mfaActivated={userMetaData['mfaActivated']}
                                 mfaRequired={userMetaData['mfaRequired']} mfaActivatedEmail={userMetaData['deviceVerificationRequired']}
            />
        </React.Fragment>
    );
}

const HIDDEN_COLS = ['ssid'];

const regoColumnHeaders = [
    {
        Header: <StandardText text="regoTable.ssid"/>,
        accessor: 'ssid',
    },
    {
        Header: <StandardText text="regoTable.accountNumber"/>,
        accessor: 'accountNumber',
    },
    {
        Header: <StandardText text="regoTable.payerName"/>,
        accessor: 'nameAccountHeld',
    },
    {
        Header: <StandardText text="regoTable.customerName"/>,
        accessor: 'authItem1',
    },
    {
        Header: <StandardText text="regoTable.channel"/>,
        accessor: 'channelPartnerSystemId',
    },
    {
        Header: <StandardText text="regoTable.status"/>,
        accessor: 'status',
        Cell: cellStatus,
    },
    {
        Header: <StandardText text="regoTable.billerName"/>,
        accessor: 'tagName',
    },
    {
        Header: <StandardText text="regoTable.mybillsDisplayName"/>,
        accessor: 'mybillsDisplayName',
    },
    {
        Header: <StandardText text="regoTable.billerId"/>,
        accessor: 'billerActorId',
    },
    {
        Header: <StandardText text="regoTable.regoDate"/>,
        accessor: 'createdDate',
        Cell: (row) => readableDateTime(row['createdDate']),
    },
];

const handleRegoRowClick = (data) => {
    let billerNumber = data[0];
    let regoChannel = data[1].toUpperCase();
    let payerId = data[2];
    if (payerId) {
        window.open(`${process.env.REACT_APP_MYBILLS_BASE_URL}/customer#/biller/${billerNumber}/registration/${regoChannel}${payerId}`, "_blank");
    }
};

const UserRegistrations = ({userRegistrations, regoMeta, regoPageNumber, setRegoPageNumber, loading}) => {

    const invalid = !userRegistrations || userRegistrations.length === 0;

    if (invalid) return <ErrorMessage><RegularText text="specific.noResults"/></ErrorMessage>;

    return (
            <Table columnHeaders={regoColumnHeaders} hiddenCols={HIDDEN_COLS} tableData={userRegistrations}
                        tableMeta={regoMeta} pageNumber={regoPageNumber} setPageNumber={setRegoPageNumber}
                        handleRowClick={handleRegoRowClick}
                        targetColumns={['billerActorId','channelPartnerSystemId','ssid']} loading={loading}/>
    );
};

const accColumnHeaders = [
    {
        Header: <StandardText text="accTable.id"/>,
        accessor: 'customerActorId',
    },
    {
        Header: <StandardText text="accTable.tagName"/>,
        accessor: 'tagName',
    },
    {
        Header: <StandardText text="accTable.accountType"/>,
        accessor: 'systemId',
        Cell: accountType,
    },
    {
        Header: <StandardText text="accTable.status"/>,
        accessor: 'status',
        Cell: cellStatus,
    },
    {
        Header: <StandardText text="accTable.timezone"/>,
        accessor: 'timezone',
    },
    {
        Header: <StandardText text="accTable.creationDate"/>,
        accessor: 'creationDate',
        Cell: (row) => readableDateTime(row['creationDate']),
    },
];

const UserAccounts = ({userAccounts, accMeta, accsPageNumber, setAccPageNumber, history, loading}) => {

    const invalid = !userAccounts || userAccounts.length === 0;

    if (invalid) return <ErrorMessage><RegularText text="specific.noAccounts"/></ErrorMessage>;

    return (
        <Table columnHeaders={accColumnHeaders} hiddenCols={HIDDEN_COLS} tableData={userAccounts}
                    tableMeta={accMeta} pageNumber={accsPageNumber} setPageNumber={setAccPageNumber}
                    handleRowClick={(data) => {
                        history.push('/accounts/' + data[0])
                    }}
                    targetColumns={['customerActorId']} loading={loading}/>
    );
};

const SpecificPayreqUser = () => {
    const [userRegistrations, setUserRegistrations] = useState([]);
    const [regoMeta, setRegoMeta] = useState([]);
    const [userAccounts, setUserAccounts] = useState([]);
    const [accMeta, setAccMeta] = useState([]);
    const [userMetaData, setUserMetaData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingRegistrations, setLoadingRegistrations] = useState(true);
    const [loadingAccs, setLoadingAccs] = useState(true);
    const [regoPageNumber, setRegoPageNumber] = useState(0);
    const [accsPageNumber, setAccPageNumber] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [validCode, setValidCode] = useState(true);
    const [inboxLimit, setInboxLimit] = useState(0);
    const [isMe, setIsMe] = useState(false);
    const [removeAlertShow, setRemoveAlertShow] = useState(false);
    const [lockoutAlertShow, setLockoutAlertShow] = useState(false);
    const [disableMFAAlertShow, setDisableMFAAlertShow] = useState(false);
    const [verifyButtonLabel, setVerifyButtonLabel] = useState("specific.copyVerificationCode");
    const [userIp, setUserIp] = useState("")

    const id = parseInt(useParams().id);
    const intl = useIntl();
    const history = useHistory();

     useEffect(() => getUserMetaData(id, setUserMetaData, setUserIp, setLoading, setValidCode, setVerifyButtonLabel, setIsMe),
         [id, inboxLimit]);

     useEffect(() => getUserRegistrations(id, searchTerm, regoPageNumber, setUserRegistrations, setRegoMeta,
         setLoadingRegistrations),
         [id, userMetaData, searchTerm, regoPageNumber]);

     useEffect(() => getUserAccounts(id, accsPageNumber, setUserAccounts, setAccMeta, setLoadingAccs),
         [id, userMetaData, accsPageNumber]);

     if (loading) return <Loading/>;

     return(
         <React.Fragment>
             {removeAlertShow && <Alert variant={"success"}><StandardText text="specific.removeUserSuccess"/></Alert>}
             {lockoutAlertShow && <Alert variant={"success"}><StandardText text="specific.removeUserLockoutAlert"/></Alert>}
             {disableMFAAlertShow && <Alert variant={"success"}><StandardText text="specific.disableUserMFASuccess"/></Alert>}
             <UserInfo userMetaData={userMetaData} userIp={userIp} validCode={validCode}
                       userAccounts={userAccounts} userRegistrations={userRegistrations} isMe={isMe}
                       setRemoveAlertShow={setRemoveAlertShow} setLockoutAlertShow={setLockoutAlertShow}
                       setDisableMFAAlertShow={setDisableMFAAlertShow} verifyButtonLabel={verifyButtonLabel}
                       setVerifyButtonLabel={setVerifyButtonLabel} inboxLimit={inboxLimit} setInboxLimit={setInboxLimit}/>
             <div className={styles.regoBox}>
                 <SecondaryHeading className={styles.regoHeader} text={"specific.regoHeader"}/>
                 <SearchBar setPageNumber={setRegoPageNumber}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            intl={intl}/>
                 <UserRegistrations userRegistrations={userRegistrations} regoMeta={regoMeta}
                                    regoPageNumber={regoPageNumber} setRegoPageNumber={setRegoPageNumber}
                                    loading={loadingRegistrations}/>
             </div>
            <div className={styles.accBox}>
                <SecondaryHeading className={styles.accHeader} text={"specific.accountsHeader"}/>
                <UserAccounts userAccounts={userAccounts} accMeta={accMeta} accsPageNumber={accsPageNumber}
                              setAccPageNumber={setAccPageNumber} history={history} loading={loadingAccs}/>
            </div>
         </React.Fragment>
     )
 }

export default SpecificPayreqUser;