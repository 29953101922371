import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import styles from "./ConfigLists.module.scss";
import {StandardPage} from "../../../common";
import ActiveChannels from "./ActiveChannels/ActiveChannels";
import AuthFields from "./AuthFields/AuthFields";
import BillFields from "./BillFields/BillFields";
import ContactFields from "./ContactFields/ContactFields";
import SSOSAMLDetails from "./SSOSAMLDetails/SSOSAMLDetails";
import {SecondaryHeading} from "../../../common";
import axios from "axios";
import Loading from "../../../Loading/Loading";

const getSubTitle = (id, setTitle, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/acc-name", {params: {accountId: id}})
        .then(({data}) => {
            setTitle(data)
        })
        .finally(() => {
            setLoading(false);
        });
};


const ConfigLists = () => {

    const id = parseInt(useParams().id);
    const [selectedChannel, setSelectedChannel] = useState(0);
    const [title, setTitle] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => getSubTitle(id, setTitle, setLoading),
        [id]);

    if (loading) return <Loading/>;

    return (
        <StandardPage header={"accounts.primaryHeader"}  values={{header: title.tagName}}
                      sub={"accounts.advanced.sub"} parentPath={"/accounts/" + id}>
            <ActiveChannels selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}/>
                <AuthFields selectedChannel={selectedChannel}/>
            <div className={styles.box}>
                <SecondaryHeading text={"accounts.biller.fields.header"}/>
                <BillFields/>
            </div>
            <div className={styles.box}>
                <SecondaryHeading text={"accounts.contacts.fields.header"}/>
                <ContactFields/>
            </div>
            <SSOSAMLDetails/>
        </StandardPage>
    );
}

export default ConfigLists;