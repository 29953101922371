import React from 'react';
import styles from "./VerticalTabbedForm.module.scss";


const ReTab = ({contentDefinition, ariaLabel, selected, setSelected}) => {

    const tabs = [],
        panels = [];

    if (contentDefinition !== null) {
        for (const tabItemDefinition of contentDefinition) {
            const uid = tabItemDefinition.uid;
            const tabHtmlId = `retab__tabslist__tab-${uid}`;
            const panelHtmlId = `retab__tabpanel-$id}`;

            tabs.push({
                uid: uid,
                htmlId: tabHtmlId,
                panelHtmlId: panelHtmlId,
                content: tabItemDefinition.tab,
            });

            panels.push({
                uid: uid,
                htmlId: panelHtmlId,
                tabHtmlId: tabHtmlId,
                content: tabItemDefinition.panel,
            });
        }

        return (
            <div className={styles.retab}>
                <Tabs ariaLabel={ariaLabel}
                  selected={selected}
                  onTabSelected={setSelected}
                  definition={tabs} />
            {
                selected <= tabs.length &&
                <Panel key={panels[selected].uid}
                       controlledBy={panels[selected].tabHtmlId}>
                    {panels[selected].content}
                </Panel>
            }
            </div>
        );
    } else {
        return null;
    }


}

function Tabs({ariaLabel, selected, onTabSelected, definition}) {
    return (
        <div role="tablist" aria-label={ariaLabel} className={styles.tabs}>
            {definition.map((tabDefinition, index) =>
                <Tab key={tabDefinition.uid}
                     id={tabDefinition.htmlId}
                     controls={tabDefinition.panelHtmlId}
                     isSelected={index === selected}
                     onSelected={() => onTabSelected(index)}>
                    {tabDefinition.content}
                </Tab>
            )}
        </div>
    );
}

function Tab({htmlId, controls, isSelected, onSelected, children}) {
    return (
        <div id={htmlId} role="tab"
                aria-selected={isSelected}
                aria-controls={controls}
                onClick={onSelected}
                className={(isSelected) ? styles.selectedTab : styles.unselectedTab}>
            {children}
        </div>
    );
}
function Panel({htmlId, controlledBy, children}) {
    return (
        <div id={htmlId}
             role="tabpanel"
             aria-labelledby={controlledBy}
             className={styles.retab__tabpanel}>
            {children}
        </div>
    );
}

export default ReTab;