import React from "react";
import {Button, DropdownSelect, Modal} from "../common";
import {Form, Formik} from "formik";
import styles from "./DropdownModal.module.scss"

const handleSubmit = async (onYes, values, {setSubmitting}) => {

    await onYes(values);
    setSubmitting(false)
}

const DropdownModal = ({header, message, affLabel, affVariant, negLabel, negVariant, onYes, onNo, show, setShow, fields}) => {

    const handleClose = () => {
        setShow(false)
    };

    return (
        <Modal header={header} show={show} handleClose={handleClose}>
            {message && <div className={styles.message}>{message}</div>}
            <Formik
                initialValues={{}}
                onSubmit={(values, helpers) => {
                    handleSubmit(onYes, values, helpers)
                        .finally(handleClose)
                }}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched,
                      isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        {fields.map((field, index) => (
                            <DropdownSelect label={field.label} name={`dropdown${index}`} options={field.options}
                                            defaultValue={field.defaultValue} onChange={handleChange}
                                            value={values[`dropdown${index}`]} onBlur={handleBlur}
                                            errors={errors[`dropdown${index}`]} touched={touched[`dropdown${index}`]}
                                            key={index}/>
                        ))}
                        <Modal.Footer className={styles.buttons}>
                            {negLabel && <Button label={negLabel} variant={negVariant} onClick={onNo} type="button"/>}
                            <Button label={affLabel} variant={affVariant} type={"submit"} loading={isSubmitting} className={styles.submitButton}/>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default DropdownModal;