import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {Alert, StandardPage, StandardText} from "../common";
import {useParams} from "react-router";
import {InfoRow} from "../InfoRow/InfoRow";
import {readableDateTime} from "../Helpers/DateTimeHelpers";
import styles from "./IntegratorDetails.module.scss";
import {getDocLocationLabel, getBooleanLabel} from "../Integrators/IntegratorUtils";
import {IntegratorStatusBadge} from "../Helpers/Helpers";
import {DeleteIntegratorModal} from "./DeleteIntegratorModal";
import {EditIntegratorModal} from "./EditIntegratorModal";
import Loading from "../Loading/Loading";
import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import ButtonCluster from "../ButtonCluster/ButtonCluster";

const deactivateIntegrator = (id, history) => {
    return axios.post("/api/integrators/deactivate", {integratorId: id})
        .then(() => history.replace("/integrators"));
}

const getIntegrator = (id, setIntegrator, setLoading) => {
    setLoading(true);
    axios.get("/api/integrators/detail", {params: {integratorId: id}})
        .then(({data}) => {
            setIntegrator(data.integrator);
        })
        .finally(() => {
            setLoading(false);
        });
}

const IntegratorDetailsCard = ({integrator}) => {
    const intl = useIntl();
    const {description, dateActivated, status, contactFiles, billFiles, fileTxRequiresAck, updatedBy,
        documentLocationId, printFiles, usingAwsTransfer, usingThirdPartyTransfer} = integrator;

    return (
        <div className={styles.box}>
            <InfoRow fieldOne={{label: "integratorDetail.description", value: description}}
                     fieldTwo={{label: "integratorDetail.status", value: <IntegratorStatusBadge status={status}/>}}/>
            <InfoRow fieldOne={{label: "integratorDetail.documentLocation",value: getDocLocationLabel(documentLocationId, intl)}}
                     fieldTwo={{label: "integratorDetail.dateActivated", value: readableDateTime(dateActivated)}}/>
            <InfoRow fieldOne={{label: "integratorDetail.contactFiles", value: getBooleanLabel(contactFiles, intl)}}
                     fieldTwo={{label: "integratorDetail.billFiles", value: getBooleanLabel(billFiles, intl)}}/>
            <InfoRow fieldOne={{label: "integratorDetail.fileTxRequiresAck", value: getBooleanLabel(fileTxRequiresAck, intl)}}
                     fieldTwo={{label: "integratorDetail.printFiles", value: getBooleanLabel(printFiles, intl)}}/>
            <InfoRow fieldOne={{label: "integratorDetail.updatedBy", value: updatedBy}}
                     fieldTwo={{label: "integratorDetail.usingAwsTransfer", value: getBooleanLabel(usingAwsTransfer, intl)}}/>
            <InfoRow fieldOne={{label: "integratorDetail.usingThirdPartyTransfer", value: getBooleanLabel(usingThirdPartyTransfer, intl)}}/>
        </div>)
}

const IntegratorDetails = () => {
    const [integrator, setIntegrator] = useState(null);
    const [loading, setLoading] = useState(true);
    const integratorId = useParams().id;
    const [successAlert, setSuccessAlert] = useState();
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const history = useHistory();

    const handleSuccessIntegratorChange = useCallback(() => getIntegrator(integratorId, setIntegrator, setLoading),
        [integratorId]);

    useEffect(() => getIntegrator(integratorId, setIntegrator, setLoading),
        [integratorId]);

    if (loading) return <Loading/>;

    return (
        <React.Fragment>
            {successAlert && <Alert variant={"success"}><StandardText text={successAlert}/></Alert>}
            <StandardPage header="integrator.header" values={{header: integrator.description}} sub="integrator.subHeader"/>
            <ButtonCluster buttons={[
                {icon: "edit", label: "integrator.editButton", variant: "primary", onClick: () => setShowEditModal(true)},
                {icon: "clear", label: "integrator.deleteButton", variant: "danger", onClick: () => setShowDeleteModal(true)}
            ]}/>
            <IntegratorDetailsCard integrator={integrator} setSuccessAlert={setSuccessAlert}/>
            <EditIntegratorModal
                integrator={integrator}
                show={showEditModal}
                setShow={setShowEditModal}
                setSuccessAlert={setSuccessAlert}
                onSuccess={handleSuccessIntegratorChange}
            />
            <DeleteIntegratorModal
                onYes={() => deactivateIntegrator(integrator.id, history)}
                show={showDeleteModal}
                setShow={setShowDeleteModal}
            />
        </React.Fragment>)
}

export default IntegratorDetails;