import React, {useEffect, useState} from "react";
import {Alert, Button, DatePicker, DropdownSelect, Modal, StandardText} from "../common";
import styles from "../Modals/DropdownModal.module.scss";
import {Form, Formik} from "formik";
import axios from "axios";
import * as Yup from "yup";
import {parseUTCDateString} from "../Helpers/DateTimeHelpers";

const getCreditOptions = (accountId, setCreditOptions) => {
    axios.post("/api/credits/list-packs", {accountId: accountId})
        .then(({data}) => {
            setCreditOptions(data);
        })
}

const addCreditPack = (accountId, packId, expiry, setError, setSuccessAlert, handleClose) => {
    return axios.post("/api/credits/add-pack", {accountId: accountId, packId: parseInt(packId), expiry: parseUTCDateString(expiry)})
        .then(({data}) => {
            if (data.success) {
                handleClose();
                setTimeout(() => setSuccessAlert(), 6000);
                setSuccessAlert("credits.addPack.success");
            } else {
                setTimeout(() => setError(false), 6000);
                setError(true);
                throw new Error("Invalid Request")
            }
        })
}

const AddCreditPackModal = ({show, setShow, accountId, setSuccessAlert, onSuccess}) => {

    const [creditOptions, setCreditOptions] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => getCreditOptions(accountId, setCreditOptions),
        [accountId]);

    const handleClose = () => {
        setShow(false)
    };

    const yesterday = new Date(Date.now() -86400000);

    const defaultExpiry = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split("T")[0];

    const validate = Yup.object().shape({
        pack: Yup.string()
            .required("formModal.required"),
        expiry: Yup.date()
            .transform((dateString) => new Date(dateString))
            .min(yesterday, "credits.modal.pastExpiry")
            .required("formModal.required")


    })

    return (
        <Modal header={"credits.modal.addCreditHeader"} show={show} handleClose={handleClose}>
            <Formik
                initialValues={{pack: '', expiry: defaultExpiry}}
                validationSchema={validate}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(true);
                    addCreditPack(accountId, values.pack, values.expiry, setError, setSuccessAlert, handleClose)
                        .then(() => onSuccess())
                        .catch(() => {})
                        .finally(() => setSubmitting(false))
                }}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched,
                      isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <DropdownSelect label="credits.modal.addCreditSelectPack" name={"pack"}
                                        options={creditOptions.map((option) => [option.name, option.id])} onChange={handleChange}
                                        value={values.pack} onBlur={handleBlur} errors={errors.pack} touched={touched}
                                        textDefault={"credits.modal.addCreditSelectPackDefault"}/>
                        <DatePicker label={"credits.modal.addCreditExpiry"} name={"expiry"} onChange={handleChange}
                                    touched={touched} onBlue={handleBlur} errors={errors.expiry} value={values.expiry}/>
                        {error && <Alert variant={"danger"}><StandardText text={"credits.modal.error"}/></Alert>}
                        <Modal.Footer className={styles.buttons}>
                            <Button label={"credits.modal.cancel"} variant={"secondary"} onClick={handleClose} type="button"/>
                            <Button label={"credits.modal.addCreditSave"} variant={"primary"} type={"submit"}
                                    loading={isSubmitting} className={styles.submitButton}/>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default AddCreditPackModal;