import React from "react";
import RegularText from "../common/_RegularText";
import {Button, Modal} from "../common";
import styles from "../common/_Modal/_Modal.module.scss";

const ActionsUnavailableModal = ({isMe, show, setShow}) => {

    return <Modal header={(isMe) ? "specific.modal.unavailableMeHeader" : "specific.modal.unavailableHeader"} show={show} setShow={setShow} handleClose={() => setShow(false)}>
        {(isMe) ?
            <RegularText text="specific.modal.unavailableMeMessage"/> :
            <RegularText text="specific.modal.unavailableMessage"/>}
        <Modal.Footer>
            <Button label={"specific.modal.unavailableYes"} variant={"primary"}
                    onClick={() => setShow(false)} className={styles.submitButton}/>
        </Modal.Footer>
    </Modal>
};
export {ActionsUnavailableModal};