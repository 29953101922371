import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import Loading from "../../Loading/Loading";
import {Box, Button, DropdownSelect, StandardPage, TextInput, BoolField, NumField} from "../../common";
import styles from "./EditMailerDetails.module.scss";
import {Form, Formik} from "formik";
import axios from "axios";
import {EditConfirmationModal} from "../../common/EditConfirmationModal/EditConfirmationModal";
import SearchableList from "../../common/_SearchableList";
import {locations} from "../../../utils/constants"

const languageConstants = [["English", "en"], ["French", "fr"]]

const getSubTitle = (id, setTitle, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/acc-name", {params: {accountId: id}})
        .then(({data}) => {
            setTitle(data)
        })
        .finally(() => {
            setLoading(false);
        });
};

const getAccountMetaData = (accountId, setAccountMetaData, setLoadingData, setExistingVals, setShowTranslationFields) => {
    setLoadingData(true);
    if (accountId >= 0) {
        axios.post("/api/payreq-accounts/account-info", {accountId})
            .then(({data}) => {
                setAccountMetaData(data);
                setExistingVals(prevState => ({...prevState, ...data}));
                if(data.documentLocationId === locations.ca) {
                    setShowTranslationFields(true)
                }
            })
            .finally(() => {
                setLoadingData(false);
            });
    } else {
        setLoadingData(false);
    }
};

const getTimezones = (setTimezones, setLoadingTimezones) => {
    setLoadingTimezones(true);
        axios.get("/api/payreq-accounts/timezones")
            .then(({data}) => {
                setTimezones(data.map((timezone) => timezone.name));
            })
            .finally(() => {
                setLoadingTimezones(false);
            });
};

const getMailhouses = (setMailhouses, setLoadingMailhouses) => {
    setLoadingMailhouses(true);
        axios.get("/api/payreq-accounts/mailhouses")
            .then(({data}) => {
                data = data.filter((mailhouse) => mailhouse.status !== 1);
                setMailhouses(data.map((mailhouse) => [mailhouse.id, mailhouse.id]).sort());
            })
            .finally(() => {
                setLoadingMailhouses(false);
            });
};

const getAuthHelperImagesTranslation = (id, setAuthFieldTranslations, setTranslationLoading, setExistingVals) => {
    setTranslationLoading(true);
    axios.get("/api/payreq-accounts/account-helper-fields-translations", {params: {accountId: id}})
        .then(({data}) => {
             const renamedObjects =
                 Object.assign({},
                     {"helpImageAccountNumberTranslation" : data[languageConstants[1][1]].helpImageAccountNumber,
                              "helpImageAuthItem1Translation" : data[languageConstants[1][1]].helpImageAuthItem1});
            setAuthFieldTranslations(renamedObjects)
            setExistingVals(prevState => ({...prevState, ...renamedObjects}))
        })
        .finally(() => {
            setTranslationLoading(false);
        })
}

const parseVals = (vals) => {
    for (let val in vals) {
        if (vals[val] === '' || vals[val] === '-') {
            vals[val] = null;
        }
    }
    return vals;
}

const languages = [["English", "en"], ["French", "fr"]]

const MakeEdit = (values, id) => {
    return axios.post("/api/payreq-accounts/mailer-details-edit",
        {
            id: id,
            tagName: values.tagName,
            fastformKey: values.fastformKey,
            maxRegoBulk: parseInt(values.maxRegoBulk),
            maxRegoPending: parseInt(values.maxRegoPending),
            timezone: values.timezone,
            mailhouseSystemId: values.mailhouseSystemId,
            showTerms: values.showTerms,
            mfaRequired: values.mfaRequired,
            logoPath: values.logoPath,
            emailLogoS3: values.emailLogoS3,
            helpImageAccountNumber: values.helpImageAccountNumber,
            helpImageAuthItem1: values.helpImageAuthItem1,
            language: languages[1][1],
            helpImageAccountNumberTranslation: values.helpImageAccountNumberTranslation,
            helpImageAuthItem1Translation: values.helpImageAuthItem1Translation,
            downloadLimit: values.downloadLimit,
        });
}

const intlKeys = {
    id: "accountInfo.accountId",
    tagName: "accountInfo.accountName",
    fastformKey: "accountInfo.fastformKey",
    maxRegoBulk: "accountInfo.maxRegoBulk",
    maxRegoPending: "accountInfo.maxPending",
    timezone: "accountInfo.accountTimezone",
    mailhouseSystemId: "accountInfo.mailhouseId",
    showTerms: "accountInfo.accountShowTerms",
    mfaRequired: "accountInfo.requiresMfa",
    logoPath: "accountInfo.logoUrl",
    emailLogoS3: "accountInfo.emailUrl",
    helpImageAccountNumber: "accountInfo.helpAccNumUrl",
    helpImageAuthItem1: "accountInfo.helpAuthItemUrl",
    helpImageAccountNumberTranslation: "accountInfo.helpAuthItemUrl.translation",
    helpImageAuthItem1Translation: "accountInfo.helpAccNumUrl.translation",
    downloadLimit: "accountInfo.downloadLimit"
}

const EditMailerForm = ({accountMetaData, authFieldTranslations, showTranslationFields, title, setModalVals, setShowConfirmation, id}) => {

    const [timezones, setTimezones] = useState([]);
    const [loadingTimezones, setLoadingTimezones] = useState(true);
    const [loadingMailhouses, setLoadingMailhouses] = useState(true);
    const [mailhouses, setMailhouses] = useState([]);
    const history = useHistory();
    const uploadOpts = [100, 500, 1000, 5000, 10000];
    const pendingOpts = [20, 100, 200, 1000, 2000];

    const validationSchema = Yup.object().shape({
        tagName: Yup.string().required("accounts.customer.edit.warning"),
        timezone: Yup.string().required("accountInfo.timezone.warning"),
    })

    useEffect(() => getTimezones(setTimezones, setLoadingTimezones),
        [id]);

    useEffect(() => getMailhouses(setMailhouses, setLoadingMailhouses),
        [id]);

    if (loadingTimezones || loadingMailhouses) return <Loading/>;

    return (
        <StandardPage header={"accounts.primaryHeader"}  values={{header: title.tagName}}
                      sub={"accountInfo.edit.mailer.sub"} parentPath={"/accounts/" + id}>
            <Box>
                <Formik
                    initialValues={{
                        tagName: accountMetaData.tagName,
                        fastformKey: accountMetaData.fastformKey,
                        maxRegoBulk: accountMetaData.maxRegoBulk,
                        maxRegoPending: accountMetaData.maxRegoPending,
                        timezone: accountMetaData.timezone,
                        mailhouseSystemId: accountMetaData.mailhouseSystemId ? accountMetaData.mailhouseSystemId : '-',
                        showTerms: accountMetaData.showTerms,
                        mfaRequired: accountMetaData.mfaRequired,
                        logoPath: accountMetaData.logoPath,
                        emailLogoS3: accountMetaData.emailLogoS3,
                        helpImageAccountNumber: accountMetaData.helpImageAccountNumber,
                        helpImageAuthItem1: accountMetaData.helpImageAuthItem1,
                        helpImageAccountNumberTranslation: authFieldTranslations.helpImageAccountNumberTranslation,
                        helpImageAuthItem1Translation: authFieldTranslations.helpImageAuthItem1Translation,
                        downloadLimit : accountMetaData.downloadLimit
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting}, event) => {
                        setSubmitting(false)
                    }}
                >
                    {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          errors,
                          touched,
                          isSubmitting,
                          setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            {accountMetaData.systemId !== "delivery" &&
                                <TextInput id="tagName"
                                       label={"accountInfo.accountName"}
                                       defaultValue={accountMetaData.tagName}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       errors={errors.tagName}
                                       touched={touched.tagName}/>}
                            {accountMetaData.systemId === "delivery" &&
                                <TextInput id="fastformKey"
                                       label={"accountInfo.fastformKey"}
                                       defaultValue={accountMetaData.fastformKey}
                                       placeholder={"accountInfo.fastformKey.placeholder"}
                                       onChange={handleChange}/>}

                            <DropdownSelect id="maxRegoBulk" label={"accountInfo.maxRegoBulk"} name={`maxRegoBulk`} options={uploadOpts}
                                            defaultValue={accountMetaData.maxRegoBulk}
                                            onChange={(e) => {
                                                handleChange(e)
                                            }}
                                            value={values.maxRegoBulk} onBlur={handleBlur}
                                            errors={errors.maxRegoBulk} touched={touched.maxRegoBulk}
                                            labelFn={x => x}/>

                            <DropdownSelect id="maxRegoPending" label={"accountInfo.maxPending"} name={`maxRegoPending`} options={pendingOpts}
                                            defaultValue={accountMetaData.maxRegoPending}
                                            onChange={handleChange}
                                            value={values.maxRegoPending} onBlur={handleBlur}
                                            errors={errors.maxRegoPending} touched={touched.maxRegoPending}
                                            labelFn={x => x}/>

                            {!loadingTimezones &&
                                <SearchableList
                                    id="timezone" label={"accountInfo.accountTimezone"} name={`timezone`}
                                    defaultValue={accountMetaData.timezone}
                                    onChange={(e) => {
                                        handleChange(e)
                                    }}
                                    setFieldValue={setFieldValue}
                                    value={values.timezone} onBlur={handleBlur}
                                    errors={errors.timezone} touched={touched.timezone}
                                    list={timezones}
                                    onKeyPress={(e) => {e.key === 'Enter' && e.preventDefault()}}
                                />
                            }

                            {!loadingMailhouses &&
                                <DropdownSelect id="mailhouseSystemId" label={"accountInfo.mailhouseId"} name={`mailhouseSystemId`} options={mailhouses}
                                                defaultValue={(accountMetaData.mailhouseSystemId) ? accountMetaData.mailhouseSystemId : '-'}
                                                placeholder={"accountInfo.mailhouseId"}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}
                                                value={values.mailhouseSystemId} onBlur={handleBlur}
                                                errors={errors.mailhouseSystemId} touched={touched.mailhouseSystemId}
                                                labelFn={x => x}/>}

                            <BoolField id="showTerms"
                                       label={"accountInfo.accountShowTerms"}
                                       defaultValue={accountMetaData.showTerms}
                                       value={values.showTerms}
                                       onChange={handleChange}
                                       name={"showTerms"}
                                        className={styles.boolField}/>

                            <BoolField id="mfaRequired"
                                       label={"accountInfo.requiresMfa"}
                                       defaultValue={accountMetaData.mfaRequired}
                                       value={values.mfaRequired}
                                       onChange={handleChange}
                                       className={styles.boolField}/>

                            <TextInput id="logoPath"
                                       label={"accountInfo.logoUrl"}
                                       defaultValue={accountMetaData.logoPath}
                                       placeholder={"accountInfo.logoPath.placeholder"}
                                       onChange={handleChange}/>

                            <TextInput id="emailLogoS3"
                                       label={"accountInfo.emailUrl"}
                                       defaultValue={accountMetaData.emailLogoS3}
                                       placeholder={"accountInfo.emailLogo.placeholder"}
                                       onChange={handleChange}/>

                            <TextInput id="helpImageAccountNumber"
                                       label={"accountInfo.helpAccNumUrl"}
                                       defaultValue={accountMetaData.helpImageAccountNumber}
                                       placeholder={"accountInfo.helperAccNumUrl.placeholder"}
                                       onChange={handleChange}/>

                            <TextInput id="helpImageAuthItem1"
                                       label={"accountInfo.helpAuthItemUrl"}
                                       defaultValue={accountMetaData.helpImageAuthItem1}
                                       placeholder={"accountInfo.helperAuthItemUrl.placeholder"}
                                       onChange={handleChange}/>
                            {showTranslationFields &&
                                <>
                                    <TextInput id="helpImageAuthItem1Translation"
                                               label={"accountInfo.helpAuthItemUrl.translation"}
                                               defaultValue={authFieldTranslations.helpImageAuthItem1Translation}
                                               placeholder={"accountInfo.helperAuthItemUrl.placeholder.translation"}
                                               onChange={handleChange}/>

                                    <TextInput id="helpImageAccountNumberTranslation"
                                               label={"accountInfo.helpAccNumUrl.translation"}
                                               defaultValue={authFieldTranslations.helpImageAccountNumberTranslation}
                                               placeholder={"accountInfo.helperAccNumUrl.placeholder.translation"}
                                               onChange={handleChange}/>
                                </>
                            }


                            <NumField  id="downloadLimit"
                                       label="accountInfo.downloadLimit"
                                       defaultValue={accountMetaData.downloadLimit}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       errors={errors.downloadLimit}
                                       touched={touched.downloadLimit}/>

                            <Button className={styles.buttons} label={"accountInfo.edit.save"} variant={"primary"} loading={isSubmitting}
                                    type="submit" onClick={() => {
                                        if (!(Object.keys(errors).length)) {
                                            setModalVals({...values});
                                            setShowConfirmation(true);
                                        }

                                }}/>
                            <Button className={styles.buttons} label={"accountInfo.edit.cancel"} variant={"secondary"} onClick={() => history.push("/accounts/" + id )} type="button"/>
                        </Form>
                    )}

                </Formik>

            </Box>
        </StandardPage>
    )
}



const EditMailerDetails = () => {
    const id = parseInt(useParams().id);
    const [title, setTitle] = useState();
    const [loading, setLoading] = useState(true);
    const [accountMetaData, setAccountMetaData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [modalVals, setModalVals] = useState({});
    const [existingVals, setExistingVals] = useState({});
    const [authFieldTranslations, setauthFieldTranslations] = useState([]);
    const [translationLoading, setTranslationLoading] = useState([]);
    const [showTranslationFields, setShowTranslationFields] = useState(false);
    const [lastId, setLastId] = useState(null);

    useEffect(() => getSubTitle(id, setTitle, setLoading),
        [id]);

    useEffect(() => {
            if (id !== lastId) {
                getAccountMetaData(id, setAccountMetaData, setLoadingData, setExistingVals, setShowTranslationFields)
                setLastId(id);
            }
        },
        [id, lastId, setLastId, setAccountMetaData, setLoadingData, setExistingVals, setShowTranslationFields]);

    useEffect(() => {
            if (id !== lastId) {
                getAuthHelperImagesTranslation(id, setauthFieldTranslations, setTranslationLoading, setExistingVals)
                setLastId(id);
            }
        },
        [id, lastId, setLastId, setauthFieldTranslations, setTranslationLoading, setExistingVals]);

    if (loading || loadingData || translationLoading) return <Loading/>;
    return (
        <>
            <EditConfirmationModal newVals={parseVals(modalVals)} existingVals={parseVals(existingVals)} show={showConfirmation} setShow={setShowConfirmation} id={id} editFunc={MakeEdit}
            intlKeys={intlKeys} header={"accounts.customer.edit.confirmation"}/>
            <EditMailerForm accountMetaData={accountMetaData} authFieldTranslations={authFieldTranslations} showTranslationFields={showTranslationFields} title={title} setModalVals={setModalVals} setShowConfirmation={setShowConfirmation} id={id}/>
        </>
    );
};

export default EditMailerDetails;