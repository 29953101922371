import React from "react"
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import {StateProvider, useAppState} from "./state";
import PayreqRouter from "./PayreqRouter";
import GenericAPIErrorModal from "./components/GenericAPIErrorModal";
import Footer from "./components/Footer/Footer";
import {SET_ERROR} from "./state/reducers/errorReducer";
import {useEffect, useState} from "react";
import Loading from "./components/Loading/Loading";
import styles from "./app.modules.scss"
import {IntlProvider} from "react-intl";
import messages_en from "./lang/en.json";
import messages_fr from "./lang/fr.json";

const messages = {
    "en": messages_en,
    "fr": messages_fr
};

const lang = navigator.language;
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getParentLang = lang => lang.length > 2 ? lang.substring(0, 2) : lang;

//this is done for screen readers
// const setRootHTMLLang = () => {
//     const parentLang = getParentLang(lang);
//     document.documentElement.lang = messages[parentLang] ? parentLang : "en"
// };

//only required if deployed to s3 separately
// if (process.env.REACT_APP_API_URL) {
//     axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// }

const errorResponseHandler = (dispatch, error) => {
    const isUsingLocalErrorHandling = error !== undefined && error.config !== undefined && error.config.hasOwnProperty("localErrorHandling") && error.config.localErrorHandling === true;
    if (!isUsingLocalErrorHandling) {
        if (error.response.status === 401) {
            window.location.href = "/";
        } else {
            dispatch({type: SET_ERROR, error: error.response.data && error.response.data.error ? error.response.data.error : {id: null}})
        }
    }
    return Promise.reject(error);
};

const configureApiRequestDefaults = (dispatch, setIsAppConfigComplete) => {
    axios.defaults.headers.common["Cache-Control"] = "no-store";
    axios.interceptors.response.use(
        response => response,
        error => errorResponseHandler(dispatch, error)
    );
    setIsAppConfigComplete(true);
};

const INITIAL_STATE = {
    user: {isAuthenticated: false},
    error: null
}

const ConfigShell = () => {
    const [, dispatch] = useAppState();
    const [isAppConfigComplete, setIsAppConfigComplete] = useState(false);
    useEffect(() => configureApiRequestDefaults(dispatch, setIsAppConfigComplete), [dispatch, setIsAppConfigComplete]);

    if (!isAppConfigComplete) return <Loading/>;

    return (
        <React.Fragment>
            <PayreqRouter className={styles.app}/>
            <Footer/>
            <GenericAPIErrorModal />
        </React.Fragment>
    )
};

function App() {
    return (
        <IntlProvider locale={lang} timeZone={timezone} messages={messages[lang] || messages[getParentLang(lang)] || messages["en"]}>
        <StateProvider initialState={INITIAL_STATE}>
            <ConfigShell/>
        </StateProvider>
        </IntlProvider>
    );
}

export default App;
