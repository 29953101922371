import React from "react";
import RegularText from "../common/_RegularText";
import {Button, Modal} from "../common";
import styles from "../common/_Modal/_Modal.module.scss";

const WithdrawRequestModal = ({onYes, show, setShow}) => {
    return <Modal header={"requestDetails.modal.withdraw.header"} show={show} setShow={setShow} handleClose={() => setShow(false)}>
        <RegularText text="requestDetails.modal.withdraw.message"/>
        <Modal.Footer>
            <Button label={"requestDetails.modal.withdraw.yesButton"} variant={"danger"}
                    onClick={() => onYes()} className={styles.submitButton}/>
        </Modal.Footer>
    </Modal>
};
export {WithdrawRequestModal};