import axios from "axios";
import React, {useEffect, useState} from "react";
import {PrimaryHeading} from "../common";
import {useParams} from "react-router";
import InfiniteList from "../InfiniteList/InfiniteList";
import Loading from "../Loading/Loading";

const R = require('ramda');
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function convertTZ(date, tzString) {
    return new Date(date).toLocaleString("en-US", {timeZone: tzString});
}

const getUserLoginEvents = (id, pageNumber, setLoginEvents, setEventsMeta, setLoading) => {
    setLoading(true);
    axios.post("/api/payreq-users/user-events", {id: id, pageNumber: pageNumber})
        .then(({data}) => {
            setLoginEvents(R.groupWith((a,b) => convertTZ(a.eventTime, timezone).split(",")[0] === convertTZ(b.eventTime, timezone).split(",")[0], data.events));
            setEventsMeta(data.meta);
            setLoading(false);
        })
};

const getUserMetaData = (id, setUserMetaData, setLoading) => {
    setLoading(true);
    if (id['id'] >= 0) {
        axios.post("/api/payreq-users/user-info", id)
            .then(({data}) => {
                setUserMetaData(data)
            })
            .finally(() => {
                setLoading(false)
            });
    } else {
        setLoading(false);
    }
};

const InvalidUser = () => (
    <div>
        <PrimaryHeading text="specific.invalidUser" sub="specific.primarySubHeader"/>
    </div>
);

const UserEvents = () => {

    const [userMetaData, setUserMetaData] = useState([]);
    const [loginEvents, setLoginEvents] = useState([]);
    const [eventsMeta, setEventsMeta] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingEvents, setLoadingEvents] = useState(true);
    const id = parseInt(useParams().id);

    useEffect(() => getUserMetaData({id: id}, setUserMetaData, setLoading),
        [id]);

    useEffect(() => getUserLoginEvents(id, pageNumber, setLoginEvents, setEventsMeta, setLoadingEvents),
        [id, pageNumber]);

    if (loading) return <Loading/>;

    if (!userMetaData || userMetaData.length === 0 || userMetaData['status'] === 2) return <InvalidUser/>
    return(
        <React.Fragment>
            <div>
                <PrimaryHeading text="specific.primaryHeader" values={{header: userMetaData['name']}} sub="specific.events.subHeader"/>
            </div>
            <InfiniteList listData={loginEvents} listMeta={eventsMeta} page={pageNumber} setPage={setPageNumber} loading={loadingEvents}/>
        </React.Fragment>
    )
}

export default UserEvents;