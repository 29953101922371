import Form from 'react-bootstrap/Form'
import React from "react";

const _Check = ({id, options, setFieldValue}) => {
    return (
        <Form.Group controlId={id}>
            {options.map((option, index) => (
                <Form.Check
                    key={index}
                    inline
                    label={option.label}
                    name={option.val}
                    type={"checkbox"}
                    id={`inline-checkbox-${index}`}
                    onChange={e => {
                        setFieldValue(option.val, e.target.checked)
                    }}
                    defaultChecked={option.defaultChecked}
                    disabled={option.disabled}
                />
            ))}
        </Form.Group>
    );
};

export default _Check;