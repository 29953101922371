import {Carousel} from "react-bootstrap";
import styles from "./_Carousel.module.scss";
import React from "react";

const _Carousel = ({items, setCurrent}) => {

    return (
            <span className={styles.carousel}>
                <Carousel controls={true} fade={false} wrap={true} slide={false} interval={null}
                          onSlide={(slide) => setCurrent(slide)}
                          prevIcon={<span className={`material-icons-outlined ${styles.navControl}`}>chevron_left</span>}
                          nextIcon={<span className={`material-icons-outlined ${styles.navControl}`}>chevron_right</span>}>
                    {items.map((item, index) =>
                        <Carousel.Item key={index}>
                            <span className={`material-icons-outlined ${styles.icon}`} >{item}</span>
                        </Carousel.Item>
                    )}
                </Carousel>
            </span>

    );
};

export default _Carousel;