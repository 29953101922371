import React from "react";
import {Button, Modal, RegularText} from "../common";
import styles from "../common/_Modal/_Modal.module.scss";

const ActionsUnavailableModal = ({accountJoined, show, setShow}) => {
    return <Modal header={"accounts.modal.disabledHeader"} show={show} setShow={setShow} handleClose={() => setShow(false)}>
        {(!accountJoined) ?
            <RegularText text="accounts.modal.disabledMessageJoin"/> :
            <RegularText text="accounts.modal.disabledMessageLeave"/>}
        <Modal.Footer>
            <Button label={"accounts.modal.disabledButtonYes"} variant={"primary"}
                    onClick={() => setShow(false)} className={styles.submitButton}/>
        </Modal.Footer>
    </Modal>
};
export {ActionsUnavailableModal};