import axios from "axios";
import React, {useEffect, useState} from "react";
import {SearchBar} from "../SearchBar/SearchBar";
import {Button, PrimaryHeading, StandardText, Table} from "../common";
import {useHistory} from "react-router-dom";
import {useIntl} from 'react-intl';
import {IntegratorStatusBadge} from "../Helpers/Helpers";
import {readableDateTime} from "../Helpers/DateTimeHelpers";
import styles from "./Integrators.module.scss";
import ErrorMessage from "../ErrorMessage";
import {getDocLocationLabel} from "./IntegratorUtils";

const getIntegrators = (searchTerm, pageNumber, setIntegrators, setIntegratorMeta, setLoading, searched) => {
    setLoading(true);
    if (searched) {
        axios.get("/api/integrators/active", {params: {search: searchTerm, pageNumber: pageNumber}})
            .then(({data}) => {
                setIntegrators(data.integrators);
                setIntegratorMeta(data.meta);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        setLoading(false);
    }
};

const columnHeaders = [
    {
        Header: <StandardText text="integratorDetail.id"/>,
        accessor: 'id',
    },
    {
        Header: <StandardText text="integratorDetail.description"/>,
        accessor: 'description',
    },
    {
        Header: <StandardText text="integratorDetail.dateActivated"/>,
        accessor: 'activationTime',
        Cell: (row) => readableDateTime(row['dateActivated']),
    },
    {
        Header: <StandardText text="integratorDetail.status"/>,
        accessor: 'status',
        Cell: (row) => <IntegratorStatusBadge status={row['status']}/> // see if works, might change to function call
    },
    {
        Header: <StandardText text="integratorDetail.documentLocation"/>,
        accessor: 'documentLocation',
        Cell: (row, _col, intl) => getDocLocationLabel(row['documentLocationId'], intl),
    },
];

const IntegratorsTable = ({integrators, integratorMeta, pageNumber, setPageNumber, searched, history, loading, intl}) => {

    const invalid = !integrators || !integrators.length;

    if (invalid) return (searched) ?
        <ErrorMessage><StandardText text="integrators.search.empty"/></ErrorMessage> :
        <b><StandardText text="integrators.search.start"/></b>;

    return (
        <Table columnHeaders={columnHeaders} tableData={integrators} tableMeta={integratorMeta} pageNumber={pageNumber}
               setPageNumber={setPageNumber} handleRowClick={(data) => history.push('/integrators/' + data[0])}
               targetColumns={['id']} loading={loading} intl={intl}/>
    );
};

const Integrators = () => {
    const [integrators, setIntegrators] = useState([]);
    const [integratorMeta, setIntegratorMeta] = useState([]);
    const [loadingIntegrators, setLoadingIntegrators] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [searched, setSearched] = useState(false);

    useEffect(() => getIntegrators(searchTerm, pageNumber, setIntegrators, setIntegratorMeta,
        setLoadingIntegrators, searched), [searchTerm, searched, pageNumber]);

    const history = useHistory();
    const intl = useIntl();

    return (
        <React.Fragment>
            <PrimaryHeading text={"integrators.header"}/>
            <Button className={styles.addButton} label={"integrators.add"} icon={"add"} variant={"primary"} onClick={() => history.push('/integrators/add')}/>
            <div className={styles.integratorBox}>
                <SearchBar setPageNumber={setPageNumber} searchTerm={searchTerm} setSearchTerm={setSearchTerm}
                   setSearched={setSearched}/>
                <IntegratorsTable integrators={integrators} integratorMeta={integratorMeta} pageNumber={pageNumber}
                                  setPageNumber={setPageNumber} searched={searched} history={history}
                                  loading={loadingIntegrators} intl={intl}/>
            </div>
        </React.Fragment>
    )
};

export default Integrators;