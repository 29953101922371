import axios from "axios";
import React, {useEffect, useState} from "react";
import {Alert, PrimaryHeading, SecondaryHeading, StandardText} from "../common";
import {useParams} from "react-router";
import InfoBox from "../InfoBox/InfoBox";
import ButtonCluster from "../ButtonCluster/ButtonCluster";
import {useHistory} from "react-router-dom";
import styles from "./RequestDetails.module.scss";
import {readableDateTime} from "../Helpers/DateTimeHelpers";
import {RequestAction, RequestStatus, UserIdLink} from "../Helpers/ApprovalRequestHelpers";
import {ApproveRequestModal} from "./ApproveRequestModal"
import {DenyRequestModal} from "./DenyRequestModal"
import Loading from "../Loading/Loading";
import {WithdrawRequestModal} from "./WithdrawRequestModal";

const getRequestData = (requestId, setRequestData, setActionDetails, setStatus, setLoading) => {
    setLoading(true);
    if (requestId >= 0) {
        axios.post("/api/approvals/approval-request", {requestId: requestId})
            .then(({data}) => {
                setRequestData(data);
                setStatus(data.status);
                setActionDetails(data.actionDetails);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        setLoading(false);
    }
};

const updateRequest = (id, status, setAlert, setStatus) => {

    const statusAlerts = {
        approved: "requestDetails.approvedRequest",
        denied: "requestDetails.deniedRequest",
        withdrawn: "requestDetails.withdrewRequest"
    }

    return axios.post("/api/approvals/update-request", {requestId: id, status: status})
        .then(({data}) => {
            setStatus(status);
            setTimeout(() => {
                setAlert({});
            }, 6000);
            setAlert((data.success) ?
                {variant: "success", text: statusAlerts[status]} :
                {variant: "warning", text: "requestDetails.updateError"});
        })
};

const InvalidRequest = () => (
    <div>
        <PrimaryHeading text="Invalid Request" sub="accounts.primarySubHeader"/>
    </div>
);

const handleBack = (history) => {
    history.goBack();
}

const RequestInfo = ({requestData, actionDetails, status, setStatus, handleBack, history, loading, setAlert}) => {

    const [approveModalShow, setApproveModalShow] = useState(false);
    const [denyModalShow, setDenyModalShow] = useState(false);
    const [withdrawModalShow, setWithdrawModalShow] = useState(false);

    if (!requestData || requestData.length === 0) return <InvalidRequest/>

    const pending = requestData.status === 'pending-approval';
    const currentUserRequest = requestData.currentUserRequest;

    return (
        <div>
            <div>
                <PrimaryHeading text="requestDetails.header" values={{id: requestData.id}} sub="requestDetails.subHeader"/>
            </div>
            <ButtonCluster buttons={[
                {icon: "person_search", label: "requestDetails.goToUser", path: `/users/${actionDetails.id}`, variant: "primary", visible: actionDetails.id},
                {icon: "thumb_up", label: "requestDetails.approve", onClick: () => setApproveModalShow(show => !show), variant: "success", visible: !currentUserRequest, disabled: !pending},
                {icon: "thumb_down", label: "requestDetails.deny", onClick: () => setDenyModalShow(show => !show), variant: "danger", visible: !currentUserRequest, disabled: !pending},
                {icon: "unpublished", label: "requestDetails.withdraw", onClick: () => setWithdrawModalShow(show => !show), variant: "danger", visible: currentUserRequest, disabled: !pending},
            ]}/>
            <InfoBox rowsAlwaysShown={2} loading={loading}
                     rows={[
                         [{label: "requestDetails.action", value: <RequestAction action={requestData.action}/>},
                             {label: "requestDetails.status", value: <RequestStatus status={status}/>}],

                         [{label: "requestDetails.requestId", value: requestData.id},
                             {label: "requestDetails.reason", value: requestData.reason}],

                         [{label: "requestDetails.requestedBy", value: requestData.requesterName},
                             {label: "requestDetails.requestedOn", value: readableDateTime(requestData.requestedOn)}],
                         (!pending) ?
                         [{label: "requestDetails.updatedBy", value: requestData.updaterName},
                             {label: "requestDetails.updatedOn", value: readableDateTime(requestData.updatedOn)}] :
                         [],
                     ]}/>
            <ApproveRequestModal show={approveModalShow} setShow={setApproveModalShow}
                                 onYes={() => {
                                     return updateRequest(requestData.id, "approved", setAlert, setStatus)
                                 }}/>
            <DenyRequestModal show={denyModalShow} setShow={setDenyModalShow}
                              onYes={() => {
                                  return updateRequest(requestData.id, "denied", setAlert, setStatus)
                              }}/>
            <WithdrawRequestModal show={withdrawModalShow} setShow={setWithdrawModalShow}
                              onYes={() => {
                                  return updateRequest(requestData.id, "withdrawn", setAlert, setStatus)
                              }}/>
        </div>
    );
};


const ActionDetails = ({actionDetails, loading, action}) => {
    if (!actionDetails || actionDetails.length === 0) return null;

    const rowsToUse = (action === "deleting-user") ? [[{label: "requestDetails.action.id", value: UserIdLink(actionDetails.id, styles.userId)}],
        [{label: "requestDetails.action.accounts", value: actionDetails.accounts.join(", ")}],
        [{label: "requestDetails.action.ticketNumber", value: actionDetails.ticketNumber}]] :

        [[{label: "requestDetails.action.id", value: UserIdLink(actionDetails.id, styles.userId)}],
            (actionDetails.mfaRequired !== undefined) ? [{label: "requestDetails.action.mfaRequired", value: actionDetails.mfaRequired}] : null,
            (actionDetails.mfaActivated !== undefined) ? [{label: "requestDetails.action.mfaActivated", value: actionDetails.mfaActivated}] : null,
            (actionDetails.mfaActivatedEmail !== undefined) ? [{label: "requestDetails.action.mfaActivatedEmail", value: actionDetails.mfaActivatedEmail}] : null,
        [{label: "requestDetails.action.ticketNumber", value: actionDetails.ticketNumber}]]

    return (
        <div className={styles.actionDetails}>
            <SecondaryHeading className={styles.actionDetailsHeader} text={"requestDetails.actionDetails"}/>
            <InfoBox rowsAlwaysShown={3} loading={loading}
                     rows={rowsToUse.filter(val => val)}/>
        </div>
    );
};


const RequestDetails = () => {
    const [requestData, setRequestData] = useState([]);
    const [actionDetails, setActionDetails] = useState([])
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({});
    const [status, setStatus] = useState("");
    const id = parseInt(useParams().id);

    useEffect(() => getRequestData(id, setRequestData, setActionDetails, setStatus, setLoading),
        [id, status]);

    const history = useHistory();

    if (loading) return <Loading/>;

    return(
        <React.Fragment>
                {alert.text && <Alert variant={alert.variant}><StandardText text={alert.text}/></Alert>}
                <RequestInfo requestData={requestData} actionDetails={actionDetails} status={status}
                             setStatus={setStatus} handleBack={handleBack} history={history} loading={loading}
                             setAlert={setAlert}/>
                <ActionDetails actionDetails={actionDetails} loading={loading} action={requestData.action}/>
        </React.Fragment>
    )
}

export default RequestDetails;