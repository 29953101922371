import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import styles from "./EditChannels.module.scss";
import {
    BoolField,
    Box,
    Button, DropdownSelect,
    PrimaryHeading, RegularText, SecondaryHeading,
    StandardPage,
    TextInput
} from "../../../../../common";
import axios from "axios";
import Loading from "../../../../../Loading/Loading";
import {Form, Formik} from "formik";
import {useHistory} from "react-router-dom";
import {Tab, Tabs} from "react-bootstrap";
import {useIntl} from "react-intl";
import {EditConfirmationModal} from "../../../../../common/EditConfirmationModal/EditConfirmationModal";

const dropdownLanguages = [["English", "en"], ["French", "fr"]]

const getSubTitle = (id, setTitle, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/acc-name", {params: {accountId: id}})
        .then(({data}) => {
            setTitle(data)
        })
        .finally(() => {
            setLoading(false);
        });
};

const getChannelFields = (id, setAuthFields, setLoading, setDropdownContacts, setExistingVals) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/account-auth-fields", {params: {accountId: id}})
        .then(({data}) => {
            setAuthFields(data)
            setExistingVals(data)
            if (data.length) {
                setDropdownContacts([...Array(9).keys()]
                    .slice(1)
                    .filter((key) => (data[0]["contactId" + key + "Field"]))
                    .map(key => ["contact-id-" + key + " (" + data[0]["contactId" + key + "Field"] + ")", "contact-id-" + key])
            )}

        })
        .finally(() => {
            setLoading(false);
        });
};

const getChannelFieldsFrench = (id, setTranslationLoading, setShowTranslationFields, setTranslations) => {
    setTranslationLoading(true);
    axios.get("/api/payreq-accounts/account-auth-fields-translations", {params: {accountId: id}})
        .then(({data}) => {
            setTranslations(data)
        }).finally(() => {
            setTranslationLoading(false);
        })
}

const makeEdit = (values, id, authField) => {
    const channel = authField.channelPartnerSystemId
    return axios.post("/api/payreq-accounts/edit-auth-fields",
        {
            id: id,
            channel: channel,
            channelId: parseNull(values.registrationContactIdField),
            data: values
        })
}

const updateFormValuesTranslation = (event, setFieldValue, translations) => {
    const index = event.target.value;
    setFieldValue("selectLanguage", parseNull(index));
    setFieldValue("authItem1Field", parseNull(translations[index].authItem1Field));
    setFieldValue("authItem2Field", parseNull(translations[index].authItem2Field));
    setFieldValue("authItem3Field", parseNull(translations[index].authItem3Field));
    setFieldValue("authItem4Field", parseNull(translations[index].authItem4Field));
    setFieldValue("authItem1Help", parseNull(translations[index].authItem1Help));
    setFieldValue("authItem2Help", parseNull(translations[index].authItem2Help));
    setFieldValue("authItem3Help", parseNull(translations[index].authItem3Help));
    setFieldValue("authItem4Help", parseNull(translations[index].authItem4Help));
    setFieldValue("registrationContactIdField", parseNull(translations[index].registrationContactIdField));
    setFieldValue("registrationContactIdHelp", parseNull(translations[index].registrationContactIdHelp));
}

const updateFormValuesChannel = (index, setFieldValue, setSelected, authFields, dropdownContacts, values) => {
    setSelected(index)
    setFieldValue("authItem1Field", (findVal(authFields[index].authItem1Field, authFields, dropdownContacts, authFields[index].useAuthItem1)));
    setFieldValue("authItem2Field", (findVal(authFields[index].authItem2Field, authFields, dropdownContacts, authFields[index].useAuthItem2)));
    setFieldValue("authItem3Field", (findVal(authFields[index].authItem3Field, authFields, dropdownContacts, authFields[index].useAuthItem3)));
    setFieldValue("authItem4Field", (findVal(authFields[index].authItem4Field, authFields, dropdownContacts, authFields[index].useAuthItem4)));
    setFieldValue("useAuthItem1", (authFields[index].useAuthItem1));
    setFieldValue("useAuthItem2", (authFields[index].useAuthItem2));
    setFieldValue("useAuthItem3", (authFields[index].useAuthItem3));
    setFieldValue("useAuthItem4", (authFields[index].useAuthItem4));
    setFieldValue("authItem1Help", (authFields[index].authItem1Help));
    setFieldValue("authItem2Help", (authFields[index].authItem2Help));
    setFieldValue("authItem3Help", (authFields[index].authItem3Help));
    setFieldValue("authItem4Help", (authFields[index].authItem4Help));
    setFieldValue("registrationContactIdField", parseNull(authFields[index].registrationContactIdField));
    setFieldValue("registrationContactIdHelp", parseNull(authFields[index].registrationContactIdHelp));
    setFieldValue("showInMybills", authFields[index].showInMybills);
    values.selected = index;
    setFieldValue("selected", index)}

const alterValuesForTranslation = (values) => {
    delete values.useAuthItem1
    delete values.useAuthItem2
    delete values.useAuthItem3
    delete values.useAuthItem4
    delete values.showInMybills
}

const parseNull = (value) => {
    return value ? value : null
}

const nullToString = (value) => {
    return (value === null || value === undefined) ? "" : value
}

const findVal = (val, authFields, dropdownContacts, mandatory) => {
    if (mandatory) {
        for (const element of dropdownContacts) {
            if (element.includes(val)) {
                return (element[1]);
            }}
        return dropdownContacts[0][1]
    }
    return val || ""
}

const intlKeys = {
    "fr": {authItem1Field :  "accounts.advanced.edit.auth1.french",
           authItem1Help : "accounts.advanced.edit.auth1.help.french",
           authItem2Field :  "accounts.advanced.edit.auth2.french",
           authItem2Help : "accounts.advanced.edit.auth2.help.french",
           authItem3Field :  "accounts.advanced.edit.auth3.french",
           authItem3Help : "accounts.advanced.edit.auth3.help.french",
           authItem4Field :  "accounts.advanced.edit.auth4.french",
           authItem4Help : "accounts.advanced.edit.auth4.help.french",
           registrationContactIdHelp : "accounts.advanced.edit.channelid.help.french",
           registrationContactIdField: "accounts.advanced.edit.channelid.french"},
    "en": {authItem1Field :  "accounts.advanced.edit.auth1",
           authItem1Help : "accounts.advanced.edit.auth1.help",
           authItem2Field : "accounts.advanced.edit.auth2",
           authItem2Help : "accounts.advanced.edit.auth2.help",
           authItem3Field : "accounts.advanced.edit.auth3",
           authItem3Help : "accounts.advanced.edit.auth3.help",
           authItem4Field : "accounts.advanced.edit.auth4",
           authItem4Help : "accounts.advanced.edit.auth4.help",
           registrationContactIdField : "accounts.advanced.edit.channelid",
           registrationContactIdHelp : "accounts.advanced.edit.channelid.help",
           useAuthItem1 : "accounts.advanced.edit.auth1.enable",
           useAuthItem2 : "accounts.advanced.edit.auth2.enable",
           useAuthItem3 : "accounts.advanced.edit.auth3.enable",
           useAuthItem4 : "accounts.advanced.edit.auth4.enable",
           showInMybills : "accounts.active.mybills.switch"}}

const parseVals = (vals) => {
    for (let val in vals) {
        if (vals[val] === '' || vals[val] === '-') {
            vals[val] = null;
        }
    }
    return vals;
}

const AuthField = ({index, values, handleChange, handleBlur, dropdownContacts, selected, authFields, setFieldValue, showTranslationFields}) => {
    return (
            <div id={'auth'+index} className={styles.authBlock}>
                <div className={styles.authMandatoryPair}>
                    {(values["useAuthItem"+index]) && !showTranslationFields ?
                        <DropdownSelect id={"authItem"+index+"Field"}
                                        className={styles.authField}
                                        label={"accounts.advanced.edit.auth" + index} name={`authItem`+index+`Field`} options={dropdownContacts}
                                        value={values["authItem"+index+"Field"]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        labelFn={x => x}/>
                        : <TextInput id={"authItem"+index+"Field"}
                                     className={styles.authField}
                                     label={"accounts.advanced.edit.auth" + index}
                                     placeholder={"accounts.advanced.edit.auth"+index+".placeholder"}
                                     onChange={handleChange}
                                     value={values["authItem"+index+"Field"]}/>
                    }
                    {!showTranslationFields &&
                        <BoolField id={"useAuthItem" + index}
                                   className={styles.boolField}
                                   label="accounts.advanced.edit.mandatory"
                                   checked={(values["useAuthItem"+index])}
                                   value={values["useAuthItem" + index]}
                                   onChange={(e) => {
                                       setFieldValue("authItem"+index+"Field", findVal(authFields[selected]["authItem"+index+"Field"],
                                           authFields, dropdownContacts, !values["useAuthItem"+index]));
                                       handleChange(e)

                                   }}
                                   name={"useAuthItem" + index}/>}
                </div>

                <TextInput id={"authItem"+index+"Help"}
                       className={styles.helpField}
                       label={"accounts.advanced.edit.auth"+index+".help"}
                       placeholder={"accounts.advanced.edit.auth"+index+".help.placeholder"}
                       onChange={handleChange}
                       value={values["authItem"+index+"Help"]}/>
            </div>
    )
}


const EditChannels = () => {

    const id = parseInt(useParams().id);
    const [title, setTitle] = useState();
    const [loading, setLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(true)
    const [authFields, setAuthFields] = useState([]);
    const [selected, setSelected] = useState(0);
    const [dropdownContacts, setDropdownContacts] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [existingVals, setExistingVals] = useState({});
    const [modalVals, setModalVals] = useState({});
    const [translationLoading, setTranslationLoading] = useState(true);
    const [showTranslationFields, setShowTranslationFields] = useState(false);
    const history = useHistory();
    const intl = useIntl();
    const [lastId, setLastId] = useState(null);
    const [translations, setTranslations] = useState([]);

    useEffect(() => getSubTitle(id, setTitle, setLoading),
        [id]);

    useEffect(() => getChannelFields(id, setAuthFields, setDataLoading, setDropdownContacts, setExistingVals),
        [id]);

    useEffect(() => {
            if (id !== lastId) {
                getChannelFieldsFrench(id, setTranslationLoading, setShowTranslationFields, setTranslations);
                setLastId(id);
            }
        },
        [id, lastId, setLastId, setTranslationLoading, setShowTranslationFields]);

    if (loading || dataLoading || translationLoading) return <Loading/>;

    return (authFields.length) ?
        <>
        <EditConfirmationModal newVals={parseVals(modalVals)} existingVals={parseVals(existingVals[selected])} show={showConfirmation} setShow={setShowConfirmation} id={id} editFunc={makeEdit}
                               intlKeys={intlKeys[modalVals.selectLanguage]} additionalFields={authFields[selected]} header={showTranslationFields ? "accounts.active."+ dropdownLanguages[1][1].toString() : "accounts.active."+ authFields[selected].channelPartnerSystemId}/>
        <StandardPage header="accounts.primaryHeader"  values={{header: title.tagName}}
                      sub="accounts.active.edit.sub" parentPath={"/accounts/" + id + "/config-lists"}>
            <Box>
                <Formik
                    initialValues={{
                        selected: 0,
                        selectLanguage: dropdownLanguages[0][1],
                        registrationContactIdField: authFields[selected].registrationContactIdField,
                        registrationContactIdHelp: authFields[selected].registrationContactIdHelp,
                        authItem1Field : nullToString(findVal(authFields[selected].authItem1Field, authFields, dropdownContacts, authFields[selected].useAuthItem1)),
                        authItem2Field : nullToString(findVal(authFields[selected].authItem2Field, authFields, dropdownContacts, authFields[selected].useAuthItem2)),
                        authItem3Field : nullToString(findVal(authFields[selected].authItem3Field, authFields, dropdownContacts, authFields[selected].useAuthItem3)),
                        authItem4Field : nullToString(findVal(authFields[selected].authItem4Field, authFields, dropdownContacts, authFields[selected].useAuthItem4)),
                        useAuthItem1 : authFields[selected].useAuthItem1,
                        useAuthItem2 : authFields[selected].useAuthItem2,
                        useAuthItem3 : authFields[selected].useAuthItem3,
                        useAuthItem4 : authFields[selected].useAuthItem4,
                        authItem1Help : authFields[selected].authItem1Help,
                        authItem2Help : authFields[selected].authItem2Help,
                        authItem3Help : authFields[selected].authItem3Help,
                        authItem4Help : authFields[selected].authItem4Help,
                        showInMybills: authFields[selected].showInMybills
                    }}
                    onSubmit={(values, {setSubmitting}, event) => {
                        setSubmitting(false)
                    }}
                >
                    {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          errors,
                          touched,
                          isSubmitting,
                          setFieldValue,
                          setValues
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className={styles.headerWarning}>
                                <PrimaryHeading text="accounts.active.fields.header"/>
                                <RegularText className={styles.warning} text="accounts.advanced.edit.notice"/>
                            </div>
                            <DropdownSelect id="selectLanguage"
                                            className={styles.loneAuthField}
                                            label="accounts.selectLanguage"
                                            name="selectLanguage"
                                            options={dropdownLanguages}
                                            value={values.selectLanguage}
                                            onChange={(e) => {

                                                if (e.target.value === dropdownLanguages[1][1]){
                                                    setSelected(0);
                                                    setExistingVals({0: {...translations[e.target.value], selectLanguage: e.target.value}})
                                                    alterValuesForTranslation(values, setFieldValue)
                                                    updateFormValuesTranslation(e, setFieldValue, translations)
                                                    setShowTranslationFields(true)
                                                } else {
                                                    setShowTranslationFields(false)
                                                    updateFormValuesChannel(0, setFieldValue, setSelected, authFields, dropdownContacts, values)
                                                    setExistingVals({0: authFields[0]})
                                                }
                                                handleChange(e)
                                            }}
                                            onBlur={handleBlur}
                                            errors={errors.selectLanguage}
                                            touched={touched.selectLanguage}
                                            labelFn={x => x}/>

                            <Tabs
                                className={styles.tabGroup}
                                defaultActiveKey={0}
                                id="channelTabs"
                                onSelect={(index) => {
                                    updateFormValuesChannel(index, setFieldValue, setSelected, authFields, dropdownContacts, values)
                                    setExistingVals(authFields)
                                    setFieldValue("selectLanguage", "en")
                                    setShowTranslationFields(false)}}>

                                {authFields.map((element, index) => (
                                    <Tab tabClassName={styles.tab} eventKey={index} key={index} title={intl.formatMessage({id: "accounts.active." + element["channelPartnerSystemId"]})}>
                                        {(parseInt(values.selected) === index) && <div className={styles.fields} data-cy={'tab'+ element["channelPartnerSystemId"]}>
                                            <SecondaryHeading className={styles.channelHeader} text={showTranslationFields ? "accounts.active."+ dropdownLanguages[1][1].toString() : "accounts.active."+ authFields[selected].channelPartnerSystemId}/>
                                            {authFields[selected] &&
                                                <div className={styles.editFields}>
                                                    <div className={styles.authBlock}>
                                                        {!showTranslationFields &&
                                                            <DropdownSelect id="registrationContactIdField"
                                                                         className={styles.loneAuthField}
                                                                         label="accounts.advanced.edit.channelid"
                                                                         name="registrationContactIdField"
                                                                         options={dropdownContacts}
                                                                         value={values.registrationContactIdField}
                                                                         onChange={handleChange}
                                                                         onBlur={handleBlur}
                                                                         errors={errors.maxRegoBulk}
                                                                         touched={touched.maxRegoBulk}
                                                                         labelFn={x => x}/>}
                                                        {showTranslationFields &&
                                                            <TextInput id="registrationContactIdField"
                                                                       className={styles.helpField}
                                                                       label="accounts.advanced.edit.channelid"
                                                                       placeholder="accounts.advanced.edit.channelid.help.placeholder"
                                                                       onChange={handleChange}
                                                                       value={values.registrationContactIdField}/>}

                                                        <TextInput id="registrationContactIdHelp"
                                                                   className={styles.helpField}
                                                                   label="accounts.advanced.edit.channelid.help"
                                                                   placeholder="accounts.advanced.edit.channelid.help.placeholder"
                                                                   onChange={handleChange}
                                                                   value={values.registrationContactIdHelp}/>
                                                    </div>

                                                    {[...Array(5).keys()].slice(1).map((index) => {
                                                        return <AuthField index={index} authFields={authFields} setFieldValue={setFieldValue} values={values}
                                                                   dropdownContacts={dropdownContacts} handleChange={handleChange}
                                                                   handleBlur={handleBlur} selected={values.selected} key={index} showTranslationFields={showTranslationFields}/>
                                                    })}
                                                    {!showTranslationFields &&
                                                    <BoolField
                                                        id="showInMybills"
                                                        className={styles.loneBoolField}
                                                        label="accounts.active.mybills.switch"
                                                        checked={(values.showInMybills)}
                                                        value={values.showInMybills}
                                                        onChange={(e) => {
                                                            setFieldValue("showInMybills", findVal(authFields[selected].showInMybills,
                                                                authFields, dropdownContacts, !values.showInMybills));
                                                            handleChange(e)
                                                        }}
                                                        name="showInMybills"/>}
                                                </div>
                                            }
                                        </div>}
                                    </Tab>
                                ))}
                            </Tabs>
                            <Button className={styles.buttons} label="accountInfo.edit.save" variant="primary" loading={isSubmitting}
                                    type="submit" onClick={() => {
                                if (!(Object.keys(errors).length)) {
                                    setModalVals(() => {
                                        let obj = {...values}
                                        delete obj.selected
                                        return obj})
                                    setShowConfirmation(true);
                                }}}/>
                            <Button className={styles.buttons} label="accountInfo.edit.cancel" variant="secondary" onClick={() => history.push("/accounts/" + id + "/config-lists")} type="button"/>
                        </Form>
                    )}
                </Formik>
            </Box>
        </StandardPage>
        </>
        :
        <StandardPage header="accounts.primaryHeader"  values={{header: title.tagName}}
                      sub="accounts.active.edit.sub" parentPath={"/accounts/" + id + "/config-lists"}>
            <Box>
                <SecondaryHeading className={styles.channelHeader} text="accounts.advanced.edit.empty"/>
                <Button className={styles.buttons} label="accountInfo.edit.cancel" variant="secondary" onClick={() => history.push("/accounts/" + id + "/config-lists")} type="button"/>
            </Box>
        </StandardPage>
}

export default EditChannels;