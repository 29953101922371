import React from "react";
import styles from "./_IconText.module.scss";
import {StandardText} from "../index";

const _IconText = ({text, values, icon, className, style, aria, children}) => (
    <div className={styles.group + " " + className} style={style}>
        <span className="material-icons-outlined" aria-label={aria}>{icon}</span>
        <div className={styles.text}>
            {<StandardText text={text} values={values}>{children}</StandardText>}
        </div>
    </div>
);

export default _IconText;