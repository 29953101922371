import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {SecondaryHeading, StandardPage, Box, Button, TextInput, BoolField, NumField} from "../../../../../common";
import axios from "axios";
import Loading from "../../../../../Loading/Loading";
import styles from "./AddChannel.module.scss";
import {Form, Formik} from "formik";
import RegularText from "../../../../../common/_RegularText";
import {useIntl} from "react-intl";

const getSubTitle = (id, setTitle, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/acc-name", {params: {accountId: id}})
        .then(({data}) => {
            setTitle(data)
        })
        .finally(() => {
            setLoading(false);
        });
};

const getAvailableChannels = (id, setAvailableChannels, setChannelsLoading, setSelectedMap) => {
    setChannelsLoading(true);
    axios.get("/api/payreq-accounts/available-channels", {params: {accountId: id}})
        .then(({data}) => {
            let map = new Map();
            data.map((channel) => map.set(channel, false))
            setSelectedMap(map)
            setAvailableChannels(data)
        })
        .finally(() => {
            setChannelsLoading(false);
        });
}

const makeEdit = (values, history, selectedMap, id) => {
    let channels = []
    selectedMap.forEach((value, key) => {if (value) channels.push(key)})
    return axios.post("/api/payreq-accounts/channels",
        {
            accountId : id,
            channels : channels,
            extId : values.extId,
            extName : values.extName,
            checkCred : values.checkCred,
            accNumberLength : nullChecker(values.accNumberLength),
            regField : nullChecker(values.regField),
            regHelp : nullChecker(values.regHelp),
            regFormat : nullChecker(values.regFormat),
            regValidationMsg : nullChecker(values.regValidationMsg),
            agentField : values.agentField
        });
}

const nullChecker = (param) => {
    return ((param) ? param : null)
}

const handleChannelClick = (channel, setSelectedMap, selectedMap) => {
    setSelectedMap(new Map(selectedMap.set(channel, !selectedMap.get(channel))))
}

const CheckBox = ({label, className, key, onClick}) => {
    const intl = useIntl();
    return (
        <div className={styles.checkBoxGroup}>
            <RegularText text={label} className={styles.checkLabel}/>
            <input className={styles.checkBox} key={key} title={intl.formatMessage({id: label})}
                   onClick={onClick} type="checkbox"/>
        </div>)
}

const AddChannel = () => {

    const id = parseInt(useParams().id);
    const [title, setTitle] = useState();
    const [loading, setLoading] = useState(true);
    const [availableChannels, setAvailableChannels] = useState([]);
    const [channelsLoading, setChannelsLoading] = useState(true);
    const [selectedMap, setSelectedMap] = useState({});
    const history = useHistory();

    useEffect(() => getSubTitle(id, setTitle, setLoading), [id]);

    useEffect(() => getAvailableChannels(id, setAvailableChannels, setChannelsLoading, setSelectedMap), [id]);

    if (loading || channelsLoading) return <Loading/>

    return (
        <StandardPage header={"accounts.primaryHeader"}  values={{header: title.tagName}}
                      sub={"accounts.active.add.sub"} parentPath={"/accounts/" + id + "/config-lists/"}>
            <Box>
                <SecondaryHeading text="accounts.active.add.heading"/>
                {(availableChannels.length) ? (
                    <Formik
                    initialValues={{
                        extId : "",
                        extName : "",
                        checkCred : true,
                        accNumberLength : 0,
                        regField : "",
                        regHelp : "",
                        regFormat : "",
                        regValidationMsg : "",
                        agentField : false
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        makeEdit(values, history, selectedMap, id)
                            .finally(() => {
                                setSubmitting(false)
                                history.replace("/accounts/" + id + '/config-lists/')
                            })}}
                    >
                    {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          errors,
                          touched,
                          isSubmitting,
                          setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className={styles.buttonList}>
                                {availableChannels.map((channel) => {
                                    return (
                                        <CheckBox label={"accounts.active."+channel}
                                                  key={channel} onClick={() => handleChannelClick(channel, setSelectedMap, selectedMap)}/>
                                    )}
                                )}
                            </div>

                            <TextInput id="extId"
                                       label={"accounts.active.ext.id"}
                                       placeholder={"accounts.active.ext.id.placeholder"}
                                       onChange={handleChange}/>

                            <TextInput id="extName"
                                       label={"accounts.active.ext.name"}
                                       placeholder={"accounts.active.ext.name.placeholder"}
                                       onChange={handleChange}/>

                            <BoolField id="checkCred"
                                       label={"accounts.active.payer.cred"}
                                       value={values.checkCred}
                                       checked={(values.checkCred)}
                                       onChange={handleChange}
                                       name={"checkCred"}
                                       className={styles.boolField}/>

                            <NumField id="accNumberLength"
                                      label={"accounts.active.acc.length"}
                                      type={"number"}
                                      min={1}
                                      placeholder={"accounts.active.acc.length.placeholder"}
                                      onChange={handleChange}
                                      value={values.accNumberLength}/>

                            <TextInput id="regField"
                                       label={"accounts.active.registration.field"}
                                       placeholder={"accounts.active.registration.field.placeholder"}
                                       onChange={handleChange}/>

                            <TextInput id="regHelp"
                                       label={"accounts.active.registration.help"}
                                       placeholder={"accounts.active.registration.help.placeholder"}
                                       onChange={handleChange}/>

                            <TextInput id="regFormat"
                                       label={"accounts.active.registration.format"}
                                       placeholder={"accounts.active.registration.format.placeholder"}
                                       onChange={handleChange}/>

                            <TextInput id="regValidationMsg"
                                       label={"accounts.active.registration.validation"}
                                       placeholder={"accounts.active.registration.validation.placeholder"}
                                       onChange={handleChange}/>

                            {(availableChannels.includes('mybillsagent')) &&
                            <BoolField id="agentField"
                                       label={"accounts.active.agent.notice"}
                                       value={values.agentField}
                                       onChange={handleChange}
                                       name={"agentField"}
                                       className={styles.boolField}/>}
                            <Button className={styles.buttons} label={"integrator.save"} variant={"primary"} loading={isSubmitting} type="submit"/>
                            <Button className={styles.submitButton} label={"accountInfo.edit.cancel"} variant={"secondary"} onClick={() => history.push("/accounts/" + id + "/config-lists" )} type="button"/>
                        </Form>
                    )}
                    </Formik>
                    ) : (
                    <>
                        <RegularText text="accountInfo.no.channels"/>
                        <Button className={styles.submitButton} label={"accountInfo.back"} variant={"secondary"} onClick={() => history.push("/accounts/" + id + "/config-lists" )} type="button"/>
                    </>
                    )}
            </Box>
        </StandardPage>
    );
}

export default  AddChannel