import React, {useState} from "react";
import {Alert, Button, Check, Modal, StandardText, TextInput} from "../../../common";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import styles from "../../../common/_Modal/_Modal.module.scss";
import axios from "axios";
import {useAppState} from "../../../../state";
import {NOTE_ADDED} from "../../../../state/reducers/alertReducer";

const addNote = (id, content, customerNote, setFormError, handleClose, dispatch) => {

    return axios.post("/api/payreq-accounts/create-note",
            {
                accountId: id,
                note: content,
                customerNote: customerNote
        })
        .then(({data}) => {
            if (data.added) {
                dispatch({type: NOTE_ADDED})
                handleClose()
            } else {
                setFormError(true);
            }
        })
}

const AddNoteModal = ({accountId, handleChange, show, setShow}) => {

    const [formError, setFormError] = useState(false);
    const [, dispatch] = useAppState();

    const handleClose = () => {
        setFormError(false)
        setShow(false)
    };

    const validateBox = Yup.object().shape({
        note: Yup.string()
            .required("formModal.required")
    })

    return <Modal header={"accounts.notes.modal.header"} show={show} handleClose={() => setShow(false)}>
        <Formik
            initialValues={{note: '', customerNote: false}}
            validationSchema={validateBox}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(true);
                addNote(accountId, values.note, values.customerNote, setFormError, handleClose, dispatch)
                    .then(() => handleChange())
                    .finally(() => setSubmitting(false))
            }}
        >
            {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                    <TextInput id="note"
                               as={"textarea"}
                               label={"accounts.notes.modal.label"}
                               placeholder={"accounts.notes.modal.placeholder"}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               value={values.note}
                               errors={errors.note}
                               touched={touched.note}/>
                    <Check id="customerNote" setFieldValue={setFieldValue} options={[{
                        label: <StandardText text={"accounts.contacts.form.createShared"}/>,
                        val: "customerNote"
                    }]}/>
                    {formError && <Alert variant={"danger"}><StandardText text={"accounts.notes.modal.formError"}/></Alert>}
                    <Modal.Footer>
                        <Button label={"accounts.notes.modal.cancel"} variant={"secondary"} onClick={handleClose} type="button"/>
                        <Button label={"accounts.notes.modal.addNote"} variant={"primary"} loading={isSubmitting}
                                type="submit" className={styles.submitButton}/>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    </Modal>
};
export {AddNoteModal};