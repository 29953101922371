import React from "react";
import FormModal from "../Modals/FormModal";
import RegularText from "../common/_RegularText";

const RemoveUserModal = ({onYes, onNo, show, setShow, setText = () => {}}) => {
    return <FormModal header={"accounts.modal.headerRemove"}
                              message={<RegularText text="accounts.modal.messageUserRemove"/>}
                              affLabel={"accounts.modal.buttonUserRemove"}
                              affVariant={"danger"}
                              negLabel={"accounts.modal.buttonNo"}
                              negVariant={"secondary"}
                              onYes={onYes}
                              onNo={onNo}
                              show={show} setShow={setShow}
                              setText={setText}
                              textFieldMessage={"accounts.modal.reasonMessage"}
                              textPlaceholder={"accounts.modal.reasonPlaceholder"}
    />;
};
export {RemoveUserModal};