import React  from "react";
import {Modal} from "../../../common";
import {RuleForm} from "../RuleForm/RuleForm";


export const EditRuleModal = ({rule, show, setShow, setSuccessAlert, onSuccess}) => {
    return (
        <Modal header={"templateRule.edit.heading"} values={{field: rule.name}} show={show} setShow={setShow} handleClose={() => setShow(false)}>
            <RuleForm rule={rule} show={show} setShow={setShow} onSuccess={onSuccess} setSuccessAlert={setSuccessAlert}/>
        </Modal>)
};