import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {
    SecondaryHeading,
    StandardPage,
    Box,
    Button,
    Modal
} from "../../../../../common";
import axios from "axios";
import Loading from "../../../../../Loading/Loading";
import {Form, Formik} from "formik";
import styles from "./DeleteChannel.module.scss";
import RegularText from "../../../../../common/_RegularText";
import {useIntl} from "react-intl";

const getSubTitle = (id, setTitle, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/acc-name", {params: {accountId: id}})
        .then(({data}) => {
            setTitle(data)
        })
        .finally(() => {
            setLoading(false);
        });
};

const getAvailableChannels = (id, setAvailableChannels, setChannelsLoading, setSelectedMap) => {
    setChannelsLoading(true);
    axios.get("/api/payreq-accounts/account-active-channels", {params: {accountId: id}})
        .then(({data}) => {
            let map = new Map()
            data.map((channel) => map.set(channel.channelPartnerSystemId, false))
            setSelectedMap(map)
            setAvailableChannels(data.map((channel) => channel.channelPartnerSystemId))
        })
        .finally(() => {
            setChannelsLoading(false);
        });
}

const makeEdit = (values, history, selectedMap, id) => {
    let channels = []
    selectedMap.forEach((value, key) => {if (value) channels.push(key)})
    return axios.post("/api/payreq-accounts/channels/delete", {
        accountId : id,
        channels : channels});
}

const handleChannelClick = (channel, setSelectedMap, selectedMap) => {
    setSelectedMap(new Map(selectedMap.set(channel, !selectedMap.get(channel))))
}
const ConfirmationModal = ({show, setShow, isSubmitting, channelMap}) => {
    let channels = []
    channelMap.forEach((value, key) => {if (value) channels.push(key)})
    return (
        <Modal header={"accounts.customer.edit.confirmation"} show={show} handleClose={() => setShow(false)}>
            <b><RegularText text={"accounts.active.edit.warning"} classname={"styles.deleteHeader"}/></b>
            <div className={styles.channelList}>
                {channels.map((channel) => {
                    return (
                        <RegularText text={"accounts.active."+channel}
                                className={styles.modalChannel} key={channel}/>
                    )}
                )}
            </div>
            <Button className={styles.buttons} label={"accounts.active.delete.button"} variant={"danger"} loading={isSubmitting} type="submit" form="deleteForm"/>
            <Button className={styles.submitButton} label={"accountInfo.edit.cancel"} variant={"secondary"} onClick={() => setShow(false)} type="button"/>
        </Modal>)
}

const CheckBox = ({label, className, key, onClick}) => {
    const intl = useIntl();
    return (
        <div className={styles.checkBoxGroup}>
            <RegularText text={label} className={styles.checkLabel}/>
            <input className={styles.checkBox} key={key} title={intl.formatMessage({id: label})}
                   onClick={onClick} type="checkbox"/>
        </div>)
}

const DeleteChannel = () => {

    const id = parseInt(useParams().id);
    const [title, setTitle] = useState();
    const [loading, setLoading] = useState(true);
    const [availableChannels, setAvailableChannels] = useState([]);
    const [channelsLoading, setChannelsLoading] = useState(true);
    const [selectedMap, setSelectedMap] = useState({});
    const history = useHistory();
    const [show, setShow] = useState(false);

    useEffect(() => getSubTitle(id, setTitle, setLoading), [id]);

    useEffect(() => getAvailableChannels(id, setAvailableChannels, setChannelsLoading, setSelectedMap), [id]);

    if (loading || channelsLoading) return <Loading/>

    return (
        <StandardPage header={"accounts.primaryHeader"}  values={{header: title.tagName}}
                      sub={"accounts.active.delete.sub"} parentPath={"/accounts/" + id + "/config-lists/"}>
            <Box>
                <SecondaryHeading text="accounts.active.fields.header"/>
                <Formik
                    initialValues={{
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        makeEdit(values, history, selectedMap, id)
                            .finally(() => {
                                setSubmitting(false)
                                history.replace("/accounts/" + id + '/config-lists/')
                            })}}
                >
                    {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          errors,
                          touched,
                          isSubmitting,
                          setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit} id="deleteForm">
                            <div className={styles.buttonList}>
                                {availableChannels.map((channel) => {
                                    return (
                                        <CheckBox label={"accounts.active."+channel}
                                             key={channel} onClick={() => handleChannelClick(channel, setSelectedMap, selectedMap)}/>
                                    )}
                                )}
                            </div>


                            <Button className={styles.buttons} label={"accounts.active.delete.button"} variant={"danger"} loading={isSubmitting} onClick={() => setShow(true)} type="button"/>
                            <Button className={styles.submitButton} label={"accountInfo.edit.cancel"} variant={"secondary"} onClick={() => history.push("/accounts/" + id + "/config-lists/" )} type="button"/>
                            <ConfirmationModal show={show} isSubmitting={isSubmitting} setShow={setShow} channelMap={selectedMap}/>
                        </Form>
                    )}

                </Formik>
            </Box>
        </StandardPage>
    );
}

export default DeleteChannel