import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import Loading from "../Loading/Loading";
import {Alert, PrimaryHeading, SecondaryHeading, StandardText, Table} from "../common";
import {useParams} from "react-router";
import ButtonCluster from "../ButtonCluster/ButtonCluster";
import styles from "./MailerCredits.module.scss";
import ErrorMessage from "../ErrorMessage";
import AddCreditPackModal from "./AddCreditPackModal";
import {Link} from "react-router-dom";
import ChangeCreditExpiryModal from "./ChangeCreditExpiryModal";
import {readableDateUTC} from "../Helpers/DateTimeHelpers";
import {cellExpired} from "../Helpers/Helpers";

const getCreditSharingAccounts = (accountId, setSharingAccounts) => {
    axios.post("/api/payreq-accounts/shared-accounts", {accountId: accountId})
        .then(({data}) => {
            setSharingAccounts(data.accounts);
        })
}

const getAccountData = (id, setAccountData, setLoading) => {
    if (id >= 0) {
        setLoading(true);
        axios.post("/api/payreq-accounts/account-info", {accountId: id})
            .then(({data}) => {
                setAccountData(data)
            })
            .finally(() => {
                setLoading(false);
            });
    }
};

const getCreditData = (id, setCreditData, setCreditMeta, pageNumber, setLoading) => {
    if (id >= 0) {
        setLoading(true);
        axios.post("/api/credits/credit-packs", {accountId: id, pageNumber: pageNumber})
            .then(({data}) => {
                setCreditData(data.creditPacks);
                setCreditMeta(data.meta);
            })
            .finally(() => {
                setLoading(false);
            });
    }
};

const CreditSharingAccounts = ({accounts}) => {
    return (
        <div className={styles.packsBox}>
            <span className={styles.sharingAccs}>Shares credit with: </span>
            {accounts.map((acc, index) => <span className={styles.sharingAccs} key={index}><Link to={`/accounts/${acc.customerActorId}`}>{acc.tagName}</Link>{(index === accounts.length - 1) ? "" : ", "}</span>)}
        </div>
    );
}

const packsColumnHeaders = [
    {
        Header: <StandardText text="credits.table.id"/>,
        accessor: 'id',
    },
    {
        Header: <StandardText text="credits.table.name"/>,
        accessor: 'name',
    },
    {
        Header: <StandardText text="credits.table.description"/>,
        accessor: 'description',
    },
    {
        Header: <StandardText text="credits.table.credits"/>,
        accessor: 'credit',
    },
    {
        Header: <StandardText text="credits.table.expiry"/>,
        accessor: 'expiry',
        Cell: (row) => readableDateUTC(row['expiry']),
    },
    {
        Header: <StandardText text="credits.table.status"/>,
        accessor: 'expired',
        Cell: cellExpired,
    },
];

const CreditPacks = ({creditData, creditMeta, pageNumber, setPageNumber, loading, handleChangeExpiry}) => {

    const invalid = !creditData || creditData.length === 0;

    if (invalid) return <ErrorMessage><StandardText text="credits.noPacks"/></ErrorMessage>;

    return (
        <Table columnHeaders={packsColumnHeaders} tableData={creditData} tableMeta={creditMeta} pageNumber={pageNumber}
               setPageNumber={setPageNumber} handleRowClick={handleChangeExpiry} targetColumns={["id"]} loading={loading}/>
    );
};

const InvalidAccount = () => (
    <div>
        <PrimaryHeading text="credits.invalidAccount" sub="credits.subheading"/>
    </div>
);

const InboxAccount = ({accountId, accountData}) => (
    <div>
        <PrimaryHeading text="specific.primaryHeader" values={{header: accountData.tagName}} sub={"credits.subheading"}/>
        <div className={styles.packsBox}>
            <ErrorMessage><StandardText text={"credits.notDeliveryAccount"}/></ErrorMessage>
        </div>
    </div>
);



const CreditInfo = ({accountId, accountData, setSuccessAlert, showChangeCreditExpiryModal, setShowChangeCreditExpiryModal,
                        packPrefill, setPackPrefill, onSuccess}) => {

    const [showAddCreditModal, setShowAddCreditModal] = useState(false);
    const invalid = !accountData || accountData.length === 0 || accountData['status'] === 2;

    if (invalid) return <InvalidAccount/>

    return (
        <React.Fragment>
            <div>
                <PrimaryHeading text="specific.primaryHeader" values={{header: accountData.tagName}} sub={"credits.subheading"}/>
            </div>
            <ButtonCluster buttons={[
                {icon: "payments", label: "credits.addCredit", onClick: () => {setShowAddCreditModal(true)}, variant: "primary"},
                {icon: "today", label: "credits.changeExpiry", onClick: () => {setShowChangeCreditExpiryModal(true)}, variant: "primary"},
            ]}/>
            <AddCreditPackModal show={showAddCreditModal} setShow={setShowAddCreditModal} accountId={accountId}
                                setSuccessAlert={setSuccessAlert} onSuccess={onSuccess}/>
            <ChangeCreditExpiryModal show={showChangeCreditExpiryModal} setShow={setShowChangeCreditExpiryModal}
                                     accountId={accountId} setSuccessAlert={setSuccessAlert} onSuccess={onSuccess}
                                     packPrefill={packPrefill} setPackPrefill={setPackPrefill}/>
        </React.Fragment>
    );
}

const MailerCredits = () => {
    const [accountData, setAccountData] = useState([]);
    const [creditData, setCreditData] = useState([]);
    const [creditMeta, setCreditMeta] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [successAlert, setSuccessAlert] = useState();
    const [sharingAccounts, setSharingAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showChangeCreditExpiryModal, setShowChangeCreditExpiryModal] = useState(false);
    const [packPrefill, setPackPrefill] = useState("");

    const inbox = accountData.systemId !== "delivery";

    const id = parseInt(useParams().id);

    const handleSuccess = useCallback(() => { getCreditData(id, setCreditData, setCreditMeta, pageNumber, setLoading)},
        [id, pageNumber]);

    const handleChangeExpiry = (packId) => {
        setPackPrefill(packId[0]);
        setShowChangeCreditExpiryModal(true);
    }

     useEffect(() => getAccountData(id, setAccountData, setLoading),
         [id]);

    useEffect(() => getCreditData(id, setCreditData, setCreditMeta, pageNumber, setLoading),
        [id, pageNumber]);

    useEffect(() => getCreditSharingAccounts(id, setSharingAccounts),
        [id])

    if (loading) return <Loading/>;
    if (inbox) return <InboxAccount accountId={id} accountData={accountData}/>


     return(
         <React.Fragment>
             {successAlert && <Alert variant={"success"}><StandardText text={successAlert}/></Alert>}
             <CreditInfo accountId={id} accountData={accountData} setSuccessAlert={setSuccessAlert} onSuccess={handleSuccess}
                         showChangeCreditExpiryModal={showChangeCreditExpiryModal}
                         setShowChangeCreditExpiryModal={setShowChangeCreditExpiryModal} packPrefill={packPrefill}
                         setPackPrefill={setPackPrefill}/>
             {sharingAccounts.length > 0 && <CreditSharingAccounts accounts={sharingAccounts}/>}
             <div className={styles.packsBox}>
                 <SecondaryHeading className={styles.accHeader} text={"credits.creditPacks"}/>
                 <CreditPacks creditData={creditData} creditMeta={creditMeta} pageNumber={pageNumber}
                              setPageNumber={setPageNumber} loading={loading} handleChangeExpiry={handleChangeExpiry}/>
             </div>
         </React.Fragment>
     )
 }

export default MailerCredits;