import Modal from "react-bootstrap/Modal";
import React from "react";
import styles from './_Modal.module.scss'
import {FormattedMessage} from "react-intl";

const ButtonContainer = ({children}) => {
    return <div className={styles.buttonContainer}>
        {children}
    </div>
}

const _Modal = ({header, show, handleClose, children, animation = true}) => {
    return <Modal show={show} onHide={handleClose} className={styles.modal} animation={animation}>
        <Modal.Header closeButton className={styles.modalPadding}>
            <Modal.Title className={styles.header}>{<FormattedMessage id={header}/>}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalPadding}>
            {children}
        </Modal.Body>
    </Modal>
}

_Modal.Footer = ButtonContainer

export default _Modal;