import React, {createContext, useContext, useReducer} from 'react';
import userReducer from "./reducers/userReducer";
import errorReducer from "./reducers/errorReducer";
import 'material-icons/iconfont/material-icons.css';
import alertReducer from "./reducers/alertReducer";

const mainReducer = ({user, error, alert}, action) => {
    // middleware goes here, i.e calling analytics service, etc.
    return {
        user: userReducer(user, action),
        error: errorReducer(error, action),
        alert: alertReducer(alert, action)
    };
};

export const StateContext = createContext();

export const StateProvider = ({children, initialState}) => (
    <StateContext.Provider value={useReducer(mainReducer, initialState)}>
        {children}
    </StateContext.Provider>
);

export const useAppState = () => useContext(StateContext);