import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {useAppState} from "../../../state";
import styles from "../SupportNotes/SupportNotes.module.scss";
import {Button, ConfirmButton as DeleteButton, IconText} from "../../common";
import ErrorMessage from "../../ErrorMessage";
import RegularText from "../../common/_RegularText";
import Pagination from "../../Pagination/Pagination";
import {FormattedMessage} from "react-intl";
import {readableDate} from "../../Helpers/DateTimeHelpers";
import {AddNoteModal} from "./AddNoteModal/AddNoteModal";
import {EditNoteModal} from "./EditNoteModal/EditNoteModal";
import {NOTE_REMOVED} from "../../../state/reducers/alertReducer";

const getSupportNotes = (id, setNotes, setNotesPinned, setNotesMeta, setNotesPinnedMeta, pageNumberUnpinned, pageNumberPinned, setLoading) => {
    if (id >= 0) {
        setLoading(true);
        axios.post("/api/payreq-accounts/support-notes",
            {
                accountId: id,
                unpinnedPageNumber: pageNumberUnpinned,
                pinnedPageNumber: pageNumberPinned,
            })
            .then(({data}) => {
                setNotes(data.unpinned.notes);
                setNotesPinned(data.pinned.notes);
                setNotesMeta(data.unpinned.meta);
                setNotesPinnedMeta(data.pinned.meta);
            }).finally(() => setLoading(false))
    }
};

const deleteNote = (id, handleChange, setLoading, dispatch) => {
    if (id >= 0) {
        setLoading(true);
        axios.post("/api/payreq-accounts/delete-note",
            {
                noteId: id
            })
            .then(() => {
                handleChange()
                dispatch({type: NOTE_REMOVED})
            })
            .finally(() => setLoading(false))
    }
}

const pinNote = (id, handleChange) => {
    if (id >= 0) {
        axios.post("/api/payreq-accounts/pin-note", {noteId: id})
            .then(() => handleChange())
    }
};

const unpinNote = (id, handleChange) => {
    if (id >= 0) {
        axios.post("/api/payreq-accounts/unpin-note", {noteId: id})
            .then(() => handleChange())
    }
};

const SubText = ({children}) => {
    return (
        <div className={styles.subText}>{children}</div>
    );
};

const NoteRow = ({note, handleEdit, handleChange}) => {

    const [loading, setLoading] = useState(false);
    const [, dispatch] = useAppState();


    return (
        <span className={styles.noteBlock}>
            <span key={note.id} className={styles.noteRow}>
                <span className={styles.noteContent}>{note.content}</span>
                <span className={styles.rowButtons}>
                    {!note.pinned && <Button icon={"push_pin"} variant={"link"} onClick={() => pinNote(note.id, handleChange)}/>}
                    {note.pinned && <Button filledIcon={"push_pin"} variant={"link"} onClick={() => unpinNote(note.id, handleChange)}/>}
                    <Button icon={"edit"} variant={"link"} onClick={() => handleEdit(note.id, note.content, note.customerNote)}/>
                    <DeleteButton icon="delete" confirmIcon="delete_forever" variant={"link"} confirmClassName={styles.confirmRed}
                                  onClick={() => deleteNote(note.id, handleChange, setLoading, dispatch)}
                                  loading={loading}/>
                </span>
            </span>
            <SubText>
                <span className={styles.authorEditor}>
                    <span className={styles.authorShared}>
                        {note.customerNote && <IconText icon={"share"} variant={"disabled"} className={styles.sharedSubtext}/>}
                        <FormattedMessage id={(note.editor) ? "accounts.notes.authorEdited" : "accounts.notes.author"}
                                          values={{author: note.author, date: readableDate(note.createdOn)}}/>
                    </span>
                    <span>
                        {note.editor &&
                        <FormattedMessage id={"accounts.notes.editor"}
                                          values={{editor: note.editor, date: readableDate(note.editedOn)}}/>}
                    </span>
                </span>
            </SubText>
        </span>
    );
}

const NoteList = ({noteData, noteMeta, pageNumber, setPageNumber, emptyMessage, handleEdit, handleChange, loading}) => {

    if (!noteData || noteData.length < 1) return (
        <div className={styles.listStyle}>
            <ErrorMessage><RegularText text={emptyMessage} className={styles.rowPadding}/></ErrorMessage>
        </div>);
    return (
        <div className={styles.listStyle}>
            {noteData.map((note) =>
                <NoteRow note={note} handleEdit={handleEdit} handleChange={handleChange} key={note.id}/>
            )}
            {noteMeta && <Pagination meta={noteMeta} pageNumber={pageNumber} setPageNumber={setPageNumber} loading={loading}/>}
        </div>
    );
};

const SupportNotes = () => {

    const [notes, setNotes] = useState([]);
    const [notesPinned, setNotesPinned] = useState([]);
    const [notesMeta, setNotesMeta] = useState([]);
    const [notesPinnedMeta, setNotesPinnedMeta] = useState([]);
    const [pageNumberUnpinned, setPageNumberUnpinned] = useState(0);
    const [pageNumberPinned, setPageNumberPinned] = useState(0);
    const [showAddNoteModal, setShowAddNoteModal] = useState(false);
    const [notePrefill, setNotePrefill] = useState({});
    const [showEditNoteModal, setShowEditNoteModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const id = parseInt(useParams().id);

    function handleEdit(noteId, noteContent, customerNote) {
        setNotePrefill({noteId, noteContent, customerNote});
        setShowEditNoteModal(true);
    }

    const handleChange = useCallback(() => getSupportNotes(id, setNotes, setNotesPinned, setNotesMeta, setNotesPinnedMeta, pageNumberUnpinned, pageNumberPinned, setLoading),
        [id, pageNumberPinned, pageNumberUnpinned]);

    useEffect(() => getSupportNotes(id, setNotes, setNotesPinned, setNotesMeta, setNotesPinnedMeta, pageNumberUnpinned, pageNumberPinned, setLoading),
        [id, pageNumberPinned, pageNumberUnpinned]);

    const validNotes = notes && notes.length > 0;
    const validPinned = notesPinned && notesPinned.length > 0;

    return (
        <React.Fragment>
            {(!validNotes || validPinned) &&
                <NoteList noteData={notesPinned} noteMeta={notesPinnedMeta} pageNumber={pageNumberPinned}
                       emptyMessage={"accounts.notes.noNotes"} setPageNumber={setPageNumberPinned}
                          handleEdit={handleEdit} handleChange={handleChange} loading={loading}/>}
            {(validNotes && validPinned) && <hr/>}
            {validNotes && <NoteList noteData={notes} noteMeta={notesMeta} pageNumber={pageNumberUnpinned}
                       setPageNumber={setPageNumberUnpinned} handleEdit={handleEdit} handleChange={handleChange}
                       loading={loading}/>}
            <span className={styles.addNote}>
                <Button variant={"link"} icon={"note_add"} label={"accounts.notes.addNote"} onClick={() => setShowAddNoteModal(true)}/>
            </span>
            <AddNoteModal accountId={id} handleChange={handleChange} show={showAddNoteModal} setShow={setShowAddNoteModal}/>
            <EditNoteModal notePrefill={notePrefill} setNotePrefill={setNotePrefill} handleChange={handleChange} show={showEditNoteModal} setShow={setShowEditNoteModal}/>
        </React.Fragment>
    );
}

export default SupportNotes;