import styles from "./_Box.module.scss";

const _Box = ({children}) => {
    return(
        <div className={styles.box}>
        {children}
        </div>
    );
}

export default _Box;