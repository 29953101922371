import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import styles from "./MailTemplates.module.scss";
import ErrorMessage from "../../ErrorMessage";
import RegularText from "../../common/_RegularText";
import {useIntl} from "react-intl";
import {getCreditTypeLabel} from "./MailTemplateUtils";
import {Alert, Button, StandardText} from "../../common";
import {EditTemplateModal} from "./EditTemplate/EditTemplateModal";
import {DeleteTemplateModal} from "./DeleteTemplate/DeleteTemplateModal";
import {CopyTemplateModal} from "./CopyTemplate/CopyTemplateModal";

const getMailTemplates = (billerId, setAccountMailTemplate) => {
    axios.get(`/api/templates/active`, {params: {billerId: billerId}})
        .then(({data}) => {
            setAccountMailTemplate(data.templates);
        })
};

const MailTemplateRow = ({template, setSuccessAlert, onSuccess, handleChange}) => {
    const [showDeleteTemplateModal ,setShowDeleteTemplateModal] = useState(false);
    const [showCopyTemplateModal ,setShowCopyTemplateModal] = useState(false);
    const intl = useIntl();
    const history = useHistory();
    const creditType = getCreditTypeLabel(template.typeName);
    const id = useParams().id;

    return (
        <div className={styles.rowStyle} key={template.id}>
            <div className={styles.labelStyle} onClick={() => history.push(`${template.billerActorId}/template/${template.id}`)}>
                {template.displayName + ' (' + intl.formatMessage({id: creditType}) + ') '}
            </div>
            <Button variant="link" icon="content_copy" onClick={() => setShowCopyTemplateModal(true)}/>
            <Button variant="link" icon="delete" onClick={() => setShowDeleteTemplateModal(true)}/>
            <DeleteTemplateModal id={template.id} name={template.displayName}
                                 show={showDeleteTemplateModal} setShow={setShowDeleteTemplateModal}
                                 onSuccess={onSuccess} setSuccessAlert={setSuccessAlert}/>
            <CopyTemplateModal id={id} templateId={template.id} name={template.displayName}
                                 show={showCopyTemplateModal} setShow={setShowCopyTemplateModal}
                                 onSuccess={onSuccess} setSuccessAlert={setSuccessAlert} handleChange={handleChange}/>
        </div>
    )
}

const MailTemplateList = ({accountMailTemplates, setSuccessAlert, onSuccess, handleChange}) => {
    if (!accountMailTemplates || accountMailTemplates.length === 0) return (
        <div className={styles.listStyle}>
            <ErrorMessage><RegularText text="accounts.templates.noTempates" className={styles.rowPadding}/></ErrorMessage>
        </div>);

    return (
        <div className={styles.listStyle}>
            {accountMailTemplates.map((template) =>
                <MailTemplateRow template={template} setSuccessAlert={setSuccessAlert} onSuccess={onSuccess} handleChange={handleChange}/>
            )}
        </div>
    )
}

const MailTemplates = () => {
    const [accountMailTemplates, setAccountMailTemplates] = useState([]);
    const [showCreateTemplateModal ,setShowCreateTemplateModal] = useState(false);
    const [successAlert, setSuccessAlert] = useState();


    const id = parseInt(useParams().id);

    const handleTemplateChange = useCallback(() => getMailTemplates(id, setAccountMailTemplates),
        [id]);

    useEffect(() => getMailTemplates(id, setAccountMailTemplates),
        [id]);

    return (
        <React.Fragment>
            {successAlert && <Alert variant={"success"}><StandardText text={successAlert}/></Alert>}
            <MailTemplateList accountMailTemplates={accountMailTemplates} setSuccessAlert={setSuccessAlert} onSuccess={handleTemplateChange} handleChange={handleTemplateChange}/>
            <span className={styles.addTemplate}> <Button variant={"link"} icon={"add_circle_outline"} label={"template.edit.addButton"} onClick={() => setShowCreateTemplateModal(true)}/></span>
            <EditTemplateModal  template={{billerActorId: id}} isCreateTemplate={true}
                                show={showCreateTemplateModal} setShow={setShowCreateTemplateModal}
                                setSuccessAlert={setSuccessAlert} onSuccess={handleTemplateChange}/>
        </React.Fragment>
    )
}

export default MailTemplates;