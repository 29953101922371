import React, {useState} from "react";
import styles from "./InfoBox.module.scss";
import {Button, StandardText} from "../common";
import Loading from "../Loading/Loading";
import {valueParse} from "../../utils/display-helper-utils";



const showHide = (visible, collapsed) => {
    if (visible || !collapsed) return styles.infoBoxRow;
    return styles.hiddenRow;
};

const InfoRows = ({row}) => {
    return (
        <React.Fragment>
            {row.map(({label, value}, index) => (
                <div className={styles.valPair} key={index}>
                    <span className={styles.label}><StandardText text={label}/></span>
                    <div className={styles.value}>{valueParse(value)}</div>
                </div>
            ))}
        </React.Fragment>
    );
}

const InfoBox = ({rows, rowsAlwaysShown = 3, loading = false, classname}) => {
    const [collapsed, setCollapsed] = useState(true);
    let pairs = [];
    rows.slice(0, rowsAlwaysShown).map(pair => {
        return pairs.push({"rowPair": pair, "visible": true});
    })
    rows.slice(rowsAlwaysShown, rows.length).map(pair => {
        return pairs.push({"rowPair": pair, "visible": false});
    })
    return (
        <div className={styles.infoBoxBoxed + " " + classname}>
            {(loading) ?
                <Loading message={"loading.findingInfo"}/> :
                <div className={styles.infoBox}>
                    {pairs.map((row, index) => (
                        <div className={showHide(row.visible, collapsed)} key={index}>
                            <InfoRows row={row.rowPair}/>
                        </div>
                    ))}
                </div>}
            {rows.length !== rowsAlwaysShown && <Button label={(collapsed ? "infoBox.showMore" : "infoBox.showLess")}
                                                        onClick={() => setCollapsed(collapsed => !collapsed)} variant={"link"}/>}
        </div>
    );

}

export default InfoBox;