import axios from "axios";
import React, {useEffect, useState} from "react";
import {SearchBar} from "../SearchBar/SearchBar";
import {PrimaryHeading, StandardText, Table} from "../common";
import {Link, useHistory} from "react-router-dom";
import {useIntl} from 'react-intl';
import {cellStatus} from "../Helpers/Helpers";
import {readableDateTime} from "../Helpers/DateTimeHelpers";
import styles from "./PayreqUsers.module.scss";
import ErrorMessage from "../ErrorMessage";

const getPayreqUsers = (searchTerm, pageNumber, setPayreqUsers, setUserMeta, setLoading, searched) => {
    setLoading(true);
    if (searched) {
        axios.post("/api/payreq-users", {search: searchTerm, pageNumber: pageNumber})
            .then(({data}) => {
                setPayreqUsers(data.payreqUsers);
                setUserMeta(data.meta);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        setLoading(false);
    }
};

const columnHeaders = [
    {
        Header: <StandardText text="userTable.id"/>,
        accessor: 'id',
    },
    {
        Header: <StandardText text="userTable.name"/>,
        accessor: 'name',
        Cell: (row, col) => <Link to={'/users/' + row['id']}>{row[col['accessor']]}</Link>,
    },
    {
        Header: <StandardText text="userTable.email"/>,
        accessor: 'email',
    },
    {
        Header: <StandardText text="userTable.phone"/>,
        accessor: 'mobileNumber',
    },
    {
        Header: <StandardText text="userTable.status"/>,
        accessor: 'status',
        Cell: cellStatus,
    },
    {
        Header: <StandardText text="userTable.referrer"/>,
        accessor: 'referrer',
    },
    {
        Header: <StandardText text="userTable.timeCreated"/>,
        accessor: 'createdTime',
        Cell: (row) => readableDateTime(row['createdTime']),
    },
];

const Users = ({payreqUsers, userMeta, pageNumber, setPageNumber, searched, history, loading}) => {

    const invalid = !payreqUsers || payreqUsers.length === 0;

    if (invalid) return (searched) ?
        <ErrorMessage><StandardText text="users.empty"/></ErrorMessage> :
        <b><StandardText text="users.start"/></b>;

    return (
        <Table columnHeaders={columnHeaders} tableData={payreqUsers} tableMeta={userMeta} pageNumber={pageNumber}
               setPageNumber={setPageNumber} handleRowClick={(data) => history.push('/users/' + data[0])}
               targetColumns={['id']} loading={loading}/>
    );
};

const PayreqUsers = () => {
     const [payreqUsers, setPayreqUsers] = useState([]);
     const [userMeta, setUserMeta] = useState([]);
     const [loadingUsers, setLoadingUsers] = useState(true);
     const [pageNumber, setPageNumber] = useState(0);
     const [searchTerm, setSearchTerm] = useState("");
     const [searched, setSearched] = useState(false);

     useEffect(() => getPayreqUsers(searchTerm, pageNumber, setPayreqUsers, setUserMeta, setLoadingUsers, searched),
         [searchTerm, searched, pageNumber]);

     const history = useHistory();
     const intl = useIntl();

     return(
         <React.Fragment>
             <PrimaryHeading text={"users.header"}/>
             <div className={styles.userBox}>
                 <SearchBar setPageNumber={setPageNumber}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            setSearched={setSearched}
                            intl={intl}/>
                <Users payreqUsers={payreqUsers} userMeta={userMeta} pageNumber={pageNumber}
                       setPageNumber={setPageNumber} searched={searched} history={history} loading={loadingUsers}/>
             </div>
         </React.Fragment>
     )
 };

 export default PayreqUsers;