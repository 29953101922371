import React, {useState} from "react";
import {Button, Modal, RegularText} from "../../../common";
import styles from "./DeleteTemplateModal.module.scss";
import axios from "axios";

const deleteTemplate = (id, setSuccessAlert, handleClose) => {
    return axios.post("/api/templates/delete-template", {id: id})
        .then(({data}) => {
            if (data.success) {
                handleClose()
                setTimeout(() => setSuccessAlert(), 6000);
                setSuccessAlert("template.delete.success");
            }
        })
}


export const DeleteTemplateModal = ({id, name, show, setShow, setSuccessAlert, onSuccess}) => {
    const [submitting, setSubmitting] = useState(false);

    return (
        <Modal header={"template.delete.heading"} show={show} setShow={setShow} handleClose={() => setShow(false)}>
            <RegularText text={"template.delete.text"} values={{name: name}}/>
            <Modal.Footer className={styles.buttons}>
                <Button label={"accounts.convertModal.cancel"} variant={"secondary"}
                        type="button" onClick={() => setShow(false)}/>
                <Button label={"template.delete.button"} variant={"danger"} loading={submitting}
                        type="button" className={styles.submitButton}
                        onClick={() => {
                            setSubmitting(true);
                            deleteTemplate(id, setSuccessAlert, ()=> setShow(false))
                                .then(() => onSuccess())
                                .catch(() => {})
                                .finally(() => setSubmitting(false));
                        }}/>
            </Modal.Footer>
        </Modal>)
};