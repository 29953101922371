import React, {useState} from "react";
import {Alert, Button, DropdownSelect, Modal, StandardText, TextInput,} from "../../../common";
import styles from "./RuleForm.module.scss";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {locationReadingRules, textReadingRules} from "../MailTemplateUtils";
import RegularText from "../../../common/_RegularText";
import axios from "axios";
import {ruleTypeAbsent, ruleTypePdfLocation, ruleTypePdfText} from "../MailTemplateUtils";

const yesAndNoOpts = ["yes", "no"].map((opt) => [`templateRule.edit.${opt}`, opt]);

const ruleTypeOpts = [ruleTypeAbsent, ruleTypePdfLocation, ruleTypePdfText].map((opt) => [`templateRule.edit.${opt}`, opt]);

const textPosition = [["templateRule.edit.before", "0"],
                      ["templateRule.edit.above", "1"],
                      ["templateRule.edit.after", "2"],
                      ["templateRule.edit.below", "3"],
                      ["templateRule.edit.below-line", "4"]];

const postProcessingFunctionsOpts = [
    "none", "before-bracket", "coliban-get-bvrn", "flip-names", "flip-names-comma-after-surname-add-initials",
    "flip-names-for-t4", "first-digits", "reduce-whitespace", "reduce-whitespace-add-comma-after-surname",
    "reduce-whitespace-add-comma-after-surname-with-initials", "reduce-whitespace-and-max-epost-length",
    "remove-whitespace", "space-between-names", "unique-invoice-id", "zip-code-from-canada-address",
    "zip-code-from-canada-address-after-comma", "zip-code-from-canada-and-usa-address", "big-emp-name",
    "big-emp-code", "split-emp-id-and-name", "customer-name-from-first-line", "less-then-3-digit-from-page-block", "canada-zip-code-from-page-block"]
    .map((opt) => [`templateRule.edit.fn.${opt}`, opt]);

const postProcessingFunctionHintsToIgnore = ["none", "big-emp-name", "big-emp-code", "split-emp-id-and-name",
                                                        "customer-name-from-first-line", "less-then-3-digit-from-page-block",
                                                        "canada-zip-code-from-page-block"]


const formatRuleForEdit = (editedRule) => {
    //when location rule then make the label the pageNo + coords
    const label = (editedRule.type === ruleTypePdfLocation)
        ? `${editedRule.pageNo}:${editedRule.left},${editedRule.top},${editedRule.width},${editedRule.height}`
        : editedRule.label;
    const absent = (editedRule.type === ruleTypeAbsent);
    const fieldTransformFn = (editedRule.fieldTransformFn === "none") ? null : editedRule.fieldTransformFn;
    return {...editedRule, label, absent, position: parseInt(editedRule.position), fieldTransformFn: fieldTransformFn};
}

const updateRule = (ruleId, editedRule, setError, setSuccessAlert, handleClose) => {
    return axios.post("/api/templates/update-rule", {ruleId: ruleId, ruleValues: formatRuleForEdit(editedRule)})
        .then(({data}) => {
            if (data.success) {
                handleClose()
                setTimeout(() => setSuccessAlert(), 6000);
                setSuccessAlert("templateRule.edit.success");
            } else {
                setTimeout(() => setError(false), 6000);
                setError(true);
                throw new Error("Invalid Request")
            }
        })
}

const addRule = (ruleToAdd, setError, setSuccessAlert, handleClose) => {
    return axios.post("/api/templates/add-rule", {ruleValues: formatRuleForEdit(ruleToAdd)})
        .then(({data}) => {
            if (data.success) {
                handleClose()
                setTimeout(() => setSuccessAlert(), 6000);
                setSuccessAlert("templateRule.edit.addSuccess");
            } else {
                setTimeout(() => setError(false), 6000);
                setError(true);
                throw new Error("Invalid Request")
            }
        })
}

const getRuleType = (isAbsent, lookIn) => {
    if (isAbsent === true) return ruleTypeAbsent;
    if (textReadingRules.includes(lookIn)) return ruleTypePdfText;
    if (locationReadingRules.includes(lookIn)) return ruleTypePdfLocation;
}

const getLocationCoords = (label, ruleType) => {
    if (ruleType && ruleType === ruleTypePdfLocation){
        const labelPageAndCoords = label.split(":");
        const coords = labelPageAndCoords[1].replaceAll({'[': '', ']': ''}).split(',');
        return {pageNo: labelPageAndCoords[0],
                left: coords[0],
                top: coords[1],
                width: coords[2],
                height: coords[3]}
    }
}

const TextRuleForm = ({values, errors, setFieldValue, handleChange, handleBlur, touched}) => {
    return  (
        <React.Fragment>
            <DropdownSelect label="templateRule.edit.ruleType" name={"position"}
                            options={textPosition}
                            onChange={(e) => {
                                setFieldValue("position", e.target.value);
                            }}
                            value={values.position} onBlur={handleBlur} errors={errors.position} touched={touched}
                            textDefault={"templateRule.edit.ruleTypeDefault"}/>
            <TextInput id="label"
                       label={"templateRule.edit.textLabel"}
                       placeholder={"templateRule.edit.textLabel"}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.label}
                       errors={errors.label}
                       touched={touched.label}/>
            <DropdownSelect label="templateRule.edit.fn" name={"fieldTransformFn"}
                            options={postProcessingFunctionsOpts}
                            onChange={(e) => {
                                setFieldValue("fieldTransformFn", e.target.value);
                            }}
                            value={values.fieldTransformFn} onBlur={handleBlur} errors={errors.fieldTransformFn} touched={touched}/>

            {!postProcessingFunctionHintsToIgnore.includes(values.fieldTransformFn) && <div>
                <StandardText text={"Function Description:"}/>
                <RegularText className={styles.preserveWhitespace}
                             text={`templateRule.edit.fn.hint.${values.fieldTransformFn}`}/>
            </div>}

            <DropdownSelect label="templateRule.edit.spaceStripper" name={"space"}
                            options={yesAndNoOpts}
                            onChange={(e) => {
                                setFieldValue("space", e.target.value);
                            }}
                            value={values.space} onBlur={handleBlur} errors={errors.space} touched={touched}/>
        </React.Fragment>
    )
}

const LocationRuleForm = ({values, errors, setFieldValue, handleChange, handleBlur, touched}) => {
    return  (
        <React.Fragment>
            <TextInput id="pageNo"
                       label={"templateRule.edit.pageNo"}
                       placeholder={"templateRule.edit.pageNo"}
                       type={"number"}
                       className={styles.smallText}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.pageNo}
                       errors={errors.pageNo}
                       touched={touched.pageNo}/>
            <TextInput id="left"
                       label={"templateRule.edit.left"}
                       placeholder={"templateRule.edit.left"}
                       type={"number"}
                       className={styles.smallText}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.left}
                       errors={errors.left}
                       touched={touched.left}/>
            <TextInput id="top"
                       label={"templateRule.edit.top"}
                       placeholder={"templateRule.edit.top"}
                       type={"number"}
                       className={styles.smallText}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.top}
                       errors={errors.top}
                       touched={touched.top}/>
            <TextInput id="width"
                       label={"templateRule.edit.width"}
                       placeholder={"templateRule.edit.width"}
                       type={"number"}
                       className={styles.smallText}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.width}
                       errors={errors.width}
                       touched={touched.width}/>
            <TextInput id="height"
                       label={"templateRule.edit.height"}
                       placeholder={"templateRule.edit.height"}
                       type={"number"}
                       className={styles.smallText}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.height}
                       errors={errors.height}
                       touched={touched.height}/>
            <DropdownSelect label="templateRule.edit.fn" name={"fieldTransformFn"}
                            options={postProcessingFunctionsOpts}
                            onChange={(e) => {
                                setFieldValue("fieldTransformFn", e.target.value);
                            }}
                            value={values.fieldTransformFn} onBlur={handleBlur} errors={errors.fieldTransformFn} touched={touched}/>

            {!postProcessingFunctionHintsToIgnore.includes(values.fieldTransformFn) && <div>
                <StandardText text={"Function Description:"}/>
                <RegularText className={styles.preserveWhitespace}
                             text={`templateRule.edit.fn.hint.${values.fieldTransformFn}`}/>
            </div>}

            <DropdownSelect label="templateRule.edit.spaceStripper" name={"space"}
                            options={yesAndNoOpts}
                            onChange={(e) => {
                                setFieldValue("space", e.target.value);
                            }}
                            value={values.space} onBlur={handleBlur} errors={errors.space} touched={touched}/>
        </React.Fragment>
    )
}

export const RuleForm = ({rule = {}, availableFields =[], isCreateRule=false, setShow, setSuccessAlert, onSuccess}) => {

    const [error, setError] = useState(false);

    const handleClose = () => {
        setError(false)
        setShow(false)
    };

    const validate = Yup.object().shape({
        isCreateRule: Yup.boolean(),
        fieldName: Yup.string().nullable().when('isCreateRule', {
           is: true,
           then: Yup.string().required("formModal.required"),
           otherwise: Yup.string().nullable().optional()
        }),
        type: Yup.string().required("formModal.required"),

        label: Yup.string().nullable().when('type', {
            is: ruleTypePdfText,
            then: Yup.string().required("formModal.required"),
            otherwise: Yup.string().nullable().optional()
        }),
        pageNo: Yup.number().when("type", {
            is: ruleTypePdfLocation,
            then: Yup.number().required("formModal.required"),
            otherwise: Yup.number().optional()
        }),
        left: Yup.number().when("type", {
            is: ruleTypePdfLocation,
            then: Yup.number().required("formModal.required"),
            otherwise: Yup.number().optional()
        }),
        top: Yup.number().when("type", {
            is: ruleTypePdfLocation,
            then: Yup.number().required("formModal.required"),
            otherwise: Yup.number().optional()
        }),
        width: Yup.number().when("type", {
            is: ruleTypePdfLocation,
            then: Yup.number().required("formModal.required"),
            otherwise: Yup.number().optional()
        }),
        height: Yup.number().when("type", {
            is: ruleTypePdfLocation,
            then: Yup.number().required("formModal.required"),
            otherwise: Yup.number().optional()
        })
    });

    const ruleType = getRuleType(rule.absent, rule.lookIn);
    const locationCoords = getLocationCoords(rule.label, ruleType);

    return (
        <Formik
            initialValues={{actorSystemBillId: rule.actorSystemBillId,
                            fieldName: (availableFields.length > 0 && availableFields[0][1]), isCreateRule: isCreateRule,
                            type: ruleType || ruleTypeAbsent, label: rule.label|| null,
                            position: rule.position || "0", fieldTransformFn: rule.fieldTransformFn || "none",
                            space: (rule.lookInOpts && rule.lookInOpts.pdfValueExtractor) ? 'yes' :'no',
                            ...locationCoords}}
            validationSchema={validate}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(true);
                if (isCreateRule === true){
                    addRule(values, setError, setSuccessAlert, handleClose)
                        .then(() => onSuccess())
                        .catch(() => {})
                        .finally(() => setSubmitting(false))
                } else {
                    updateRule(rule.id, values, setError, setSuccessAlert, handleClose)
                        .then(() => onSuccess())
                        .catch(() => {})
                        .finally(() => setSubmitting(false))
                }
            }}
        >
            {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                    {isCreateRule === true && <DropdownSelect label="templateRule.edit.fieldNameSelect" name={"fieldName"}
                                                              options={availableFields}
                                                              onChange={(e) => {
                                                                  setFieldValue("fieldName", e.target.value);
                                                              }}
                                                              value={values.fieldName} onBlur={handleBlur} errors={errors.fieldName} touched={touched}/>}
                    {isCreateRule === false && <RegularText text={"templateRule.edit.fieldName"} values={{name: rule.name}}/>}
                    <DropdownSelect label="templateRule.edit.ruleType" name={"type"}
                                    options={ruleTypeOpts}
                                    onChange={(e) => {
                                        setFieldValue("label", "");
                                        setFieldValue("type", e.target.value);
                                    }}
                                    value={values.type} onBlur={handleBlur} errors={errors.type} touched={touched}/>
                    {values.type === ruleTypePdfText &&
                        <TextRuleForm values={values} errors={errors} setFieldValue={setFieldValue}
                                      touched={touched} handleChange={handleChange} handleBlur={handleBlur}/>}
                    {values.type === ruleTypePdfLocation &&
                        <LocationRuleForm values={values} errors={errors} setFieldValue={setFieldValue}
                                          touched={touched} handleChange={handleChange} handleBlur={handleBlur}/>}

                    {error && <Alert variant={"danger"}><StandardText text={"templateRule.edit.error"}/></Alert>}

                    <Modal.Footer className={styles.buttons}>
                        <Button label={"accounts.convertModal.cancel"} variant={"secondary"}
                                type="button" onClick={handleClose}/>
                        <Button label={"templateRule.edit.modalSave"} variant={"primary"} loading={isSubmitting}
                                type="submit" className={styles.submitButton}/>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    )
};