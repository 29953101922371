import {Form} from "react-bootstrap";
import {useIntl} from "react-intl";
import React, {useState} from 'react';
import {StandardText} from "./index";
import styles from "./_SearchableList.module.scss";
const filterList = (list, filter) => {
    filter = filter.toLowerCase();
    return (
        list.map((element) => {
            if (element && element.toLowerCase().includes(filter)) {
                return element
            }
            return null;
    }))
}

const handleBlur = (setOpen, value, list, setFieldValue, e) => {
        if (!list.includes(value)) {
            setFieldValue("timezone", "");
        }
        setOpen(false);
}

const handleFocus = (setOpen) => {
    setOpen(true);
}

const SearchableList = ({id, label, onBlur, onChange, value = "", name, errors, touched, list, setFieldValue, onKeyPress}) => {
    const [open, setOpen] = useState(false);
    let filteredList = filterList(list, value);
    const intl = useIntl();

    return (
        <div>
            <Form.Group controlId={id}>
                {label && <b><Form.Label><StandardText text={label}/></Form.Label></b>}
                <Form.Control type="input"
                              autoComplete="off"
                              onChange={onChange}
                              onBlur={(e) => handleBlur(setOpen, value, list, setFieldValue, e)}
                              value={value}
                              name={name}
                              className={styles.input}
                              isInvalid={errors}
                              onFocus={() => handleFocus(setOpen)}
                              onKeyPress={onKeyPress}/>
                <Form.Control.Feedback type="invalid">
                    {typeof(errors) === "string" && <span className={styles.errors}>{intl.formatMessage({id: errors})}</span>}
                </Form.Control.Feedback>
            </Form.Group>
            {open && filteredList &&
                <div className={styles.dropdownList}>
                    {filteredList.map((li, index) => {
                        if (li) {
                            return (
                                <div className={styles.listElement} key={index}
                                    onMouseDown={(event) => {
                                    setFieldValue("timezone", li)
                                    setOpen(false)}}>
                                        {li}
                                </div>
                            )
                        }
                        return null;
                    })}
                </div>}
        </div>
    )
}

export default SearchableList