import styles from "./MailerSummary.module.scss";
import {Badge, Button, IconText} from "../../common";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router-dom";

const getMailerInfo = (id, setMailerInfo) => {
    if (id >= 0) {
        axios.post("/api/payreq-accounts/delivery-account-info", {accountId: id})
            .then(({data}) => {
                setMailerInfo(data)
            });
    }
};

const PaymentBadge = ({paymentStatus}) => {
    if (paymentStatus === "active" ) {
        return <Badge label={"accounts.summary.paymentsActive"} variant={"success"}/>
    } else if (paymentStatus === "configured" ) {
        return <Badge label={"accounts.summary.paymentsConfigured"} variant={"warning"}/>
    }
    return <Badge label={"accounts.summary.paymentsNotConfigured"} variant={"secondary"}/>
}

const MailerSummary = () => {

    const [mailerInfo, setMailerInfo] = useState([]);
    const history = useHistory();
    const id = parseInt(useParams().id);

    useEffect(() => getMailerInfo(id, setMailerInfo),
        [id])

    return (
        <div className={styles.summaryFlexBox}>
            <div className={styles.mailerSummaryBox}>
                <div className={styles.summaryRow}>
                    <div className={styles.summaryValPair} data-cy="printing">
                        <IconText text={"accounts.summary.printing"} icon={"print"}/>
                        <span className={styles.pairBadge} data-cy="printingSpan">
                            {(mailerInfo.printing) ?
                                <Badge label={"accounts.summary.enabled"} variant={"success"}/> :
                                <Badge label={"accounts.summary.disabled"} variant={"secondary"}/>}
                        </span>
                    </div>
                    <div className={styles.summaryValPair} data-cy="archive">
                        <IconText text={"accounts.summary.archive"} icon={"inventory_2"}/>
                        <span className={styles.pairBadge} data-cy="archiveSpan">
                            {(mailerInfo.archive) ?
                                <Badge label={"accounts.summary.enabled"} variant={"success"}/> :
                                <Badge label={"accounts.summary.disabled"} variant={"secondary"}/>}
                        </span>
                    </div>
                    <div className={styles.summaryValPair} data-cy="mandatory">
                        <IconText text={"accounts.summary.mandatory"} icon={"receipt_long"}/>
                        <span className={styles.pairBadge} data-cy="mandatorySpan">
                            {(mailerInfo.mandatory) ?
                                <Badge label={"accounts.summary.enabled"} variant={"success"}/> :
                                <Badge label={"accounts.summary.disabled"} variant={"secondary"}/>}
                        </span>
                    </div>
                </div>
                <div className={styles.summaryRow}>
                    <div className={styles.summaryValPair}>
                        <IconText text={"accounts.summary.payments"} icon={"credit_card"}/>
                        <span className={styles.pairBadge}>
                            <PaymentBadge paymentStatus={mailerInfo.payments} />
                        </span>
                    </div>
                    <div className={styles.summaryValPair}>
                        <IconText text={"accounts.summary.sso_saml"} icon={"login"}/>
                        <span className={styles.pairBadge}>
                            {mailerInfo.sso ?
                                <Badge label={"accounts.summary.enabled"} variant={"success"}/> :
                                <Badge label={"accounts.summary.disabled"} variant={"secondary"}/>}
                        </span>
                    </div>
                    <div className={styles.summaryValPair} data-cy="ignoreNonDigital">
                        <IconText text={"accounts.summary.ignore"} icon={"not_interested"}/>
                        <span className={styles.pairBadge} data-cy="ignoreSpan">
                            {mailerInfo.ignore ?
                                <Badge label={"accounts.summary.enabled"} variant={"success"}/> :
                                <Badge label={"accounts.summary.disabled"} variant={"secondary"}/>}
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <Button className={styles.button} icon={"edit"} variant={"link"} label={"accounts.summary.edit.header"} onClick={() => history.push(id + '/edit-mailer-summary')}/>
            </div>
        </div>
    );
}

export default MailerSummary;

