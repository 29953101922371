import styles from "./AuthFields.module.scss";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {IconText, SecondaryHeading} from "../../../../common";
import Loading from "../../../../Loading/Loading";


const getAuthFields = (id, setAuthFields, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/account-auth-fields", {params: {accountId: id}})
        .then(({data}) => {
            setAuthFields(data)
        })
        .finally(() => {
            setLoading(false);
        });
};

function fieldCleaner (authFields, selectedChannel) {
    let returnFields = [];
    const _ = require('lodash');
    for (let i = 1; i < 5; i++) {
        let fname = "authItem"+i+"Field";
        let contact, value = null;
        if (authFields[selectedChannel][_.camelCase(authFields[selectedChannel][fname]+"Field")]) {
            contact = authFields[selectedChannel][fname]+":";
            value = authFields[selectedChannel][_.camelCase(authFields[selectedChannel][fname]+"Field")];
        } else {
            contact = "";
            value = authFields[selectedChannel][fname];
        }
        returnFields.push({
            authField: "accounts.auth.fields." + i,
            contactField: contact,
            val: value,
            mandatory: (authFields[selectedChannel]["useAuthItem"+i]),
        });
    }
    return returnFields;
}

const AuthField = ({authFieldsList, fieldnum}) => {
    return (
        <div>
        {authFieldsList[fieldnum] !== undefined &&
                <div className={styles.contentDiv}>
                    <div className={styles.headerDiv} data-cy={"fieldtype" + fieldnum}>
                        <IconText className={styles.fieldHeader} text={authFieldsList[fieldnum].authField}/>
                        { authFieldsList[fieldnum].val &&
                            <>
                                { (authFieldsList[fieldnum].mandatory)
                                    ? <IconText className={styles.fieldHeaderOp} text={"accounts.auth.mandatory"}/>
                                    : <IconText className={styles.fieldHeaderOp} text={"accounts.auth.optional"}/>
                                }
                            </>
                        }
                    </div>
                    <div className={styles.fields}>
                        { authFieldsList[fieldnum].contactField &&
                            <div className={styles.fieldLabel}>{authFieldsList[fieldnum].contactField}</div>}
                        <div className={styles.fieldText} data-cy={"field" + fieldnum}>{authFieldsList[fieldnum].val}</div>
                    </div>
                </div>
        }
        </div>
    )

}

const AuthFields = ({selectedChannel}) => {

    const [authFields, setAuthFields] = useState([]);
    const [loading, setLoading] = useState(true);
    const id = parseInt(useParams().id);

    useEffect(() => getAuthFields(id, setAuthFields, setLoading),
        [id]);

    if (loading) return <Loading/>;
    if (authFields.length !== 0) {
        const authFieldsList = fieldCleaner(authFields, selectedChannel);
        return (
            <div className={styles.box}>
                <SecondaryHeading text={"accounts.auth.fields.header"}/>
                <div className={styles.authFieldsBox}>
                { authFieldsList.map((authField, i) => {
                    return <AuthField authFieldsList={authFieldsList} fieldnum={i} key={i}/>
                })}
                </div>
            </div>
        )
    } else {
        return null
    }

}

export default AuthFields;

