import DropdownModal from "../Modals/DropdownModal";
import React from "react";
import RegularText from "../common/_RegularText";


const ChangeInboxLimitModal = ({show, setShow, onYes, onNo, fields}) => {

    return <DropdownModal show={show} setShow={setShow} header={"specific.modal.inboxHeader"}
                          message={<RegularText text="specific.modal.inboxMessage"/>} negVariant={"secondary"}
                          affVariant={"primary"} onYes={onYes} onNo={onNo} negLabel={"specific.modal.buttonCancel"}
                          affLabel={"specific.modal.inboxButtonYes"}
                          fields={fields}/>;

};

export default ChangeInboxLimitModal;