import {Badge, IconText} from "../common";
import React from "react";

const RequestStatus = ({status}) => {
    if (status === "pending-approval") {
        return(<Badge variant="warning" label="ARHelpers.pending"/>);
    } else if (status === "approved") {
        return(<Badge variant="success" label="ARHelpers.approved"/>);
    } else if (status === "withdrawn") {
        return(<Badge variant="secondary" label="ARHelpers.withdrawn"/>);
    } else if (status === "denied") {
        return(<Badge variant="danger" label="ARHelpers.denied"/>);
    }
    return null;
}

const RequestAction = ({action}) => {
    if (action === "deleting-user") return <b><IconText text={"ARHelpers.deleteUser"} icon={"delete"}/></b>;
    if (action === "disabling-user-mfa") return <b><IconText text={"ARHelpers.disableMFA"} icon={"block"}/></b>;
    return null;
}

const UserIdLink= (text, className) => {
    return (
        <a href={`${window.location.origin}/users/${text}`} rel="noopener noreferrer" className={className}>
            {text}<span className={`${className} material-icons`}>open_in_new</span>
        </a>
    )
}

export {RequestStatus, RequestAction, UserIdLink}