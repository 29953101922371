import React from "react";
import styles from "./Table.module.scss";
import Pagination from "../../Pagination/Pagination";
import Table from 'react-bootstrap/Table'

const hiddenCheck = (col, hiddenCols) => {
    if (!hiddenCols.includes(col['accessor'])) {
        return col;
    }
};

const _Table = ({columnHeaders, hiddenCols = [], tableData, tableMeta, pageNumber, setPageNumber,
                    handleRowClick = () => {}, targetColumns = [], loading, intl}) => {
    const visibleCols = columnHeaders.filter(col => hiddenCheck(col, hiddenCols))
    return (
        <div>
            <Table>
                <thead>
                <tr>
                    {visibleCols.map((col, index) => (
                        <th key={index}>{col['Header']}</th>
                        ))}
                </tr>
                </thead>
                <tbody>
                {tableData.map((row, index) => (
                    <tr key={index} className={styles.tableRow} onClick={()=> handleRowClick(targetColumns.map(value => row[value]))}>
                        {visibleCols.map((col, index) => (
                            <td key={index}>{
                                (col['Cell'] && col['Cell'](row, col, intl)) || row[col['accessor']]
                            }</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>
            <Pagination meta={tableMeta} pageNumber={pageNumber} setPageNumber={setPageNumber} loading={loading}/>
        </div>);
};

export default _Table;