import React, {useEffect, useState} from "react";
import {
    Alert,
    Box,
    Button, Carousel,
    Check,
    DropdownSelect,
    SecondaryHeading,
    StandardPage,
    TextInput
} from "../../../common";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import styles from "./AddContact.module.scss";
import axios from "axios";
import StandardText from "../../../common/_StandardText";
import {useHistory, useParams} from "react-router-dom";
import {useAppState} from "../../../../state";
import {CONTACT_ADDED} from "../../../../state/reducers/alertReducer";

const getAccountMetaData = (accountId, setAccountMetaData, setLoading) => {
    setLoading(true);
    if (accountId >= 0) {
        axios.post("/api/payreq-accounts/account-info", {accountId})
            .then(({data}) => {
                setAccountMetaData(data);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        setLoading(false);
    }
};

const getAccountUsers = (accountId, setAccountUsers) => {
    if (accountId >= 0) {
        axios.post("/api/payreq-accounts/account-users-nopag", {accountId})
            .then(({data}) => {
                setAccountUsers(data.users.map((user) => [user.name, user]));
            });
    }
};

const addContact = (values, setFormError, dispatch, history) => {
    const { user: userString, ...other } = values;
    let userId = userString;
    if (userString !== '') {
        userId = JSON.parse(userString).id.toString();
    }

    return axios.post("/api/payreq-accounts/add-support-contact", { ...other, userId: userId })
        .then(({data}) => {
            if (data.contacts) {
                dispatch({type: CONTACT_ADDED})
                history.replace("/accounts/" + values.accountId)
            } else {
                setFormError(true);
            }
        })
};

const icons = [
    ["headset_mic"], ["call"], ["print"], ["computer"], ["payments"], ["mail"], ["notification_important"],
    ["manage_accounts"], ["work"], ["star"], ["alternate_email"], ["info"], ["priority_high"], ["vpn_key"], ["public"],
    ["warning"], ["workspace_premium"], ["flag"], ["anchor"], ["gavel"], ["military_tech"],
]

const AddContact = () => {
    const [, dispatch] = useAppState();
    const [formError, setFormError] = useState(false);
    const [accountMetaData, setAccountMetaData] = useState([]);
    const [accountUsers, setAccountUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const id = parseInt(useParams().id);
    const history = useHistory();

    useEffect(() => getAccountMetaData(id, setAccountMetaData, setLoading),
        [id]);

    useEffect(() => getAccountUsers(id, setAccountUsers),
        [id]);

    const validateForm = Yup.object().shape({
        name: Yup.string()
            .required("formModal.required"),
        label: Yup.string()
            .required("formModal.required"),
        icon: Yup.string()
            .required("formModal.required"),
        email: Yup.string().email().when('phone', {
            is: (phone) => !phone || phone.length === 0,
            then: Yup.string().email().required("accounts.contacts.form.emailRequired"),
            otherwise: Yup.string().optional()
        }),
        phone: Yup.number().when('email', {
            is: (email) => !email || email.length === 0,
            then: Yup.number()
                .typeError("accounts.contacts.form.invalidNumber")
                .positive("accounts.contacts.form.negativePhoneNumber")
                .integer("accounts.contacts.form.decimalPhoneNumber")
                .required("accounts.contacts.form.phoneRequired"),
            otherwise: Yup.number().optional()
        }),
    },
        [ [ 'email', 'phone' ] ]
    );

    return <StandardPage header={"accounts.primaryHeader"} values={{header: accountMetaData.tagName}}
                         sub={"accounts.contacts.sub"} loading={loading} parentPath={"/accounts/" + id}>
        <Box>
            <SecondaryHeading text={"accounts.contacts.detailsHeader"}/>
            <Formik
                initialValues={{accountId: id,  customerContact: false, name: '', email: '', phone: '', label: '', icon: icons[0][0], user: ''}}
                validationSchema={validateForm}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(true);
                    addContact(values, setFormError, dispatch, history)
                        .finally(() => setSubmitting(false))
                }}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <span className={styles.formRow}>
                            <span className={styles.contactIcon}>
                                <b><StandardText text={"accounts.contacts.iconLabel"}/></b>
                                <Carousel items={icons} setCurrent={(index) => setFieldValue("icon", icons[index][0])}/>
                            </span>
                            <span className={styles.contactLabel}>
                            <TextInput id="label"
                                       label={"accounts.contacts.positionLabel"}
                                       placeholder={"accounts.contacts.positionPlaceholder"}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       value={values.label}
                                       errors={errors.label}
                                       touched={touched.label}/>
                            </span>
                        </span>
                        <DropdownSelect label={"accounts.contacts.selectExistingUserLabel"} name={`user`} options={accountUsers}
                                        textDefault={"accounts.contacts.selectExistingUserDefault"}
                                        onChange={(e) => {
                                            const user = JSON.parse(e.target.value);
                                            setFieldValue("name", user.name);
                                            setFieldValue("email", user.email);
                                            if (user.phone) { setFieldValue("phone", user.phone); }
                                            handleChange(e)
                                        }}
                                        value={values.user} onBlur={handleBlur}
                                        errors={errors.user} touched={touched.user}/>
                        <span className={styles.orText}><b><StandardText text={"accounts.contacts.form.or"}/></b></span>
                        <hr/>
                        <span className={styles.formRow}>
                            <span className={styles.userName}>
                                <TextInput id="name"
                                           label={"accounts.contacts.form.name"}
                                           placeholder={"accounts.contacts.form.namePlaceholder"}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.name}
                                           errors={errors.name}
                                           touched={touched.name}/>
                            </span>
                            <span className={styles.userEmail}>
                                <TextInput id="email"
                                           label={"accounts.contacts.form.email"}
                                           placeholder={"accounts.contacts.form.emailPlaceholder"}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.email}
                                           errors={errors.email}
                                           touched={touched.email}/>
                            </span>
                        </span>
                        <TextInput id="phone"
                                   label={"accounts.contacts.form.phone"}
                                   placeholder={"accounts.contacts.form.phonePlaceholder"}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.phone}
                                   errors={errors.phone}
                                   touched={touched.phone}/>
                        <Check id="customerContact" setFieldValue={setFieldValue} options={[{
                            label: <StandardText text={"accounts.contacts.form.createShared"}/>,
                            val: "customerContact"
                        }]}/>
                        {formError && <Alert variant={"danger"}><StandardText text={"accounts.modal.ChangeNameError"}/></Alert>}
                        <span className={styles.formButtons}>
                            <Button label={"accounts.contacts.addContact"} variant={"primary"} loading={isSubmitting}
                                    type="submit" className={styles.submitButton}/>
                            <Button label={"accounts.contacts.cancel"} variant={"secondary"} onClick={() => history.push("/accounts/" + id)} type="button"/>
                        </span>
                    </Form>
                )}
            </Formik>
        </Box>
    </StandardPage>
};
export default AddContact;