export const CONTACT_ADDED = "CONTACT_ADDED";
export const CONTACT_REMOVED = "CONTACT_REMOVED";
export const ALERT_CLOSED = "ALERT_CLOSED";
export const NOTE_ADDED = "NOTE_ADDED";
export const NOTE_EDITED = "NOTE_EDITED";
export const NOTE_REMOVED = "NOTE_REMOVED";
export const INBOX_CONVERTED_BUSINESS = "INBOX_CONVERTED_BUSINESS";
export const INBOX_CONVERTED_PERSONAL = "INBOX_CONVERTED_PERSONAL";

const alertReducer = (state, action) => {
    switch (action.type) {
        case CONTACT_ADDED:
            return {variant: "success", text: "accounts.contacts.success"};
        case CONTACT_REMOVED:
            return {variant: "success", text: "accounts.contacts.successRemove"};
        case NOTE_ADDED:
            return {variant: "success", text: "accounts.notes.successAdd"};
        case NOTE_EDITED:
            return {variant: "success", text: "accounts.notes.successEdit"};
        case NOTE_REMOVED:
            return {variant: "success", text: "accounts.notes.successRemove"};
        case INBOX_CONVERTED_BUSINESS:
            return {variant: "success", text: "accounts.convert.businessSuccess"};
        case INBOX_CONVERTED_PERSONAL:
            return {variant: "success", text: "accounts.convert.personalSuccess"};
        case ALERT_CLOSED:
            return null;
        default:
            return state;
    }
};

export default alertReducer;