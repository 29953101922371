import {Button} from "../common";
import styles from "./PaginatedList.module.scss"
import React from "react";
import Pagination from "../Pagination/Pagination";
import ErrorMessage from "../ErrorMessage";
import Loading from "../Loading/Loading";
import RegularText from "../common/_RegularText";

const PaginatedList = ({listData, listMeta, handleInfo = () => {}, handleRemove, pageNumber, setPageNumber, history, setAccountUserId,
                      setShowDeleteModal, deleteDisabled, emptyMessage, loading}) => {
    if (loading) return <Loading message={"loading.paginatedList"}/>;
    if (!listData || listData.length < 1) return <div className={styles.listStyle}><ErrorMessage><RegularText text={emptyMessage} className={styles.rowPadding}/></ErrorMessage></div>;
    return (
        <div className={styles.listStyle}>
            {{listData}['listData'].map((user, index) =>
                <div className={styles.rowStyle} onClick={() => handleInfo(user['id'], history)} key={index}>
                    <div className={styles.labelStyle}>
                        {user['name'] + ' (' + user['email'] + ') '}
                    </div>
                    <div className={styles.buttonStyle} style={{display: (deleteDisabled) ? "none" : ""}}>
                        <Button icon={"clear"}
                                onClick={e => {
                                    handleRemove(user['id'], setAccountUserId, setShowDeleteModal)
                                    e.stopPropagation();
                                }} variant={"link"} size={"sm"}/>
                    </div>

                </div>
            )}
            {listMeta && <Pagination meta={listMeta} pageNumber={pageNumber} setPageNumber={setPageNumber}/>}
        </div>
    );
};

export default PaginatedList;