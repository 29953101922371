import {Form, Formik} from "formik";
import {TextInput} from "../common";
import React from "react";

export const SearchBar = ({setPageNumber, searchTerm, setSearchTerm = () => {}, setSearched}) => {
    return (
        <React.Fragment>
            <Formik
                initialValues={{search: searchTerm, pageNumber: 0}}
                onSubmit={(values) => {
                    setSearchTerm(values['search']);
                    setPageNumber(0);
                    setSearched(true);
                }}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                  }) => (

                    <Form onSubmit={handleSubmit}>
                        <label htmlFor="search"></label>
                        <TextInput id="search"
                                   placeholder={"searchBar.placeholder"}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.search}
                        />
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};