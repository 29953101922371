import {Form} from "react-bootstrap";
import {useIntl} from "react-intl";
import React from 'react';
import {StandardText} from "./index";
import styles from "./_DropdownSelect.module.scss"

const Option = ({optVal, labelFn}) => {
    const intl = useIntl();
    if (typeof(optVal) === "number" || optVal.length === 1)
        return <option>{optVal}</option>;
    if (optVal.length === 2) {
        if (typeof(optVal[1]) === "object")
            return <option value={JSON.stringify(optVal[1])}>{optVal[0]}</option>;
        if (typeof(optVal[1]) === "function")
            return <option value={optVal[0]}>{optVal[1](intl, optVal[0])}</option>;
        return <option value={optVal[1]}>{labelFn ? labelFn(optVal[0]) : intl.formatMessage({id: optVal[0]})}</option>;
    }
};

const _DropdownSelect = ({id, label, hint, onBlur, onChange, value, name, errors, touched, options, defaultValue, textDefault, labelFn, className}) => {
    const intl = useIntl();
    return (
        <Form.Group className={className + " " + styles.dropdown} controlId={id}>
            {label && <b><Form.Label><StandardText text={label}/></Form.Label></b>}
            <Form.Control as="select"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          isInvalid={touched && !!errors} name={name}
                          className={styles.option}>
                {defaultValue && <option key='blankChoice' hidden value>{defaultValue}</option>}
                {textDefault && <option key='blankChoice' hidden value>{intl.formatMessage({id: textDefault})}</option>}
                {options.map((option, index) => (
                    <Option optVal={option} key={index} labelFn={labelFn}/>
                ))}
            </Form.Control>
            {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
            <Form.Control.Feedback type="invalid">
                {typeof(errors) === "string" && <p>{intl.formatMessage({id: errors})}</p>}
            </Form.Control.Feedback>
        </Form.Group>
    )
};

export default _DropdownSelect;