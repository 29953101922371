import React from "react";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import styles from "./_PrimaryHeading.module.scss"

const _PrimaryHeading = ({text, className, values, sub, intl}) => (
    <h1 id="pageHeading" className={"page-heading " + className}>{intl.formatMessage({id: text}, values)}
        {sub && <span className={styles.subHeading}>{intl.formatMessage({id: sub})}</span>}
    </h1>
);

_PrimaryHeading.propTypes = {
    text: PropTypes.string,
    values: PropTypes.object
};

export default injectIntl(_PrimaryHeading);