import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import styles from "./SupportContacts.module.scss";
import {Button} from "../../common";
import ContactList from "./ContactList/ContactList";

const getSupportContacts = (id, setCustomerContacts, setAccountContacts, setCustomerContactMeta, setAccountContactMeta,
                            accPageNumber, custPageNumber, setLoading) => {
    if (id >= 0) {
        setLoading(true);
        axios.post("/api/payreq-accounts/support-contacts", {accountId: id, accPageNumber: accPageNumber, custPageNumber: custPageNumber})
            .then(({data}) => {
                setCustomerContacts(data.customer.contacts);
                setAccountContacts(data.accounts.contacts);
                setCustomerContactMeta(data.customer.meta);
                setAccountContactMeta(data.accounts.meta);
            }).finally(() => setLoading(false))
    }
};

const SupportContacts = () => {

    const [customerContacts, setCustomerContacts] = useState([]);
    const [accountContacts, setAccountContacts] = useState([]);
    const [customerContactMeta, setCustomerContactMeta] = useState([]);
    const [accountContactMeta, setAccountContactMeta] = useState([]);
    const [custPageNumber, setCustPageNumber] = useState(0);
    const [accPageNumber, setAccPageNumber] = useState(0);
    const [loading, setLoading] = useState(false);

    const id = parseInt(useParams().id);

    const history = useHistory();

    const handleChange = useCallback(() => getSupportContacts(id, setCustomerContacts, setAccountContacts, setCustomerContactMeta,
            setAccountContactMeta, accPageNumber, custPageNumber, setLoading),
        [id, accPageNumber, custPageNumber])

    useEffect(() => getSupportContacts(id, setCustomerContacts, setAccountContacts, setCustomerContactMeta,
            setAccountContactMeta, accPageNumber, custPageNumber, setLoading),
        [id, accPageNumber, custPageNumber])

    return (
        <div>
            <div className={styles.contacts}>
                {customerContacts.length > 0 &&
                <span>
                    <ContactList contactData={customerContacts} contactMeta={customerContactMeta}
                                 emptyMessage={"accounts.contacts.noSharedContacts"} loading={loading} pageNumber={custPageNumber}
                                 setPageNumber={setCustPageNumber} handleChange={handleChange}/>
                    <hr/>
                </span>}
                <ContactList contactData={accountContacts} contactMeta={accountContactMeta}
                             emptyMessage={"accounts.contacts.noAccountContacts"} loading={loading}
                             pageNumber={accPageNumber} setPageNumber={setAccPageNumber} handleChange={handleChange}/>
            </div>
            <span className={styles.addContact}>
                <Button icon={"person_add"} variant={"link"} label={"accounts.contacts.addContact"} onClick={() => history.push(id + '/add-contact')}/>
            </span>
        </div>
    );
}

export default SupportContacts;