import {Form} from "react-bootstrap";
import {useIntl} from "react-intl";
import React from 'react';
import {StandardText} from "./index";
import styles from "./_DatePicker.module.scss"

const _DatePicker = ({id, label, hint, onBlur, onChange, value = "", name, errors, touched, defaultDate = ""}) => {
    const intl = useIntl();
    return (
        <Form.Group controlId={id}>
            {label && <b><Form.Label><StandardText text={label}/></Form.Label></b>}
            <Form.Control type="date"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={(value) ? value : defaultDate}
                          isInvalid={touched && !!errors} name={name}
                          className={styles.input}/>
            {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
            <Form.Control.Feedback type="invalid">
                {typeof(errors) === "string" && <p>{intl.formatMessage({id: errors})}</p>}
            </Form.Control.Feedback>
        </Form.Group>
    )
};

export default _DatePicker;