import React, {useState} from "react";
import {Alert, Button, Check, Modal, TextInput} from "../common";
import styles from "../common/_Modal/_Modal.module.scss";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import RegularText from "../common/_RegularText";
import StandardText from "../common/_StandardText";

const removeUser = (id, ticketNumber, accounts, setRemoveAlertShow, setFormError, handleClose) => {

    return axios.post("/api/approvals/create-request", {
        action: "deleting-user",
        reason: ticketNumber,
        details: {id: id, ticketNumber: ticketNumber, accounts: accounts},
        attribute: "id",
        value: id.toString()})
        .then(({data}) => {
            if (data.requestExists) {
                return setFormError(true);
            } else {
                handleClose();
                setTimeout(() => setRemoveAlertShow(false), 6000);
                setRemoveAlertShow(true);
            }
        })
};

const DeleteUserModal = ({show, setShow, setRemoveAlertShow, id, userAccounts}) => {

    const [formError, setFormError] = useState(false);

    const handleClose = () => {
        setFormError(false)
        setShow(false)
    };

    const validateBox = Yup.object().shape({
        ticket: Yup.string()
            .required("formModal.required")
    })

    return <Modal header={"specific.modal.deleteHeader"} show={show} setShow={setShow} handleClose={handleClose}>
        <Formik
            initialValues={{ticket: ''}}
            validationSchema={validateBox}
            onSubmit={(values, {setSubmitting}) => {
                const accountsToDelete = Object.keys(values).filter(val => val !== "ticket" && values[val]);
                setSubmitting(true);
                removeUser(id, values.ticket, accountsToDelete, setRemoveAlertShow, setFormError, handleClose)
                    .finally(() => setSubmitting(false))
            }}
        >
            {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  setFieldValue,
                  isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                    <b><label htmlFor="ticket">{<RegularText text={"specific.modal.deleteMessage"}/>}</label></b>
                    <TextInput id="ticket"
                               placeholder={"specific.modal.messagePlaceholder"}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               value={values.ticket}
                               errors={errors.ticket}
                               touched={touched.ticket}/>
                    {(userAccounts.length > 0) && <b><label htmlFor="checkBox">
                        <RegularText text={"specific.modal.accDeleteMessage"}/></label></b>}
                    <Check options={userAccounts} id={"checkbox"} setFieldValue={setFieldValue}/>
                    {formError && <Alert variant={"danger"}><StandardText text={"specific.modal.userAlreadyScheduled"}/></Alert>}
                    <Modal.Footer>
                        <Button label={"specific.modal.buttonCancel"} variant={"secondary"} onClick={handleClose} type="button"/>
                        <Button label={"specific.modal.buttonDeleteUser"} variant={"danger"} loading={isSubmitting}
                                type="submit" className={styles.submitButton}/>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    </Modal>;
};
export {DeleteUserModal};