import {Navbar, Nav, NavDropdown, Container} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import {useAppState} from "../../state";
import {SET_USER} from "../../state/reducers/userReducer";
import {getCsrfToken} from "../Login/Login";
import React from "react";
import styles from "./Nav.module.scss"
import Image from 'react-bootstrap/Image'
import logo from "../../resources/Images/Atlas Logo - Huge.svg"
import {IconText} from "../common";
import StandardText from "../common/_StandardText";

const logout = (dispatch, history) => {
    axios.post("/api/logout").then(
        ({data: {success}}) => {
            if (success) {
                dispatch({
                    type: SET_USER,
                    user: {isAuthenticated: false}
                })
                getCsrfToken(() => {})
                history.push("/login")
            }
        }
    );
}

// React functional component
const PayreqNavbar = ({isAuthenticated}) => {
    const [, dispatch] = useAppState();
    const history = useHistory();

    if (!isAuthenticated) return null;
    return (
        <React.Fragment>
            <div className={styles.nav}>
                <Container fluid="md">
                    <Navbar>
                        <Navbar.Brand as={Link} to="/"><Image className={styles.logo} src={logo} fluid={true}/></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link className={styles.navComponent} as={Link} to="/users"><IconText text="nav.users" icon={"people_alt"}/></Nav.Link>
                                <Nav.Link className={styles.navComponent} as={Link} to="/accounts"><IconText text="nav.mailers" icon={"mark_as_unread"}/></Nav.Link>
                                <Nav.Link className={styles.navComponent} as={Link} to="/approval-requests"><IconText text="nav.approvalRequests" icon={"add_task"}/></Nav.Link>
                                <Nav.Link className={styles.navComponent} as={Link} to="/integrators"><IconText text="nav.integrators" icon={"mediation"}/></Nav.Link>
                            </Nav>
                            <NavDropdown id="basic-nav-dropdown" title={<IconText icon={"settings"} text="nav.settings" aria={"Settings"} className={styles.navDrop}/>} className={styles.dropDown + " " + styles.dropdownToggle}>
                                <NavDropdown.Item as={Link} to="/change-password"><StandardText text="nav.changePassword"/></NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => logout(dispatch, history)}><StandardText text="nav.logout"/></NavDropdown.Item>
                            </NavDropdown>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default PayreqNavbar;