
export const canadaTypes = ['paystatement', 'payt4'];

export const getCreditType = (typeName) => {
    if (canadaTypes.includes(typeName)) {
        return typeName;
    }
    return "credit";
}

export const getCreditTypeLabel = (typeName) => {
    return 'accounts.templates.type.' + getCreditType(typeName);
}

export const textReadingRules = [1, 2, 3, 4];
export const locationReadingRules = [5];

export const ruleTypeAbsent = "absent";
export const ruleTypePdfLocation = "location"
export const ruleTypePdfText = "text";