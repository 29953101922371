import {Alert, PrimaryHeading, StandardText} from "../index";
import React from "react";
import ButtonCluster from "../../ButtonCluster/ButtonCluster";
import Loading from "../../Loading/Loading";
import {useAppState} from "../../../state";


const _StandardPage = ({header, sub, values, buttons, loading, children}) => {

    const [state] = useAppState();

    if (loading) return <Loading/>;

    return (
        <React.Fragment>
            {state.alert && <Alert variant={state.alert.variant}><StandardText text={state.alert.text}/></Alert>}
            <div>
                <PrimaryHeading text={header} values={values} sub={sub}/>
            </div>
            {buttons && <ButtonCluster buttons={buttons}/>}
            {children}
        </React.Fragment>
    );
}

export default _StandardPage;