import React from "react";
import styles from "./Loading.module.scss"
import LoadingSpinner from "./LoadingSpinner";
import StandardText from "../common/_StandardText";

const Loading = ({message = "loading.loadingMessage"}) => {
    return (
        <div className={styles.loading}>
            <div className={styles.message}><StandardText text={message}/></div>
            <LoadingSpinner/>
        </div>
    );
};

export default Loading;