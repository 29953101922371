import {Alert} from "react-bootstrap";
import {useCallback, useEffect, useState} from "react";
import React from 'react';
import {useAppState} from "../../state";
import {ALERT_CLOSED} from "../../state/reducers/alertReducer";

const _Alert = ({variant = "info", dismissible = true, children, timed = true, ...rest}) => {
    const [show, setShow] = useState(true);
    const [, dispatch] = useAppState();

    const handleClose = useCallback(() => {
        setShow(false)
        dispatch({type: ALERT_CLOSED})
    }, [setShow, dispatch])

    useEffect((timed) => {
        if (timed) {
            const timeout = setTimeout(handleClose, 6000)
            return () => {clearTimeout(timeout)};
        }
    }, [handleClose])

    if (!show) return null;
    return (
        <Alert variant={variant} dismissible={dismissible} onClose={handleClose} {...rest}>{children}</Alert>
    )
}

export default _Alert;