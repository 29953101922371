import React from "react";
import {Button, Modal, TextInput} from "../../../common";
import styles from "./CopyTemplateModal.module.scss";
import axios from "axios";
import {Form, Formik} from "formik";
import {useIntl} from "react-intl";
import RegularText from "../../../common/_RegularText";

const copyTemplate = (id, templateId, name, setSuccessAlert, handleClose) => {
    return axios.post("/api/templates/copy", {
        id: parseInt(id),
        templateId: templateId,
        name: name
    })
        .then(({data}) => {
            if (data.success) {
                handleClose()
                setTimeout(() => setSuccessAlert(), 6000);
                setSuccessAlert("template.copy.success");
            }
        })
}


export const CopyTemplateModal = ({id, templateId, name, show, setShow, setSuccessAlert, handleChange}) => {

    const handleClose = () => setShow(false);
    const intl = useIntl();

    return (
    <Modal header={"template.copy.header"} show={show} handleClose={handleClose}>
        <Formik
            initialValues={{name: ''}}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(true);
                copyTemplate(id, templateId, values.name, setSuccessAlert, handleClose)
                    .then(() => handleChange())
                    .finally(() => setSubmitting(false))
            }}
        >
            {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                    <RegularText className={styles.existing} children={intl.formatMessage({id: "template.copy.existing"}) + name}/>
                    <TextInput id="name"
                               label={"template.copy.name"}
                               placeholder={"template.copy.name.placeholder"}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               value={values.name}
                               errors={errors.name}
                               touched={touched.name}/>
                    <Modal.Footer>
                        <Button label={"accounts.convertModal.cancel"} variant={"secondary"} onClick={handleClose} type="button"/>
                        <Button label={"template.copy.action"} variant={"primary"} loading={isSubmitting}
                                type="submit" className={styles.submitButton}/>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    </Modal>)
};