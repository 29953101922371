import React from "react";
import {FormattedMessage} from "react-intl";
import ButtonCluster from "../ButtonCluster/ButtonCluster";
import styles from "./Pagination.module.scss"
import Loading from "../Loading/Loading";

const EmptyPagination = () => (
    <div className={styles.paginationMeta}>
            <p>
            <FormattedMessage id="pagination.showing"/>{' '}
                <strong>
                {'0'} <FormattedMessage id="pagination.to"/> {'0'}
                    </strong>
                {' '}<FormattedMessage id="pagination.outOf"/>{' '}
                <strong>
                {'0'}
                    </strong>
                {' '}
            </p>
    </div>
);

const LoadingInfo = () => {
    return (
        <span className={styles.paginationLoading}>
            <Loading/>
        </span>
    );
}

const PaginationInfo = ({meta}) => {
    if (!meta || meta.length === 0) return <EmptyPagination/>
    return(
        <div className={styles.paginationMeta}>
            <p>
            <FormattedMessage id="pagination.showing"/>{' '}
                <strong>
            {meta['showing'][0]} <FormattedMessage id="pagination.to"/> {meta['showing'][1]}
                    </strong>
                {' '}<FormattedMessage id="pagination.outOf"/>{' '}
                <strong>
            {meta['total']['count']}
                    </strong>
                {' '}
                </p>
        </div>
    )};

function singlePage(meta) {
    return (meta['showing'] && meta['showing'][0] === 1 && meta['showing'][1] === meta['total']['count']);
}

const Pagination = ({meta, pageNumber, setPageNumber, className, loading}) => {
    let maxPage;
    if (!meta || meta.length === 0) {
        maxPage = 0;
    } else {
        maxPage = meta['maxPage'];
    }
    const canBack = (pageNumber > 0);
    const canForward = (pageNumber < maxPage);
    if (singlePage(meta)) return null;
    return (
        <div className={styles.pagination + " " + className}>
            <ButtonCluster buttons={[
                {icon: "first_page",
                    onClick: () => {setPageNumber(0)},
                    disabled: !canBack,
                },
                {icon: "chevron_left",
                    onClick: () => {setPageNumber(pageNumber => pageNumber - 1)},
                    disabled: !canBack,
                },
                {icon: "chevron_right",
                    onClick: () => {setPageNumber(pageNumber => pageNumber + 1)},
                    disabled: !canForward,
                },
                {icon: "last_page",
                    onClick: () => {setPageNumber(maxPage)},
                    disabled: !canForward,
                },
            ]}/>
            {!loading && <PaginationInfo meta={meta}/>}
            {loading && <LoadingInfo/>}
        </div>
    );
};

export default Pagination;