import React, {useEffect, useState} from "react";
import {Alert, Button, DatePicker, DropdownSelect, Modal, StandardText} from "../common";
import styles from "../Modals/DropdownModal.module.scss";
import {Form, Formik} from "formik";
import axios from "axios";
import * as Yup from "yup";
import {parseUTCDateString} from "../Helpers/DateTimeHelpers";

const getCreditPacks = (id, setCreditData, setLoading) => {
    setLoading(true);
    if (id >= 0) {
        axios.post("/api/credits/credit-packs-nopag", {accountId: id})
            .then(({data}) => {
                setCreditData(data.creditPacks);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        setLoading(false);
    }
}

const changeExpiry = (accountId, packId, expiry, setError, setSuccessAlert, handleClose) => {
    return axios.post("/api/credits/change-expiry", {accountId: accountId, packId: parseInt(packId), expiry: parseUTCDateString(expiry)})
        .then(({data}) => {
            if (data.success) {
                handleClose();
                setTimeout(() => setSuccessAlert(), 6000);
                setSuccessAlert("credits.changeExpiry.success");
            } else {
                setTimeout(() => setError(false), 6000);
                setError(true);
                throw new Error("Invalid Request")
            }
        })
}

const getPackExpiry = (creditPacks, packId) => {
    if (packId) {
        const currentPack = creditPacks.find(pack => pack.id === parseInt(packId))
        if (currentPack) { return currentPack.expiry.split("T")[0]; }
    }
    return "";
}

const ChangeCreditExpiryModal = ({show, setShow, accountId, setSuccessAlert, packPrefill, setPackPrefill, onSuccess}) => {

    const [creditPacks, setCreditPacks] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => getCreditPacks(accountId, setCreditPacks, setLoading),
        [accountId]);

    const handleClose = () => {
        setShow(false)
        setPackPrefill("");
    };

    const yesterday = new Date(Date.now() -86400000);


    const prefilledExpiry = getPackExpiry(creditPacks, packPrefill);

    const validate = Yup.object().shape({
        pack: Yup.string()
            .required("formModal.required"),
        expiry: Yup.date()
            .transform((dateString) => new Date(dateString))
            .min(yesterday, "credits.modal.pastExpiry")
            .required("formModal.required")
    })

    return (
        <Modal header={"credits.modal.changeExpiryHeader"} show={show} handleClose={handleClose}>
            <Formik
                initialValues={{pack: packPrefill, expiry: prefilledExpiry}}
                validationSchema={validate}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(true);
                    changeExpiry(accountId, values.pack, values.expiry, setError, setSuccessAlert, handleClose)
                        .then(() => onSuccess())
                        .catch(() => {})
                        .finally(() => setSubmitting(false))
                }}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <DropdownSelect label="credits.modal.changeExpirySelectPack" name={"pack"}
                                        options={creditPacks.map((option) => [`${option.id} - ${option.name}`, option.id])}
                                        onChange={(e) => {
                                            setFieldValue("pack", e.target.value);
                                            setFieldValue("expiry", getPackExpiry(creditPacks, e.target.value));
                                            handleChange(e)
                                        }}
                                        value={values.pack} onBlur={handleBlur} errors={errors.pack} touched={touched}
                                        textDefault={"credits.modal.changeExpirySelectPackDefault"}/>
                        <DatePicker label={"credits.modal.changeExpirySelectExpiry"} name={"expiry"}
                                    onChange={handleChange} touched={touched} onBlue={handleBlur} errors={errors.expiry}
                                    value={values.expiry}/>
                        {error && <Alert variant={"danger"}><StandardText text={"credits.modal.error"}/></Alert>}
                        <Modal.Footer className={styles.buttons}>
                            <Button label={"credits.modal.cancel"} variant={"secondary"} onClick={handleClose} type="button"/>
                            <Button label={"credits.modal.changeExpirySave"} variant={"primary"} type={"submit"}
                                    loading={isSubmitting || loading} className={styles.submitButton}/>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ChangeCreditExpiryModal;