import styles from "./ActiveChannels.module.scss";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import ReTab from "../../../../VerticalTabbedForm/VerticalTabbedForm"
import {Button, RegularText, SecondaryHeading, StandardText} from "../../../../common";
import {valueParse} from "../../../../../utils/display-helper-utils";
import Loading from "../../../../Loading/Loading";
import {useIntl} from "react-intl";

const getActiveChannels = (id, setActiveChannels, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/account-active-channels", {params: {accountId: id}})
        .then(({data}) => {
            setActiveChannels(data)
        })
        .finally(() => {
            setLoading(false);
        });
};

function mapTabContents(activeChannels, content, intl) {

    let channelNames = {
        "bpv": intl.formatMessage({id: "accounts.active.bpv"}),
        "email": intl.formatMessage(({id:"accounts.active.email"})),
        "mybillsagent" : intl.formatMessage({id:"accounts.active.mybillsagent"}),
        "xeroconnect" : intl.formatMessage({id:"accounts.active.xeroconnect"}),
        "myob" : intl.formatMessage({id:"accounts.active.myob"}),
        "reckon": intl.formatMessage({id:"accounts.active.reckon"}),
        "mybills": intl.formatMessage(({id: "accounts.active.mybills"})),
        "einvoicing": intl.formatMessage(({id: "accounts.active.einvoicing"})),
        "archive": intl.formatMessage(({id: "accounts.active.archive"})),
        "mastercard-bpx": intl.formatMessage(({id: "accounts.active.mastercard-bpx"})),
        "mybills-bills": intl.formatMessage(({id: "accounts.active.mybills-bills"})),
        "epost": intl.formatMessage(({id: "accounts.active.epost"})),
    }


    content = activeChannels.map((element, i) => {
        return ({
            uid: i,
            tab: channelNames[element.channelPartnerSystemId],
            panel: (
                <div>
                    <h3>{channelNames[element.channelPartnerSystemId]}</h3>
                    <dl className={styles.horizontalDefinitionList}>
                        <dt className={styles.label}><StandardText text={"accounts.active.channel.id"} /></dt>
                        <dd><RegularText dataCy="contactId">{valueParse(element.registrationContactIdField)}</RegularText></dd>
                        <dt className={styles.label}><StandardText text={"accounts.active.channel.help"}/></dt>
                        <dd><RegularText dataCy="contactIdHelp">{valueParse(element.registrationContactIdHelp)}</RegularText></dd>
                        <dt className={styles.label}><StandardText text={"accounts.active.field.1"} /></dt>
                        <dd><RegularText dataCy="field1">{valueParse(element.authItem1Field)}</RegularText></dd>
                        <dt className={styles.label}><StandardText text={"accounts.active.field.1-help"}/></dt>
                        <dd><RegularText dataCy="field1help">{valueParse(element.authItem1Help)}</RegularText></dd>
                        <dt className={styles.label}><StandardText text={"accounts.active.field.2"}/></dt>
                        <dd><RegularText dataCy="field2">{valueParse(element.authItem2Field)}</RegularText></dd>
                        <dt className={styles.label}><StandardText text={"accounts.active.field.2-help"}/></dt>
                        <dd><RegularText dataCy="field2help">{valueParse(element.authItem2Help)}</RegularText></dd>
                        <dt className={styles.label}><StandardText text={"accounts.active.field.3"}/></dt>
                        <dd><RegularText dataCy="field3">{valueParse(element.authItem3Field)}</RegularText></dd>
                        <dt className={styles.label}><StandardText text={"accounts.active.field.3-help"}/></dt>
                        <dd><RegularText dataCy="field3help">{valueParse(element.authItem3Help)}</RegularText></dd>
                        <dt className={styles.label}><StandardText text={"accounts.active.mybills.switch"}/></dt>
                        <dd><RegularText dataCy="mybills">{valueParse(element.showInMybills)}</RegularText></dd>
                    </dl>
                </div>
            )
        });
    })
    return content;
}

const ActiveChannels = ({selectedChannel, setSelectedChannel}) => {
    const [activeChannels, setActiveChannels] = useState([]);
    const [loading, setLoading] = useState(true);
    let content = null;
    const intl = useIntl();
    const id = parseInt(useParams().id);
    const history = useHistory();

    useEffect(() => getActiveChannels(id, setActiveChannels, setLoading),
        [id])

    if (loading) return <Loading/>;

    content = mapTabContents(activeChannels, content, intl);
    return (
        <div className={styles.box}>
            <SecondaryHeading className={styles.usersHeader} text={"accounts.active.fields.header"}/>
            <div className={styles.buttonGroup}>
                <Button className={styles.addButton} icon="add" label="accounts.active.add" variant="primary" onClick={() => history.push('/accounts/'+id+'/config-lists/add')}/>
                <Button icon="clear" label="accounts.active.delete" variant="danger" onClick={() => history.push('/accounts/'+id+'/config-lists/delete')}/>
            </div>
            {(activeChannels[0] !== undefined) && <ReTab contentDefinition={content} ariaLabel="Vertical Tabs" selected={selectedChannel} setSelected={setSelectedChannel}/>}
            <div>
                <Button variant="link" icon="edit" label="accounts.active.edit" onClick={() => history.push('/accounts/'+id+'/config-lists/channel-edit')}/>
            </div>
        </div>
    );

}

export default ActiveChannels;


