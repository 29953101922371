import React, {useCallback, useState} from "react";
import {Button} from "./index";

export const _ConfirmButton = ({icon, confirmIcon, label, variant, confirmLabel, className, confirmClassName, onClick, loading}) => {

    const [confirmed, setConfirmed] = useState(false);
    const endTimer = useCallback(() => {
        setConfirmed(false);
    }, [setConfirmed]);

    const startTimer = useCallback(() => {
        const timerId = setTimeout(endTimer, 3000);
        setConfirmed(true);
        return () => clearTimeout(timerId);
    }, [endTimer, setConfirmed]);

    return <Button icon={confirmed ? confirmIcon : icon}
                   variant={variant}
                   label={confirmed ? confirmLabel : label}
                   className={(confirmed) ? confirmClassName : className}
                   onClick={confirmed ? onClick : startTimer}
                   loading={loading}/>;
}

export default _ConfirmButton;