import {
    Alert,
    BoolField,
    Box,
    Button,
    DropdownSelect,
    StandardPage, StandardText,
    TextInput
} from "../../common";
import {Form, Formik} from "formik";
import styles
    from "./AddIntegrator.module.scss";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {getDocLocationLabel} from "../IntegratorUtils";
import axios from "axios";
import Loading from "../../Loading/Loading";
import * as Yup from "yup";

const getMailhouses = (setMailhouses, setLoadingMailhouses) => {
    setLoadingMailhouses(true);
    axios.get("/api/payreq-accounts/mailhouses")
        .then(({data}) => {
            setMailhouses(data);
        })
        .finally(() => {
            setLoadingMailhouses(false);
        });
}

const nameTaken = (mailhouses, values) => {
    return (mailhouses.find(mailhouse => mailhouse.id === values.id) && true) || false;
}

const makeEdit = (values, mailhouses, setSubmitting, history) => {
    if (nameTaken(mailhouses, values)) {
        setSubmitting(false);
        return;
    }
        return (
            axios.post("/api/integrators/integrator", {
                id: values.id,
                description: values.description,
                contact: values.contact,
                bill: values.bill,
                requires: values.requires,
                location: parseInt(values.location),
                print: values.printfiles,
                usingAwsTransfer: values.usingAwsTransfer,
                usingThirdPartyTransfer: values.usingThirdPartyTransfer})
                .finally(() => {
                    history.push("/integrators");
                })
        )
}

const AddIntegrator = () => {
    const history = useHistory();
    const id = parseInt(useParams().id);
    const [mailhouses, setMailhouses] = useState([])
    const [loadingMailhouses, setLoadingMailhouses] = useState(false);

    useEffect(() => getMailhouses(setMailhouses, setLoadingMailhouses),
        [id]);

    const documentLocationOpts = [1, 2, 3].map(id => [id, intl => getDocLocationLabel(id, intl)]);

    if (loadingMailhouses) {
        return <Loading/>
    }

    const validationSchema = Yup.object().shape({
        id: Yup.string().required("integrator.id.error"),
        description: Yup.string().required("integrator.description.error"),
        });

    return (
        <StandardPage header={"integrator.new"} parentPath={"/integrators"}>
            <Box>
                <Formik
                    initialValues={{
                        id: "",
                        description: "",
                        contact: false,
                        bill: false,
                        requires: false,
                        location: 1,
                        printfiles: false,
                        usingAwsTransfer: false,
                        usingThirdPartyTransfer: false
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        makeEdit(values, mailhouses, setSubmitting, history)
                            .finally(()=>{
                                setSubmitting(false)
                            });
                    }}
                    validationSchema={validationSchema}
                >
                    {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          errors,
                          touched,
                          isSubmitting,
                          setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className={styles.entryFields}>
                                <TextInput id="id"
                                           className={styles.firstInput}
                                           label={"integratorDetail.id"}
                                           placeholder={"integratorDetail.id.placeholder"}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.id}
                                           errors={errors.id}
                                           touched={touched.id}/>

                                {nameTaken(mailhouses, values) &&
                                    <Alert variant={"danger"}><StandardText text={"integrator.id.warning"}/></Alert>}

                                <TextInput id="description"
                                           label={"integratorDetail.description"}
                                           placeholder={"integratorDetail.description.placeholder"}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.description}
                                           errors={errors.description}
                                           touched={touched.description}
                                />

                                <BoolField id="contact"
                                           className={styles.boolField}
                                           label={"integratorDetail.contactFiles"}
                                           defaultValue={values.bill}
                                           value={values.contact}
                                           onChange={handleChange}/>

                                <BoolField id="bill"
                                           className={styles.boolField}
                                           label={"integratorDetail.billFiles"}
                                           defaultValue={values.bill}
                                           value={values.bill}
                                           onChange={handleChange}/>

                                <BoolField id="requires"
                                           className={styles.boolField}
                                           label={"integratorDetail.fileTxRequiresAck"}
                                           defaultValue={values.bill}
                                           value={values.bill}
                                           onChange={handleChange}/>

                                <DropdownSelect id="location"
                                                className={styles.dropdown}
                                                label={"integratorDetail.documentLocation"}
                                                name={`location`}
                                                options={documentLocationOpts}
                                                value={values.location}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errors={errors.location}
                                                touched={touched.location}
                                                labelFn={x => x}/>

                                <BoolField id="printfiles"
                                           className={styles.boolField}
                                           label={"integratorDetail.printFiles"}
                                           defaultValue={values.printfiles}
                                           value={values.prinfilest}
                                           onChange={handleChange}/>

                                <BoolField id="usingAwsTransfer"
                                           className={styles.boolField}
                                           label={"integratorDetail.usingAwsTransfer"}
                                           defaultValue={values.usingAwsTransfer}
                                           value={values.usingAwsTransfer}
                                           onChange={handleChange}/>

                                <BoolField id="usingThirdPartyTransfer"
                                           className={styles.boolField}
                                           label={"integratorDetail.usingThirdPartyTransfer"}
                                           defaultValue={values.usingThirdPartyTransfer}
                                           value={values.usingThirdPartyTransfer}
                                           onChange={handleChange}/>

                            </div>

                            <Button className={styles.buttons} label={"integrator.save"} variant={"primary"} loading={isSubmitting}
                                    type="submit"/>
                            <Button className={styles.buttons} label={"integrator.cancel"} variant={"secondary"} onClick={() => history.push("/integrators")} type="button"/>
                        </Form>
                    )}
                </Formik>
            </Box>
        </StandardPage>
    )
}

export default AddIntegrator