import styles from "./ContactFields.module.scss";
import {Button, IconText} from "../../../../common";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import Loading from "../../../../Loading/Loading";
import {valueParse} from "../../../../../utils/display-helper-utils";

const getContactFields = (id, setContactFields, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/account-contact-fields", {params: {accountId: id}})
        .then(({data}) => {
            setContactFields(data["en"])
        })
        .finally(() => {
            setLoading(false);
        });
};

const ContactFields = () => {

    const [contactFields, setContactFields] = useState([]);
    const [loading, setLoading] = useState(true);

    const id = parseInt(useParams().id);
    const history = useHistory();

    useEffect(() => getContactFields(id, setContactFields, setLoading),
        [id]);

    if (loading) return <Loading/>;

    if (Object.values(contactFields).every((field) => field === "" || field === null)) {
        return (
            <IconText className={styles.emptyText} text={"accounts.contact.fields.empty"}/>
        );
    }

    return (
            <div className={styles.contactFieldsBox}>
                <div className={styles.fields}>
                    <IconText className={styles.fieldLabel} text={"accounts.contact.fields.1"}/>
                    <IconText className={styles.fieldBox}>{valueParse(contactFields.contactField1)}</IconText>
                </div>
                <div className={styles.fields}>
                    <IconText className={styles.fieldLabel} text={"accounts.contact.fields.2"}/>
                    <IconText className={styles.fieldBox}>{valueParse(contactFields.contactField2)}</IconText>
                </div>
                <div className={styles.fields}>
                    <IconText className={styles.fieldLabel} text={"accounts.contact.fields.3"}/>
                    <IconText className={styles.fieldBox}>{valueParse(contactFields.contactField3)}</IconText>
                </div>
                <div className={styles.fields}>
                    <IconText className={styles.fieldLabel} text={"accounts.contact.fields.4"}/>
                    <IconText className={styles.fieldBox}>{valueParse(contactFields.contactField4)}</IconText>
                </div>
                <div className={styles.fields}>
                    <IconText className={styles.fieldLabel} text={"accounts.contact.fields.5"}/>
                    <IconText className={styles.fieldBox}>{valueParse(contactFields.contactField5)}</IconText>
                </div>
                <div className={styles.fields}>
                    <IconText className={styles.fieldLabel} text={"accounts.contact.fields.6"}/>
                    <IconText className={styles.fieldBox}>{valueParse(contactFields.contactField6)}</IconText>
                </div>
                <div className={styles.fields}>
                    <IconText className={styles.fieldLabel} text={"accounts.contact.fields.7"}/>
                    <IconText className={styles.fieldBox}>{valueParse(contactFields.contactField7)}</IconText>
                </div>
                <div className={styles.fields}>
                    <IconText className={styles.fieldLabel} text={"accounts.contact.fields.8"}/>
                    <IconText className={styles.fieldBox}>{valueParse(contactFields.contactField8)}</IconText>
                </div>
                <div>
                    <Button variant="link" icon="edit" label="accounts.contacts.edit" onClick={() => history.push('/accounts/'+id+'/config-lists/contacts-edit')}/>
                </div>
            </div>);

}

export default ContactFields;



