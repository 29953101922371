import styles from "./EditMailerSummary.module.scss";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import {BoolField, Button, DropdownSelect, NumField, TextInput} from "../../../common";
import Loading from "../../../Loading/Loading";
import {SecondaryHeading, StandardPage, Box} from "../../../common";
import {Form, Formik} from "formik";

const getSubTitle = (id, setTitle, setLoading) => {
    setLoading(true);
    axios.get("/api/payreq-accounts/acc-name", {params: {accountId: id}})
        .then(({data}) => {
            setTitle(data)
        })
        .finally(() => {
            setLoading(false);
        });
};

const getMailerInfo = (id, setMailerInfo, setLoadingData) => {
    setLoadingData(true);
    if (id >= 0) {
        axios.post("/api/payreq-accounts/delivery-account-info", {accountId: id})
            .then(({data}) => {
                setMailerInfo(data)
            })
            .finally(() => {
            setLoadingData(false);
        });
    }
};

const getMailhouses = (setMailhouses, setLoadingMailhouses) => {
    setLoadingMailhouses(true);
    axios.get("/api/payreq-accounts/sending-mailhouses")
        .then(({data}) => {
            data = data.filter((mailhouse) => mailhouse.status !== 1);
            setMailhouses(data.map((mailhouse) => [mailhouse.id, mailhouse.id]).sort());
        })
        .finally(() => {
            setLoadingMailhouses(false);
        });
};


const makeEdit = (values) => {
    return (
        axios.post("/api/payreq-accounts/edit-mailer-summary", {
        id: values.id,
        archive: (values.selected === "archive") ? 'payer-view' : null,
        mandatory: (values.selected === "archive" && values.mandatory),
        printing: (values.selected === "printing"),
        ignore: (values.selected === "ignore"),
        printkey: values.printkey ? values.printkey : null,
        maxpages: values.maxpages ? values.maxpages : null,
        sendingMailhouseId: parseVal(values.sending)
    })
    )
};

const radioVal = (values) => {
    if (values.printing === true) {
        return "printing";
    } else if (values.archive === true) {
        return "archive";
    } else if (values.ignore === true) {
        return "ignore";
    } else {
        return "default"
    }
}

const parseVal = val => {
    if (val === '' || val === '-') {
        return null
    }
    return val;
}


const EditMailerSummary = () => {

    const id = parseInt(useParams().id);
    const [title, setTitle] = useState();
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [mailerInfo, setMailerInfo] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [loadingMailhouses, setLoadingMailhouses] = useState(true);
    const [mailhouses, setMailhouses] = useState({});

    useEffect(() => getSubTitle(id, setTitle, setLoading),
        [id]);
    useEffect(() => getMailerInfo(id, setMailerInfo, setLoadingData),
        [id])
    useEffect(() => getMailhouses(setMailhouses, setLoadingMailhouses),
        [id])


    if (loading || loadingData) return <Loading/>;
    return (
        <StandardPage header={"accounts.primaryHeader"}  values={{header: title.tagName}}
                      sub={"accounts.summary.edit.header.sub"} parentPath={"/accounts/" + id}>
            <Box>
                <span className={styles.editSummaryHeader}> <SecondaryHeading text={"accounts.summary.edit.header"}/></span>
                <Formik
                    initialValues={{id: id,
                                    mandatory: mailerInfo.mandatory,
                                    maxpages: mailerInfo.maxpages ? mailerInfo.maxpages : 0,
                                    printkey: mailerInfo.printkey ? mailerInfo.printkey : '',
                                    sending: mailerInfo.sendingmailhouseid ? mailerInfo.sendingmailhouseid : '',
                                    selected: radioVal(mailerInfo)
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        makeEdit(values, history)
                            .finally(() => {
                                setSubmitting(false)
                                history.replace("/accounts/" + values.id);
                            }
                        )}}
                >
                    {({
                          values,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                      }) => (

                            <Form onSubmit={handleSubmit}>
                            <div className={styles.fieldsGroup}>
                                <BoolField id="ignore"
                                           label={"accounts.summary.ignore"}
                                           checked={values.selected === "ignore"}
                                           value={values.selected}
                                           type={"radio"}
                                           onChange={(change) => {
                                               setFieldValue("selected", "ignore")
                                               handleChange(change)
                                           }}
                                           className={styles.boolField}/>

                                <BoolField id="archive"
                                           label={"accounts.summary.archive"}
                                           checked={values.selected === "archive"}
                                           value={values.selected}
                                           type={"radio"}
                                           onChange={(change) => {
                                               setFieldValue("selected", "archive")
                                               handleChange(change)
                                           }}
                                           className={styles.boolField}/>

                                <BoolField id="printing"
                                           label={"accounts.summary.printing"}
                                           checked={values.selected === "printing"}
                                           value={values.selected}
                                           type={"radio"}
                                           onChange={(change) => {
                                               setFieldValue("selected", "printing")
                                               handleChange(change)
                                           }}
                                           className={styles.boolField}/>

                                <BoolField id="default"
                                           label={"accounts.summary.default"}
                                           checked={values.selected === "default"}
                                           value={values.selected}
                                           type={"radio"}
                                           onChange={(change) => {
                                               setFieldValue("selected", "default")
                                               handleChange(change)
                                           }}
                                           className={styles.boolField}/>

                            </div>

                            {(values.selected === "archive") &&
                                <>
                                    <hr/>
                                    <BoolField id="mandatory"
                                               label={"accounts.summary.mandatory"}
                                               checked={values.mandatory}
                                               onChange={(change) => {
                                                   handleChange(change);
                                               }}
                                               className={styles.boolField}/>
                                </>}

                            {(values.selected === "printing") &&
                                <>
                                    <hr/>
                                    <TextInput id="printkey"
                                       className={styles.authField}
                                       label={"accounts.summary.print.key"}
                                       onChange={handleChange}
                                       value={values.printkey}/>

                                    <NumField id="maxpages"
                                       className={styles.authField}
                                       defaultValue={values.maxpages}
                                       label={"accounts.summary.max.pages"}
                                       type={"number"}
                                       min={0}
                                       onChange={handleChange}
                                       value={values.maxpages}/>

                                    {!loadingMailhouses &&
                                        <DropdownSelect id="sending" label="accounts.summary.sending" name="sending" options={mailhouses}
                                                        defaultValue={(values.sending) ? values.sending : '-'}
                                                        placeholder="accountInfo.mailhouseId"
                                                        onChange={handleChange}
                                                        value={values.sending}
                                                        labelFn={x => x}/>}
                                </>}


                            <Button className={styles.buttons} label={"accounts.summary.edit"} variant={"primary"} loading={isSubmitting}
                                    type="submit"/>
                            <Button className={styles.buttons} label={"accounts.customer.cancel"} variant={"secondary"} onClick={() => history.push("/accounts/" + id)} type="button"/>
                        </Form>)
                    }
                </Formik>
            </Box>
        </StandardPage>
    );
};

export default EditMailerSummary;