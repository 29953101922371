import {Col, Form, Row} from "react-bootstrap";
import React from 'react';

const _Form = ({children, onSubmit, width = 4}) => {

    return (
        <Row>
            <Col md={width}>
                <Form onSubmit={onSubmit}>{children}</Form>
            </Col>
        </Row>
    )
}

export default _Form;