import React from "react";
import {FormattedDate, FormattedTime} from "react-intl";

function parseUTCDateString(date) {
    const dateParts = date.split("-");
    if (dateParts.length > 2) {
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1;
        const day = parseInt(dateParts[2]);
        return new Date(Date.UTC(year, month, day));
    }
}

function readableDate(date) {
    return <FormattedDate value={date}
                          year="numeric"
                          month="long"
                          day="2-digit"/>;
}

function readableDateUTC(date) {
    return <FormattedDate value={date}
                          year="numeric"
                          month="long"
                          day="2-digit" timeZone={"UTC"}/>;
}

function readableTime(time) {

    return <FormattedTime value={time}/>;
}

function readableDateTime(dateTime) {
    return <React.Fragment>
        <FormattedDate value={dateTime}
                       year="numeric"
                       month="long"
                       day="2-digit"/>
        {" - "}
        <FormattedTime value={dateTime}/>
    </React.Fragment>
}

export {parseUTCDateString, readableDate, readableDateUTC, readableTime, readableDateTime};