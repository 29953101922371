import {Badge} from "react-bootstrap";
import React from "react";
import {FormattedMessage} from "react-intl";
import styles from './_Badge.module.scss';


const _Badge = ({label, className, variant, as, values, children}) => {
    return <Badge className={styles.badge + className} variant={variant} as={as}>
        <div className={styles.label}><FormattedMessage id={label} values={values}/></div>
        {children}
    </Badge>
}

export default _Badge;