import {Form} from "react-bootstrap";
import {useIntl} from "react-intl";
import styles from './_NumField.module.scss';
import React from 'react';

const _NumField = ({id, label, hint, as, onChange, className, defaultValue, name, min, placeholder}) => {
    const intl = useIntl();
    const resolvedPlaceholder = typeof(placeholder) === "string" ? intl.formatMessage({id: placeholder}) : '';
    return (
        <Form.Group controlId={id} className={[styles.formGroup, className].join(" ")}>
            {label && <b><Form.Label className={styles.label}>{intl.formatMessage({id: label})}</Form.Label></b>}
            <Form.Control type={'number'}
                          min={min}
                          as={as}
                          onChange={onChange}
                          defaultValue={defaultValue}
                          className={[styles.formEntry, className].join(" ")}
                          name={name}
                          defaultChecked={defaultValue}
                          placeholder={resolvedPlaceholder}/>
            {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
        </Form.Group>
    )
}

export default _NumField;