import {Form} from "react-bootstrap";
import {useIntl} from "react-intl";
import styles from './_BoolField.module.scss';
import React from 'react';

const _BoolField = ({id, label, hint, as, onBlur, onChange, errors, touched, className, defaultValue, name, checked, type='checkbox'}) => {
    const intl = useIntl();
    return (
        <Form.Group controlId={id} className={[styles.formGroup, className].join(" ")}>
            {label && <b><Form.Label className={styles.label}>{intl.formatMessage({id: label})}</Form.Label></b>}
            <Form.Control type={type}
                          as={as}
                          onChange={onChange}
                          onBlur={onBlur}
                          checked={checked}
                          defaultValue={defaultValue}
                          isInvalid={touched && !!errors}
                          className={[styles.formEntry, className].join(" ")}
                          name={name}
                          defaultChecked={defaultValue}/>
            {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
            <Form.Control.Feedback type="invalid">
                {typeof(errors) === "string" && <span className={styles.errors}>{intl.formatMessage({id: errors})}</span>}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

export default _BoolField;