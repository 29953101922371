import React, {useState} from "react";
import RegularText from "../../common/_RegularText";
import {Button, Modal} from "../../common";
import styles from "../../common/_Modal/_Modal.module.scss";
import axios from "axios";
import {useAppState} from "../../../state";
import {INBOX_CONVERTED_PERSONAL} from "../../../state/reducers/alertReducer";

const convertInboxPersonal = (id, setLoading, dispatch) => {
    return axios.post("/api/payreq-accounts/convert-to-personal-inbox", {accountId: id})
        .then(() => {
            dispatch({type: INBOX_CONVERTED_PERSONAL})
        })
        .finally(() => {
            setLoading(false);
        });
};

const ConvertInboxPersonalModal = ({accountId, show, setShow, handleChange}) => {

    const [loading, setLoading] = useState(false);
    const [, dispatch] = useAppState();

    return <Modal header={"accounts.convertModal.personalHeader"} show={show} setShow={setShow} handleClose={() => setShow(false)}>
        <RegularText text="accounts.convertModal.personalMessage"/>
        <Modal.Footer>
            <Button label={"accounts.convertModal.cancel"} variant={"secondary"}
                    onClick={() => setShow(false)}/>
            <Button label={"accounts.convertModal.convert"} variant={"primary"} loading={loading}
                    onClick={() => {
                        convertInboxPersonal(accountId, setLoading, dispatch)
                            .then(() => {
                                handleChange()
                                setShow(false)
                            })
                    }} className={styles.submitButton}/>
        </Modal.Footer>
    </Modal>
};
export {ConvertInboxPersonalModal};