import {useAppState} from "../state";
import {Button, Modal, RegularText} from "./common";
import React from "react";

const GenericAPIErrorModal = () => {
    const [state] = useAppState();
    const {error} = state;
    return (
        <Modal show={!!error} header={"genError.heading"}>
            <RegularText text="genError.refresh"/>
            <RegularText text="genError.contact"/>
            {error && error.id && <p>Error ID: {error.id}</p>}
            <Modal.Footer>
                {/* eslint-disable-next-line no-restricted-globals */}
                <Button label={"genError.refreshButton"} onClick={() => location.reload()}/>
            </Modal.Footer>
        </Modal>
    );
};

export default GenericAPIErrorModal;