import React from "react";
import RegularText from "../common/_RegularText";
import {Button, Modal} from "../common";
import styles from "../common/_Modal/_Modal.module.scss";

const LeaveModal = ({onYes, show, setShow}) => {
    return <Modal header={"accounts.modal.headerLeave"} show={show} setShow={setShow} handleClose={() => setShow(false)}>
        <RegularText text="accounts.modal.messageSupportLeave"/>
        <Modal.Footer>
            <Button label={"accounts.modal.buttonNo"} variant={"secondary"}
                    onClick={() => setShow(false)}/>
            <Button label={"accounts.modal.buttonSupportLeave"} variant={"danger"}
                    onClick={() => {
                        onYes();
                        setShow(false);
                    }} className={styles.submitButton}/>
        </Modal.Footer>
    </Modal>
};
export {LeaveModal};