import styles from "./RequestsList.module.scss"
import React from "react";
import Pagination from "../Pagination/Pagination";
import {FormattedMessage} from "react-intl";
import ErrorMessage from "../ErrorMessage";
import {readableDate} from "../Helpers/DateTimeHelpers";
import {RequestAction, RequestStatus} from "../Helpers/ApprovalRequestHelpers";
import RegularText from "../common/_RegularText";
import {StandardText} from "../common";

const SubText = ({children}) => {
    return (
        <div className={styles.subText}>{children}</div>
    );
};

const RequestRow = ({data, rowPress}) => {
    return (
        <div className={styles.rowStyle} onClick={() => rowPress()}>
            <div className={styles.requestId}><StandardText text={"approvalRequests.requestId"} values={{id: data.id}}/></div>
            <div className={styles.actionName}>
                <div className={styles.requestAction}><RequestAction action={data.action}/></div>
                <SubText>
                    {(data.currentUserRequest) ?
                    <FormattedMessage id={"approvalRequests.currentUserRequest"} values={{date: readableDate(data.requestedOn), requesterName: data.requesterName}}/> :
                    <FormattedMessage id={"approvalRequests.nonCurrentUserRequest"} values={{date: readableDate(data.requestedOn), requesterName: data.requesterName}}/>}
                </SubText>
            </div>
            <div className={styles.requestReason}><StandardText text={"approvalRequests.requestReason"} values={{reason: data.reason}}/></div>
            <div className={styles.statusName}>
                <div className={styles.requestStatus}><RequestStatus status={data.status}/></div>
                <SubText>
                    {(data.updatedOn) ?
                    <FormattedMessage id={"approvalRequests.updatedRequest"} values={{date: readableDate(data.updatedOn), updaterName: data.updaterName}}/> :
                    <FormattedMessage id="approvalRequests.awaitingAction"/>}
                </SubText>
            </div>
        </div>
    );
}

const RequestsList = ({listData, listMeta, pageNumber, setPageNumber, emptyMessage, history}) => {

    if (!listData || listData.length < 1) return <div className={styles.boxShadow}><ErrorMessage><RegularText text={emptyMessage}/></ErrorMessage></div>;
    return (
        <div className={styles.boxShadow}>
            <div className={styles.rows}>
            {listData.map((request, index) =>
                <RequestRow key={index} data={request} rowPress={() => history.push(`/approval-requests/${request.id}`)}/>
            )}
            </div>
            {listMeta && <Pagination className={styles.listPagination} meta={listMeta} pageNumber={pageNumber} setPageNumber={setPageNumber}/>}
        </div>
    );
};

export default RequestsList;